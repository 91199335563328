import { Key } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import main from "api/main";
import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";
import useApi from "hooks/useApi";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoginPasswordField } from "../Login";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Modal from "../../components/UI/Modal";
import { useTranslation } from "react-i18next";

export default function EntityProfile() {
  const { t, i18n } = useTranslation();
  const [viewMode, setViewMode] = useState("list");
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const handleOpenPasswordModal = () => {
    setOpenPasswordModal((state) => !state);
  };

  const navigate = useNavigate();

  //api
  const getMyEntityProfile = useApi(main.getMyEntityProfile);

  useEffect(() => {
    getMyEntityProfile.request();
  }, []);

  const listData = [
    {
      label: "Specialties",
      data: getMyEntityProfile.data?.specialties?.map((item) => item.name),
    },
    {
      label: "Established Date",
      data: moment(getMyEntityProfile.data?.established_date).format(
        "DD-MM-YYYY"
      ),
    },
    {
      label: "Email",
      data: getMyEntityProfile.data?.email,
    },
    {
      label: "Website",
      data: getMyEntityProfile.data?.website,
    },
    {
      label: "Commercial Register",
      data: {
        file: getMyEntityProfile.data?.commercial_register_file,
        file_name: "view",
      },
      fieldType: "link",
    },
    {
      label: "Health License",
      data: {
        file: getMyEntityProfile.data?.health_license_file,
        file_name: "view",
      },
      fieldType: "link",
    },
    {
      label: "Syndicate License",
      data: {
        file: getMyEntityProfile.data?.syndicate_license_file,
        file_name: "view",
      },
      fieldType: "link",
    },
    {
      label: "Tax Card",
      data: { file: getMyEntityProfile.data?.tax_card_file, file_name: "view" },
      fieldType: "link",
    },
    {
      fieldType: "element",
      width: "100%",
      element: (
        <Stack
          width="100%"
          direction="row"
          paddingRight={4}
          justifyContent="flex-end"
          gap={2}
        >
          <Button
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={handleOpenPasswordModal}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {t("Change Password")}
              <Key />
            </Stack>
          </Button>
        </Stack>
      ),
    },
  ];

  const cardItems = [
    {
      header: "Name",
      keyName: "data.full_name",
    },
    {
      header: "EID",
      keyName: "data.username?.substr(1)",
    },
    {
      header: "Type",
      keyName: "data.type",
    },
    {
      header: "Phone Number",
      keyName: "data.phone_number",
    },
  ];

  return (
    <MainContainer title={"My Profile"}>
      <Modal open={openPasswordModal} handleOpen={handleOpenPasswordModal}>
        <PasswordModal closeHandler={handleOpenPasswordModal} />
      </Modal>
      <Stack gap={1}>
        <CebForm
          viewModeState={[viewMode, setViewMode]}
          listData={listData}
          cardsData={[getMyEntityProfile.data]}
          cardItems={cardItems}
          chosenCardId={getMyEntityProfile.data?.id}
          profile={true}
          backHandler={() => navigate(-1)}
        />
      </Stack>
    </MainContainer>
  );
}

//validation
const PasswordResolver = () =>
  useCallback(async (data) => {
    //Craete Schema
    const schema = {
      old_password: yup.string().required("Cuurent Password is required"),
      password: yup.string().required("Password is required"),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    };

    //Validate Schema
    try {
      const values = await yup.object().shape(schema).validate(data, {
        abortEarly: false,
      });

      return {
        values,
        errors: {},
      };
    } catch (errors) {
      return {
        values: {},
        errors: errors.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? "validation",
              message: currentError.message,
            },
          }),
          {}
        ),
      };
    }
  }, []);

const PasswordModal = ({ closeHandler }) => {
  const { t, i18n } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: PasswordResolver(), mode: "onTouched" });

  //api
  const changePassword = useApi(main.changePassword);

  const onSubmit = async (data) => {
    const res = await changePassword.requestWithToast(
      t("Changed successfully"),
      data.old_password,
      data.password,
      data.confirm_password
    );
    if (res.ok) {
      closeHandler();
    }
  };

  return (
    <Stack
      mb={2}
      width={600}
      sx={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
        <>
          <LoginPasswordField
            title={t("Current Password")}
            // placeholder={"Enter Current Passowrd"}
            name="old_password"
            register={register}
            errors={errors}
          />
          <LoginPasswordField
            title={t("New Password")}
            // placeholder={"Enter new password"}
            name="password"
            register={register}
            errors={errors}
          />

          <LoginPasswordField
            title={t("Re-type New Passsword")}
            // placeholder={"Re-type New Password"}
            name="confirm_password"
            register={register}
            errors={errors}
          />

          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#003468",
              px: "50px",
              fontWeight: "600",
              fontSize: "20px",
              borderRadius: "10px",
              mt: "60px",
              textTransform: "none",
            }}
            disabled={changePassword.loading}
          >
            {t("Change")}
          </Button>
        </>
      </form>
    </Stack>
  );
};
