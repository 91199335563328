import React from "react";

const Home = ({ isActive, ...props }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    style={
      isActive
        ? {
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: 2,
            color: "#005AB3",
          }
        : { padding: 2, color: "#fff" }
    }
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_144_28)">
      <path
        d="M44.9467 23.0534L24.9467 3.05337C24.6969 2.80504 24.3589 2.66565 24.0067 2.66565C23.6545 2.66565 23.3165 2.80504 23.0667 3.05337L3.0667 23.0534C2.84827 23.3084 2.73413 23.6365 2.74709 23.9721C2.76005 24.3077 2.89916 24.626 3.13662 24.8635C3.37408 25.1009 3.6924 25.24 4.02797 25.253C4.36353 25.2659 4.69163 25.1518 4.9467 24.9334L24 5.88004L43.0534 24.9467C43.3084 25.1651 43.6365 25.2793 43.9721 25.2663C44.3077 25.2534 44.626 25.1143 44.8635 24.8768C45.1009 24.6393 45.24 24.321 45.253 23.9854C45.2659 23.6499 45.1518 23.3218 44.9334 23.0667L44.9467 23.0534Z"
        fill="currentColor"
      />
      <path
        d="M37.3333 42.6667H30.6667V29.3334H17.3333V42.6667H10.6667V24.0001L8 26.6667V42.6667C8 43.374 8.28095 44.0522 8.78105 44.5523C9.28115 45.0524 9.95942 45.3334 10.6667 45.3334H20V32.0001H28V45.3334H37.3333C38.0406 45.3334 38.7189 45.0524 39.219 44.5523C39.719 44.0522 40 43.374 40 42.6667V26.3467L37.3333 23.6801V42.6667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_144_28">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default Home;
