import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const MainContainer = ({ title, children }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "95%", mb: 2, overflow: "auto" }}>
      <Typography
        color={"secondary"}
        sx={{ mb: 4, fontWeight: 700, fontSize: "31px" }}
      >
        {t(title)}
      </Typography>

      {children}
    </Box>
  );
};

export default MainContainer;
