import { Button, Collapse, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import MainContainer from "components/Entity/MainContainer";

import PatientCard, { PatientCardTitles } from "components/patient/PatientCard";
import useApi from "hooks/useApi";
import main from "api/main";
import moment from "moment";
import ActionButton from "components/UI/ActionButton";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const PurchaseOrders = () => {
  const urlParams = new URLSearchParams(window.location.search);

  //api
  const getPurchaseOrderList = useApi(
    urlParams.get("entityId")
      ? main.getPurchaseOrderList
      : main.getMyPurchaseOrderList
  );
  const rejectPurchaseOrder = useApi(main.rejectPurchaseOrder);
  const approvePurchaseOrder = useApi(main.approvePurchaseOrder);

  useEffect(() => {
    getPurchaseOrderList.request(urlParams.get("entityId"));
  }, [rejectPurchaseOrder.data, approvePurchaseOrder.data]);

  return (
    <>
      <MainContainer title="Purchase Orders">
        <Stack gap={2}>
          {urlParams.get("entityId") && (
            <Typography color="primary" fontSize="25px" fontWeight={500}>
              {urlParams.get("entityName")}
            </Typography>
          )}

          <Stack gap={1}>
            <PatientCardTitles
              items={[
                { header: "Order ID" },
                { header: "Date of Ordering" },
                { header: "Status" },
                { header: "View" },
              ]}
            />
            {getPurchaseOrderList.data?.map((item) => (
              <OrderCard
                key={item.id}
                orderId={item.serial_number}
                date={moment(item.created_at).format("DD-MM-YYYY")}
                status={item.purchase_status}
                admin={urlParams.get("entityId") ? true : false}
                purchaseLines={item.purchase_lines}
                rejectHandler={() =>
                  rejectPurchaseOrder.requestWithToast(
                    t("Rejected successfully"),
                    urlParams.get("entityId"),
                    item.id
                  )
                }
                approveHandler={() =>
                  approvePurchaseOrder.requestWithToast(
                    t("Approved successfully"),
                    urlParams.get("entityId"),
                    item.id
                  )
                }
              />
            ))}
          </Stack>
        </Stack>
      </MainContainer>
    </>
  );
};

export default PurchaseOrders;

const OrderCard = ({
  orderId,
  date,
  status,
  purchaseLines,
  admin,
  rejectHandler,
  approveHandler,
}) => {
  const { t } = useTranslation();
  const [viewCollapse, setViewCollapse] = useState({});

  return (
    <Stack>
      <PatientCard
        data={{ orderId, date, status }}
        items={[
          { keyName: "data.orderId" },
          { keyName: "data.date" },
          {
            keyName: `data.status`,
            type: "status",
            statusColor: `data.status === "Approved" ? "green" : data.status === "Pending" ?  "rgba(139, 128, 0, 1)": "secondary.main" `,
          },
          {
            type: "element",
            element: (
              <Button
                style={{
                  background:
                    purchaseLines?.length === 0 ? "#D3D3D3" : "#A5D2FF",
                  color: "#003468",
                  width: 140,
                  height: 36,
                  borderRadius: 10,
                  fontSize: 16,
                  fontWeight: 400,
                }}
                onClick={() =>
                  setViewCollapse((state) => ({
                    ...state,
                    [orderId]: !state[orderId],
                  }))
                }
                disabled={purchaseLines?.length === 0}
              >
                {viewCollapse[orderId] ? t("Hide") : t("View")}
              </Button>
            ),
          },
        ]}
      />
      <Collapse in={viewCollapse[orderId]}>
        <Stack
          sx={{
            backgroundColor: "card.main",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
          p={2}
          marginTop={-2}
        >
          <Stack
            sx={{ backgroundColor: "#fff" }}
            gap={2}
            padding={4}
            borderRadius="10px"
          >
            <Grid container width={"100%"}>
              <Grid item xs={4} fontWeight={700}>
                {t("Role")}
              </Grid>
              <Grid item xs={4} textAlign="center" fontWeight={700}>
                {t("Number of licenses")}
              </Grid>
              <Grid item xs={4} textAlign="center" fontWeight={700}>
                {t("Number of months")}
              </Grid>
            </Grid>
            {purchaseLines.map((item, index) => (
              <Grid
                key={item.id}
                container
                width={"100%"}
                paddingTop="20px"
                paddingBottom="18px"
                borderBottom={
                  index !== purchaseLines.length - 1 &&
                  "0.6px solid rgba(0,0,0,0.3)"
                }
              >
                <Grid item xs={4}>
                  {item.role_user}
                </Grid>
                <Grid item xs={4} textAlign="center">
                  {item.quantity}
                </Grid>
                <Grid item xs={4} textAlign="center">
                  {item.num_months}
                </Grid>
              </Grid>
            ))}
            {admin && status === "Pending" && (
              <Stack
                pt={4}
                width={"100%"}
                direction="row"
                justifyContent={"flex-end"}
                alignItems="center"
                gap={2}
              >
                <ActionButton type="reject" onClick={rejectHandler}>
                  Reject
                </ActionButton>
                <ActionButton onClick={approveHandler}>Approve</ActionButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
