import { CssBaseline, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import authService from "auth/authService";
import useWebSocket from "hooks/useWebSocket";
import Entity from "pages/Entity/index";
import Login from "pages/Login";
import Permissions from "pages/RolePermissions/Permissions";
import Superadmin from "pages/Superadmin/index";
import SuperAdminLogin from "pages/SuperadminLogin";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import SignUp from "./pages/SignUp";
import Theme from "./styles/MUITheme";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const navigate = useNavigate();
  let user = authService.getCurrentUser();
  useEffect(() => {
    if (user?.user_id) {
      if (user?.role === "Admin") navigate("/superadmin/home");
      else navigate("/entity/home");
    } else {
      navigate("/login");
    }
  }, []);
  return <div>home</div>;
};

const App = () => {
  const { i18n } = useTranslation();
  const url = useLocation();
  const LOGIN = "/login";
  const SUPERADMIN_LOGIN = "/superadmin-login";
  const SIGNUP = "/signup";
  console.log(url);

  useEffect(() => {
    if (
      localStorage.getItem("lng") === "ar" &&
      url.pathname !== SUPERADMIN_LOGIN
    ) {
      i18n.changeLanguage("ar");
    }
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <>
        <CssBaseline />

        <Grid container direction="column" styles={{ minHeight: "100vh" }}>
          <div dir={i18n.language === "ar" ? "rtl" : "lft"}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path={LOGIN} element={<Login />} />
              <Route path={SUPERADMIN_LOGIN} element={<SuperAdminLogin />} />
              <Route path={SIGNUP} element={<SignUp />} />
              <Route path="/entity/*" element={<Entity />} />
              <Route path="/superadmin/*" element={<Superadmin />} />
              <Route path="/permissions" element={<Permissions />} />
            </Routes>
          </div>
        </Grid>
      </>
    </ThemeProvider>
  );
};

export default App;
