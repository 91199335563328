// import { Box, Button, Stack, TextField, Typography } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// import Filter from "assets/icons/Filter";
// import ItemCard from "components/Entity/ItemCard";
// import MainContainer from "components/Entity/MainContainer";

// import { useForm } from "react-hook-form";
// import StyledButton from "../../components/UI/StyledButton";
// import CebForm from "components/Form/CebForm";

// const cardsData = [
//   {
//     name: "Ahmed",
//     job: "Doctor",
//     department: "Cardiology",
//     respond: "accepted",
//     id: "123123ABC",
//   },
//   {
//     name: "Mohamed",
//     job: "Doctor",
//     department: "Cardiology",
//     respond: "pending",
//     id: "123123ABD",
//   },
// ];

// const cardItems = [
//   { header: "Name", keyName: "data.name" },
//   { header: "Job", keyName: "data.job" },
//   { header: "Department", keyName: "data.department" },
// ];

// const Enrollment = () => {
//   const navigate = useNavigate();
//   const [openModal, setOpenModal] = useState(false);

//   //const [enrollmentList, setEnrollmentList] = useState([]);

//   const { register, control, handleSubmit } = useForm();

//   const handleOpenModal = () => {
//     setOpenModal((state) => !state);
//   };

//   const getEnrollmentList = async () => {
//     /*
//     const res = await api.getEntityDoctors();
//     console.log(res.data.data);
//     setDoctorList(res.data.data);
//     */
//   };

//   useEffect(() => {
//     getEnrollmentList();
//   }, []);

//   return (
//     <>
//       <MainContainer title="Enrollment">
//         <CebForm />
//       </MainContainer>
//     </>
//   );
// };

// export default Enrollment;

import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import mainApi from "../../api/main";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CebForm from "../../components/Form/CebForm";
import Modal from "../../components/UI/Modal";
import OtpForm from "../../components/patient/OtpForm";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

export default function Permissions() {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState("form");
  const [openModal, setOpenModal] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [newPractitionerId, setNewPractitionerId] = useState();
  const [watchedFields, setWatchedFields] = useState([]);
  const [resetForm, setResetForm] = useState();
  const [addNewPractitioner, setAddNewPractitioner] = useState(false);
  let navigate = useNavigate();

  const { addToast } = useToasts();

  //Api
  const getPractitionerSecureSearch = useApi(
    mainApi.getPractitionerSecureSearch
  );

  const fields = [
    {
      name: "nid",
      label: "NID",
      type: "number",
      validation: { required: true, length: 14 },
    },
  ];

  const handleOpenModal = () => {
    if (openModal && otpView) {
      setOtpView(false);
    }
    setOpenModal((state) => !state);
  };

  //helpers
  const getWatchedFields = (f) => {
    setWatchedFields(f);
  };

  //submit
  const onSubmit = async (data) => {
    const res = await getPractitionerSecureSearch.request(data.nid);
    if (res.ok) {
      if (res?.data?.data) {
        console.log("enroll", res?.data?.data);
        setNewPractitionerId(res?.data?.data.id);
        setOtpView(true);
        handleOpenModal();
        setResetForm(true);
      } else {
        addToast("Practitioner is not registered", {
          appearance: "error",
          autoDismiss: true,
        });
        setAddNewPractitioner(true);
      }
    }
  };

  return (
    <>
      <Stack
        sx={{
          mx: 9,
          mt: 6,
          width: "80%",
        }}
        gap={5}
      >
        <Stack direction={"row"} justifyContent="space-between">
          {addNewPractitioner && (
            <Stack direction="row" gap={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/patients/newPatient");
                }}
                style={{
                  width: 145,
                  height: 48,
                  borderRadius: 10,
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                Add New
              </Button>
            </Stack>
          )}
          {!addNewPractitioner && (
            <Typography
              color={"secondary"}
              sx={{ mb: 4, fontWeight: 700, fontSize: "31px" }}
            >
              {t("Request New Enrollment")}
            </Typography>
          )}
        </Stack>

        <CebForm
          fields={fields}
          viewModeState={[viewMode, setViewMode]}
          onSubmit={onSubmit}
          // fieldsToWatch={{ name: ["state"] }}
          // getWatchedFields={getWatchedFields}
          resetState={[resetForm, setResetForm]}
          // requestErrors={addPatient.error}
          disableCancelButton
          saveButtonLabel="Request"
        />
      </Stack>

      <Modal open={openModal} handleOpen={handleOpenModal}>
        <OtpForm
          enrollPractitioner
          userId={newPractitionerId}
          handleOpenModal={handleOpenModal}
        />
      </Modal>
    </>
  );
}
