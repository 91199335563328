import React, { useState } from "react";
import { Modal, Box, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1182,
  // height: 697,
  overflow: "auto",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const StyledModal = ({ open, handleOpen, minHeight = 300, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 10 }}
    >
      <Box sx={{ ...style, minHeight }}>{children}</Box>
    </Modal>
  );
};

export default StyledModal;
