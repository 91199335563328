import React from "react";
import { CircularProgress, Stack } from "@mui/material";

const LoadingScreen = () => {
  return (
    <>
      <Stack
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1000,
          backgroundColor: "rgba(0,0,0,0.8)",
          color: "#ECECEC",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" />
      </Stack>
    </>
  );
};

export default LoadingScreen;
