import { Box, Button, Collapse, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MainContainer from "components/Entity/MainContainer";

import PatientCard, { PatientCardTitles } from "components/patient/PatientCard";
import PlusIcon from "assets/icons/Plus";
import theme from "styles/MUITheme";
import useApi from "hooks/useApi";
import main from "api/main";
import Modal from "components/UI/Modal";
import { PURCHASE_ORDERS } from "config/Constants";
import CebSelectField from "components/Form/CebSelectField";
import { useForm } from "react-hook-form";
import CebTextField from "components/Form/CebTextField";
import CustomAddButton from "components/UI/CustomAddButton";
import { useToasts } from "react-toast-notifications";
import { DeleteOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const LicenseManagement = ({ entityId, entityName }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const getLicenseDashboardList = useApi(
    entityId ? main.getLicenseDashboardList : main.getMyLicenseDashboardList
  );
  const getGroupsList = useApi(main.getGroupsList);
  const assignLicense = useApi(main.assignLicense);
  const unAssignLicense = useApi(main.unAssignLicense);

  const handleOpenModal = () => {
    if (!openModal) getGroupsList.request();
    setOpenModal((s) => !s);
  };

  useEffect(() => {
    getLicenseDashboardList.request(entityId);
  }, [assignLicense.data, unAssignLicense.data]);

  return (
    <>
      <MainContainer title="Licenses">
        {!entityId && (
          <Modal open={openModal} handleOpen={handleOpenModal}>
            <NewPurchaseOrder closeHandler={handleOpenModal} />
          </Modal>
        )}
        <Stack gap={2}>
          {entityId && (
            <Typography color="primary" fontSize="25px" fontWeight={500}>
              {entityName}
            </Typography>
          )}

          {/* actionbar */}
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap="30px">
              <Stack direction="row" gap={2}>
                <InfoCard
                  label={t("Number of users")}
                  count={getLicenseDashboardList.data?.number_users}
                />
                <InfoCard
                  label={t("Number of licenses")}
                  count={getLicenseDashboardList.data?.number_licenses}
                />
              </Stack>
              {!entityId && (
                <Stack direction="row" gap="20px" alignItems="center">
                  <Typography color="primary" fontSize="16px" fontWeight={500}>
                    {t("Request new license")}
                  </Typography>
                  <Stack
                    justifyContent="center"
                    sx={{ cursor: "pointer" }}
                    onClick={handleOpenModal}
                  >
                    <PlusIcon />
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() =>
                navigate(
                  entityId
                    ? `/superadmin${PURCHASE_ORDERS}?entityId=${entityId}&entityName=${entityName}`
                    : `/entity${PURCHASE_ORDERS}`
                )
              }
            >
              {t("Purchase Orders")}
            </Button>
          </Stack>

          <Stack gap={1}>
            <PatientCardTitles
              items={[
                { header: "Role" },
                { header: "Number of License" },
                { header: "View" },
              ]}
            />
            {getLicenseDashboardList.data?.role_list_license?.map(
              (item, index) => (
                <LicenseCard
                  id={index}
                  role={item.name}
                  licenses={item.license_count}
                  practitionerList={item.practitioners}
                  assignLicenseHandler={(practitionerId) =>
                    assignLicense.requestWithToast(t("Assigned successfully"), {
                      assigned_practitioner: practitionerId,
                    })
                  }
                  unAssignLicenseHandler={(licenseId) =>
                    unAssignLicense.requestWithToast(
                      t("Un-Assigned successfully"),
                      licenseId
                    )
                  }
                  admin={entityId ? true : false}
                />
              )
            )}
          </Stack>
        </Stack>
      </MainContainer>
    </>
  );
};

export default LicenseManagement;

const InfoCard = ({ label, count }) => (
  <Stack
    direction="row"
    height="43px"
    paddingX="16px"
    justifyContent={"space-between"}
    alignItems="center"
    border="1px solid rgba(0, 0, 0, 0.3)"
    borderRadius="10px"
    gap={3}
  >
    <Typography fontWeight={400} fontSize="16px">
      {label}
    </Typography>
    <Typography fontWeight={400} fontSize="16px">
      {count && Number(count).toLocaleString("en-US")}
    </Typography>
  </Stack>
);

const LicenseCard = ({
  id,
  role,
  licenses,
  practitionerList,
  admin,
  assignLicenseHandler,
  unAssignLicenseHandler,
}) => {
  const [viewCollapse, setViewCollapse] = useState({});
  const { t } = useTranslation();

  return (
    <Stack>
      <PatientCard
        data={{ role, licenses }}
        items={[
          { keyName: "data.role" },
          { keyName: "data.licenses" },
          {
            type: "element",
            element: (
              <Button
                style={{
                  background:
                    practitionerList?.length === 0 ? "#D3D3D3" : "#A5D2FF",
                  color: "#003468",
                  width: 140,
                  height: 36,
                  borderRadius: 10,
                  fontSize: 16,
                  fontWeight: 400,
                }}
                onClick={() =>
                  setViewCollapse((state) => ({
                    ...state,
                    [id]: !state[id],
                  }))
                }
                disabled={practitionerList?.length === 0}
              >
                {viewCollapse[id] ? t("Hide") : t("View")}
              </Button>
            ),
          },
        ]}
      />
      <Collapse in={viewCollapse[id]}>
        <Stack
          sx={{
            backgroundColor: "card.main",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
          p={2}
          marginTop={-2}
        >
          <Stack
            sx={{ backgroundColor: "#fff" }}
            gap={2}
            padding={4}
            borderRadius="10px"
          >
            <Grid container width={"100%"}>
              <Grid item xs={3} fontWeight={700}>
                {t("Name")}
              </Grid>
              <Grid item xs={3} textAlign="center" fontWeight={700}>
                {t("Date of joining")}
              </Grid>
              <Grid item xs={3} textAlign="center" fontWeight={700}>
                {t("License End-Date")}
              </Grid>
              <Grid item xs={3} textAlign="center" fontWeight={700}>
                {t("Status")}
              </Grid>
            </Grid>
            {practitionerList.map((item, index) => (
              <Grid
                key={item.id}
                container
                width={"100%"}
                paddingTop="20px"
                paddingBottom="18px"
                borderBottom={
                  index !== practitionerList.length - 1 &&
                  "0.6px solid rgba(0,0,0,0.3)"
                }
              >
                <Grid item xs={3}>
                  {item.full_name}
                </Grid>
                <Grid item xs={3} textAlign="center">
                  {item.date_joined}
                </Grid>
                <Grid item xs={3} textAlign="center">
                  {item?.license ? item.license?.expiration_date : "_"}
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    {admin && (
                      <Box
                        width={110}
                        height={32}
                        backgroundColor={
                          item.license?.is_expired === false
                            ? "green"
                            : theme.palette.secondary.main
                        }
                        borderRadius={10}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          textAlign={"center"}
                          color="white"
                          fontSize={16}
                        >
                          {item.license?.is_expired === false
                            ? t("Active")
                            : t("Not-Active")}
                        </Typography>
                      </Box>
                    )}
                    {!admin && (
                      <Button
                        variant="outlined"
                        color={
                          item.license?.is_expired === false
                            ? "secondary"
                            : "success"
                        }
                        sx={{
                          textTransform: "capitalize",
                          ":hover": {
                            backgroundColor:
                              item.license?.is_expired === false
                                ? theme.palette.secondary.main
                                : "green",
                            color: "#fff",
                          },
                          borderRadius: 2,
                        }}
                        onClick={() => {
                          item.license?.is_expired === false
                            ? unAssignLicenseHandler(item.license?.id)
                            : assignLicenseHandler(item.id);
                        }}
                      >
                        {item.license?.is_expired === false
                          ? t("Un-Assign")
                          : t("Assign")}
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NewPurchaseOrder = ({ closeHandler }) => {
  const { t, i18n } = useTranslation();
  const { addToast } = useToasts();

  const [orderItems, setOrderItems] = useState([]);

  //api
  const addPurchaseOrder = useApi(main.addPurchaseOrder);

  const addItemHandler = (data) => {
    if (
      !(
        data.role_user &&
        Number(data.quantity) > 0 &&
        Number(data.num_months) > 0
      )
    ) {
      addToast("Please enter fields properly and try again", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    const existedItemIndex = orderItems.findIndex(
      (item) =>
        item.role_user === data.role_user && item.num_months === data.num_months
    );
    if (existedItemIndex !== -1) {
      setOrderItems((old) =>
        old.map((item, index) =>
          index === existedItemIndex
            ? {
                ...item,
                quantity: item.quantity + data.quantity,
              }
            : item
        )
      );
    } else {
      setOrderItems((old) => [...old, data]);
    }
  };

  const deleteItemHandler = (itemIndex) => {
    setOrderItems((old) => old.filter((_, index) => index !== itemIndex));
  };

  const onSubmitOrder = async () => {
    const res = await addPurchaseOrder.requestWithToast(
      t("Order has been created successfully"),
      { purchase_lines: orderItems }
    );
    if (res.ok) {
      closeHandler();
    }
  };

  return (
    <Stack gap={3} sx={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}>
      {/* form */}
      <Typography fontSize={"20px"} fontWeight={700}>
        {t("New Purchase Order")}
      </Typography>
      <OrderForm addItemHandler={addItemHandler} />

      {/* order items */}
      <Stack gap={2}>
        {orderItems.map((item, index) => (
          <Stack
            border="1px solid rgba(0, 52, 104, 0.5)"
            borderRadius={"10px"}
            padding="16px"
            gap={"30px"}
          >
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center">
                <Typography color="primary" fontWeight={500} width={230}>
                  {t("Role")}:
                </Typography>
                <Typography>{item.roleName}</Typography>
              </Stack>
              <DeleteOutline
                color="secondary"
                onClick={() => deleteItemHandler(index)}
              />
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography color="primary" fontWeight={500} width={230}>
                {t("Number of licenses")}:
              </Typography>
              <Typography>{item.quantity}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography color="primary" fontWeight={500} width={230}>
                {t("Number of months")}:
              </Typography>
              <Typography>{item.num_months}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>

      {orderItems.length > 0 && (
        <Stack
          width={"100%"}
          direction="row"
          justifyContent={"flex-end"}
          gap={2}
        >
          <Button
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={closeHandler}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={onSubmitOrder}
            disabled={addPurchaseOrder.loading}
          >
            {t("Submit Order")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

const OrderForm = ({ addItemHandler }) => {
  const { t } = useTranslation();
  const { control, register, setValue, trigger, handleSubmit } = useForm();

  //api
  const getPractitionerTypes = useApi(main.getPractitionerTypes);

  useEffect(() => {
    getPractitionerTypes.request();
  }, []);

  return (
    <Stack direction={"row"} gap={2} alignItems="center">
      <Stack direction={"row"} gap={2} alignItems="center">
        <Typography>{t("Role")}</Typography>
        <CebSelectField
          register={register}
          control={control}
          items={getPractitionerTypes.data}
          idName="id"
          width="200px"
          name="role_user"
        />
      </Stack>
      <CebTextField
        name="quantity"
        register={register}
        type="number"
        trigger={trigger}
        setValue={setValue}
        width="180px"
        placeholder={t("Number of licenses")}
      />
      <CebTextField
        name="num_months"
        register={register}
        type="number"
        trigger={trigger}
        setValue={setValue}
        width="180px"
        placeholder={t("Number of months")}
      />
      <CustomAddButton
        onClick={handleSubmit((data) =>
          addItemHandler({
            ...data,
            quantity: Number(data.quantity),
            roleName: getPractitionerTypes.data.find(
              (item) => item.id === data.role_user
            )?.name,
          })
        )}
      />
    </Stack>
  );
};
