import { useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";
// import useAuth from "../auth/useAuth";
import { useNavigate } from "react-router-dom";
import { socketBaseURL } from "../api/client";
import { getJwt } from "../auth/authService";
import { Context } from "../context/Context";
//import getModuleURL from "../helpers/getModuleURL";

const useWebSocket = () => {
  const { addToast } = useToasts();
  const [messageReceived, setMessageReceived] = useState(0);
  const { toastClickKey, getNotificationUnreadCount } = useContext(Context);
  const [toastClick, setToastClick] = toastClickKey;

  const navigate = useNavigate();

  const openEventListener = (event) => {
    console.log("Websocket Client Connected");
  };

  const incomingMessageListener = (message) => {
    console.log("Hello ?? ?? ?? ?? ?? ?? ");
    console.log("message data", JSON.parse(message?.data));
    setMessageReceived((old) => old + 1);
    getNotificationUnreadCount();
    const msg = JSON.parse(message?.data);

    if (msg.type === "approve") {
      addToast(`Approved successfully`, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(
        `patients/${msg.patient_id}/${msg.patient_age}/${msg.patient_gender}/admission/?add=true`
      );
    } else {
      addToast(`${msg.title}: ${msg.message}`, {
        appearance: "info",
        autoDismiss: true,
      });
      setToastClick({
        onToastClick: () => {
          //navigate(getModuleURL(msg));
          window.location.reload();
        },
        notification: true,
      });
    }
  };

  const closeSocket = (event, client = null) => {
    console.log("You are disconnected");
    // setTimeout(()=>{
    //   if (client) { //not logged out
    //     console.log("Retrying connection")
    //     client = new WebSocket('ws://fgc-fm.net/ws/web-notification/')
    //     client.addEventListener('open', openEventListener)
    //     client.addEventListener('message',incomingMessageListener)
    //     client.addEventListener('close', closeSocket)
    //   }
    // }, 5000)
  };

  const handleWebSocket = () => {
    if (!getJwt()) return "not logged in";
    console.log("entered socket");
    var client = new WebSocket(
      `${socketBaseURL}/ws/web-notification/?token=${getJwt()}`
    );

    client.addEventListener("open", openEventListener);
    client.addEventListener("message", incomingMessageListener);
    client.addEventListener("close", closeSocket);
  };

  const handleWebSocketClose = () => {
    var client = new WebSocket(
      `${socketBaseURL}/ws/web-notification/?token=${getJwt()}`
    );
    client.addEventListener("close", closeSocket(client));
  };

  return { handleWebSocket, handleWebSocketClose, messageReceived };
};

export default useWebSocket;
