import { useCallback } from "react";
import * as yup from "yup";
import { parse, isDate } from "date-fns";
import moment from "moment";
import { t } from "i18next";

/*
PARAMS:
1- fields = [{name:"field_name", type:"text", validation:{required:true, min:5 }}]
--Date Validation : {min: Date, max: Date, minField:"field_name of min Date", maxField: "field_name of max Date"  }
--Number Validation : {min:2, max:8, length:5 (for exact length)}

USE CASE:
const resolver = useYupResolver([
    {
      name: "test_name1",
      type: "number",
      validation: {
        max: 5,
        required: true,
      },
    },
  ]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver,
  });

*/
function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}

const errorMessage = (customMessage, defaultMessage) => {
  return customMessage ? customMessage : defaultMessage;
};

const createSchema = (fields) => {
  let schema = {};
  fields.forEach((f) => {
    if (f.twoFieldsInRow || f.relatedFields) {
      schema = { ...schema, ...createSchema(f.fields) };
    } else if (f.fieldType === "nested") {
      schema = {
        ...schema,
        ...createSchema(
          f.fields.map((item) => ({ ...item, name: `${f.name}_${item.name}` }))
        ),
      };
    } else {
      const v = f.validation;
      const customMessage = f.validation?.message;
      let numberLength = 0;
      if (!f.multipleRecords) {
        if (!f.type || f.type === "text") {
          schema[f.name] = yup.string().nullable();
          if (v?.required)
            schema[f.name] = schema[f.name].required(t("Required"));
          if (v?.max)
            schema[f.name] = schema[f.name].test(
              "max",
              errorMessage(t(customMessage), `Maximum ${v.max} characters`),
              (val) => {
                if (val) {
                  return val.length <= v.max;
                } else if (v?.required) return false;
                else return true;
              }
            );
          if (v?.min)
            schema[f.name] = schema[f.name].test(
              "min",
              errorMessage(t(customMessage), `Minimum ${v.min} characters`),
              (val) => {
                if (val) {
                  return val.length >= v.min;
                } else if (v?.required) return false;
                else return true;
              }
            );
          if (v?.length)
            schema[f.name] = schema[f.name].test(
              "len",
              errorMessage(t(customMessage), `Must be ${v.length} characters`),
              (val) => {
                if (val) {
                  return val.length === v.length;
                } else if (v?.required) return false;
                else return true;
              }
            );
        } else if (f.type === "number" || f.type === "decimal") {
          schema[f.name] = yup
            .number()
            .typeError("Must be number")
            .nullable()
            .transform((_, val) => {
              if (val === "") return null;
              else {
                numberLength = val.length;
                return Number(val);
              }
            });

          if (v?.required)
            schema[f.name] = schema[f.name].required(t("Required"));
          if (v?.integer)
            schema[f.name] = schema[f.name].integer(
              `${f.label} must be integer`
            );
          if (v?.positive)
            schema[f.name] = schema[f.name].positive(
              `${f.label} must be positive`
            );
          if (v?.negative)
            schema[f.name] = schema[f.name].negative(
              `${f.label} must be negative`
            );
          if (v?.minValue) {
            schema[f.name] = schema[f.name].min(
              v.minValue,
              errorMessage(
                t(customMessage),
                `Must be greater than or equal ${v.minValue}`
              )
            );
          }
          if (v?.maxValue) {
            schema[f.name] = schema[f.name].max(
              v.maxValue,
              errorMessage(
                t(customMessage),
                `Must be less than or equal ${v.maxValue}`
              )
            );
          }
          if (v?.max)
            schema[f.name] = schema[f.name].test(
              "max",
              errorMessage(t(customMessage), `Maximum ${v.max} numbers`),
              (val) => {
                if (val) {
                  return numberLength <= v.max;
                } else if (v?.required) return false;
                else return true;
              }
            );
          if (v?.min)
            schema[f.name] = schema[f.name].test(
              "min",
              errorMessage(t(customMessage), `Minimum ${v.min} numbers`),
              (val) => {
                if (val) {
                  return numberLength >= v.min;
                } else if (v?.required) return false;
                else return true;
              }
            );
          if (v?.length)
            schema[f.name] = schema[f.name].test(
              "len",
              errorMessage(
                t(customMessage),
                `${t("Must be")} ${v.length} ${t("numbers")}`
              ),
              (val) => {
                if (val) {
                  return numberLength === v.length;
                } else if (v?.required) return false;
                else return true;
              }
            );
        }
        // if (
        //   !f.type ||
        //   f.type === "text" ||
        //   f.type === "number" ||
        //   f.type === "decimal"
        // ) {
        //   schema[f.name] =
        //     f.type === "number" || f.type === "decimal"
        //       ? yup
        //           .number()
        //           .typeError("Must be number")
        //           .nullable()
        //           .transform((_, val) => {
        //             if (val === "") return null;
        //             else {
        //               numberLength = val.length;
        //               return Number(val);
        //             }
        //           })
        //       : yup.string().nullable();

        //   if (v?.required) schema[f.name] = schema[f.name].required("Required");
        //   if (v?.integer)
        //     schema[f.name] = schema[f.name].integer(
        //       `${f.label} must be integer`
        //     );
        //   if (v?.positive)
        //     schema[f.name] = schema[f.name].positive(
        //       `${f.label} must be positive`
        //     );
        //   if (v?.minValue) {
        //     schema[f.name] = schema[f.name].min(
        //       v.minValue,
        //       errorMessage(
        //         customMessage,
        //         `Must be greater than or equal ${v.minValue}`
        //       )
        //     );
        //   }
        //   if (v?.maxValue) {
        //     schema[f.name] = schema[f.name].max(
        //       v.maxValue,
        //       errorMessage(
        //         customMessage,
        //         `Must be less than or equal ${v.maxValue}`
        //       )
        //     );
        //   }
        //   if (v?.max)
        //     schema[f.name] = schema[f.name].test(
        //       "max",
        //       errorMessage(
        //         customMessage,
        //         `Maximum ${v.max} ${
        //           f.type === "number" || f.type === "decimal"
        //             ? "numbers"
        //             : "characters"
        //         }`
        //       ),
        //       (val) => {
        //         if (val) {
        //           return (
        //             ((f.type === "number" || f.type === "decimal") &&
        //               numberLength <= v.max) ||
        //             val.length <= v.max
        //           );
        //         } else if (v?.required) return false;
        //         else return true;
        //       }
        //     );
        //   if (v?.min)
        //     schema[f.name] = schema[f.name].test(
        //       "min",
        //       errorMessage(
        //         customMessage,
        //         `Minimum ${v.min} ${
        //           f.type === "number" || f.type === "decimal"
        //             ? "numbers"
        //             : "characters"
        //         }`
        //       ),
        //       (val) => {
        //         if (val) {
        //           return (
        //             ((f.type === "number" || f.type === "decimal") &&
        //               numberLength >= v.min) ||
        //             val.length >= v.min
        //           );
        //         } else if (v?.required) return false;
        //         else return true;
        //       }
        //     );
        //   if (v?.length)
        //     schema[f.name] = schema[f.name].test(
        //       "len",
        //       errorMessage(
        //         customMessage,
        //         `Must be ${v.length} ${
        //           f.type === "number" || f.type === "decimal"
        //             ? "numbers"
        //             : "characters"
        //         }`
        //       ),
        //       (val) => {
        //         if (val) {
        //           return (
        //             ((f.type === "number" || f.type === "decimal") &&
        //               numberLength === v.length) ||
        //             val.length === v.length
        //           );
        //         } else if (v?.required) return false;
        //         else return true;
        //       }
        //     );
        // }
        else if (
          f.type === "date" ||
          f.type === "dateTime" ||
          f.type === "time"
        ) {
          schema[f.name] = yup
            .string()
            .nullable()
            .test(
              "format",
              f.type === "time" ? t("Invalid Time") : t("Invalid Date"),
              (val) =>
                val ? moment(val).isValid() : v?.required ? false : true
            )
            .typeError(errorMessage(t(customMessage), t("Invalid Date")));
        } else if (f.type === "file") {
          schema[f.name] = yup
            .mixed()
            .nullable()
            .test(
              "fileFormat",
              errorMessage(t(customMessage), t("Invalid File")),
              (value) => {
                if (!value[0] && !v?.required) return true;
                if (!value[0] && v?.required) return false;
                for (const file of value) {
                  if (!f.acceptFiles.includes(file.type)) return false;
                }
                return true;
              }
            );
        } else if (f.type === "select" && f.validation?.required) {
          if (f.autoComplete && !f.multiple) {
            schema[f.name] = yup
              .mixed()
              .nullable()
              .test(
                "fileFormat",
                errorMessage(t(customMessage), t("Required")),
                (value) => {
                  if (!value && !v?.required) return true;
                  if (!value && v?.required) return false;
                  return true;
                }
              );
          } else if (!f.multiple) {
            schema[f.name] = yup.string().nullable().required(t("Required"));
          } else {
            schema[f.name] = yup
              .mixed()
              .test(
                "fileFormat",
                errorMessage(t(customMessage), t("Required")),
                (value) => {
                  if (value.length === 0) return false;
                  else return true;
                }
              );
          }
        } else if (f.type === "email") {
          schema[f.name] = yup
            .string()
            .email(t("Invalid email format"))
            .nullable();
          if (v?.required)
            schema[f.name] = schema[f.name].required(t("Required"));
        } else if (f.type === "ratio") {
          schema[f.name] = yup
            .string()
            .matches(/^\d+:\d+$/, "Enter correct ratio (number:number)")
            .nullable()
            .transform((_, val) => (val === "" ? null : val));
          if (v?.required)
            schema[f.name] = schema[f.name].required(t("Required"));
        } else if (f.type === "url") {
          schema[f.name] = yup.string().url();
        }
      }
    }
  });
  return schema;
};

const useYupResolver = (fields) =>
  useCallback(
    async (data) => {
      //Craete Schema
      const schema = createSchema(fields);

      //Validate Schema
      try {
        const values = await yup.object().shape(schema).validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [fields]
  );

export default useYupResolver;
