import React from "react";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { styled, IconButton } from "@mui/material";

const StyledAddIcon = styled(AddSharpIcon)`
  color: white;
  :hover {
    color: black;
  }
`;

const CustomAddButton = ({ onClick, onKeyDown, submitBtn = false, color }) => (
  <>
    {!submitBtn && (
      <IconButton
        sx={{
          width: 30,
          height: 30,
          backgroundColor:
            color === "secondary" ? "secondary.main" : "primary.main",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <StyledAddIcon />
      </IconButton>
    )}

    {submitBtn && (
      <IconButton
        sx={{
          width: 30,
          height: 30,
          backgroundColor:
            color === "secondary" ? "secondary.main" : "primary.main",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        type="submit"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <StyledAddIcon />
      </IconButton>
    )}
  </>
);

export default CustomAddButton;
