import main from "api/main";
import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import Modal from "components/UI/Modal";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExternalClinicDetails from "./ExternalClinicDetails";
import { DeleteOutline } from "@mui/icons-material";
import authService from "auth/authService";
import { useTranslation } from "react-i18next";

const ExternalClinics = () => {
  const { t } = useTranslation();
  //cebform view mode
  const [viewMode, setViewMode] = useState("cards");
  const [modalViewMode, setModalViewMode] = useState("form");
  const [openModal, setOpenModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  //api
  const getClinicList = useApi(main.getClinicList);
  const addClinic = useApi(main.addClinic);
  const getSpecialtyList = useApi(main.getSpecialtyList);
  const deleteClinic = useApi(main.deleteClinic);

  const isHospitalProfile =
    authService.getCurrentUser()?.workplace_type?.hospital_profile;

  //handle detail view
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    getClinicList.request(pageNumber);
  }, [addClinic.data, deleteClinic.data, pageNumber]);

  useEffect(() => {
    if (openModal) getSpecialtyList.request();
  }, [openModal]);

  const cardsItem = [
    {
      header: "Name",
      keyName: "data.clinic_name",
    },
    {
      header: "Specialty",
      keyName: "data.specialty",
    },
    {
      header: "Number of Practitioners",
      keyName: "data.num_doctors",
    },
    isHospitalProfile && {
      header: "Delete",
      type: "element",
      element: <DeleteOutline />,
      onClickHandler: (card) => deleteClinic.request(card.id),
    },
  ].filter((item) => item);

  const fields = [
    {
      name: "clinic_name",
      label: "Name",
      validation: { required: true },
    },
    {
      name: "specialty",
      label: "Specialty",
      type: "select",
      items: getSpecialtyList.data,
      validation: { required: true },
    },
    {
      label: "Phone Number",
      name: "phone_number",
      type: "number",
      validation: {
        required: true,
        length: 11,
        message: "Phone number must be 01xxxxxxxxx",
      },
    },
  ];

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const onSubmitNewClinic = async (data) => {
    const res = await addClinic.requestWithToast(t("Added successfully"), data);
    if (res.ok) {
      handleOpenModal();
      setPageNumber(1);
    }
  };

  return (
    <>
      {urlParams.get("id") ? (
        <ExternalClinicDetails
          clinicId={urlParams.get("id")}
          isHospitalProfile={isHospitalProfile}
        />
      ) : (
        <MainContainer
          title={isHospitalProfile ? "External Clinics" : "Clinic Management"}
        >
          {isHospitalProfile && (
            <>
              <Modal open={openModal} handleOpen={handleOpenModal}>
                <CebForm
                  viewModeState={[modalViewMode, setModalViewMode]}
                  fields={fields}
                  onSubmit={onSubmitNewClinic}
                  modalForm
                  modalTitle="Add New Clinic"
                  formWidth={700}
                  cancelHandler={handleOpenModal}
                />
              </Modal>

              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Button
                  variant="outlined"
                  onClick={handleOpenModal}
                  style={{
                    width: 145,
                    height: 48,
                    borderRadius: 10,
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                >
                  {t("Add New")}
                </Button>
              </Stack>
            </>
          )}
          <CebForm
            viewModeState={[viewMode, setViewMode]}
            cardItems={cardsItem}
            cardsData={getClinicList.data}
            handleViewButton={(card) => navigate(`?id=${card.id}`)}
            loading={getClinicList.loading}
            totalCount={getClinicList.count}
            paginationState={[pageNumber, setPageNumber]}
          />
        </MainContainer>
      )}
    </>
  );
};

export default ExternalClinics;
