import React, { useEffect } from "react";

import MainContainer from "components/Entity/MainContainer";
import api from "../../api/main";
import { DashboardCard } from "components/Entity/DashboardCard";
import CountCard from "components/CountCard";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { CardContainer } from "components/Entity/StyledComponents";
import doctorsImg from "assets/images/doctors.png";
import BulletIcon from "assets/icons/Bullet";
import NavArrow from "assets/icons/NavArrow";
import { useNavigate } from "react-router-dom";
import useApi from "hooks/useApi";
import main from "../../api/main";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const getAdminDashboardList = useApi(main.getAdminDashboardList);

  useEffect(() => {
    getAdminDashboardList.request();
  }, []);

  console.log(getAdminDashboardList.data);

  return (
    <Box sx={{ width: "97%", maxWidth: "1500px", pb: 4 }}>
      <Typography
        color={"secondary"}
        sx={{ mb: 2, fontWeight: 700, fontSize: "31px" }}
      >
        Dashboard
      </Typography>
      <Stack direction="row" gap="80px">
        <Stack flex={1} gap="16px">
          <Stack position="relative" height="216px" justifyContent="center">
            <CardContainer
              style={{
                height: "129px",
                justifyContent: "center",
                paddingLeft: "32px",
              }}
            >
              <Typography fontSize="20px" fontWeight={500}>
                WELCOME BACK
              </Typography>
            </CardContainer>
            <img
              src={doctorsImg}
              style={{ position: "absolute", top: 0, right: 16 }}
            />
          </Stack>
          <StatusCard
            title={"New Institutions"}
            items={getAdminDashboardList.data?.entities?.map((item) => ({
              name: item.full_name,
              status:
                item.creation_status === "Pending"
                  ? "pending"
                  : item.is_active
                  ? "active"
                  : "suspended",
              link: `/superadmin/entities?id=${item.id}`,
            }))}
          />
        </Stack>
        <Stack pt="35px" width="30%" gap="16px">
          <CountCard
            title="Number of active institutions"
            count={getAdminDashboardList.data?.active_entity_count}
            percentage={{
              value: getAdminDashboardList.data?.active_entity_percentage,
              color: "#0050A0",
            }}
            link="/superadmin/entities"
          />
          <CountCard
            title="Number of active users"
            count={getAdminDashboardList.data?.active_user_count}
            percentage={{
              value: getAdminDashboardList.data?.active_user_percentage,
              color: "#EF8C18",
            }}
            link="/superadmin/employees"
          />
          <QuickNavCard
            title="License Management"
            items={getAdminDashboardList.data?.entities?.map((item) => ({
              label: item.full_name,
              link: `/superadmin/license?id=${item?.id}&name=${item?.full_name}`,
            }))}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default Dashboard;

export const QuickNavCard = ({ title, items }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack
      width="100%"
      borderRadius="10px"
      border="1px solid rgba(0,0,0,0.3)"
      padding="16px"
      gap="16px"
      maxHeight={350}
      overflow="auto"
    >
      <Typography fontWeight={500} fontSize="16px">
        {title}
      </Typography>
      <Stack gap="16px">
        {items?.map((item, index) => (
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            paddingBottom="8px"
            borderBottom={
              index !== items.length - 1 && "0.6px solid rgba(0,0,0,0.3)"
            }
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <BulletIcon />
              <Typography>{item?.label}</Typography>
            </Stack>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <Typography fontWeight={500} fontSize="13px" color="#0050A0">
                {t("Manage")}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export const StatusCard = ({ title, items = [], charts }) => {
  const navigate = useNavigate();
  return (
    <Stack
      width="100%"
      borderRadius="10px"
      border="1px solid rgba(0,0,0,0.3)"
      padding="16px"
      gap="16px"
    >
      <Typography fontWeight={500} fontSize="16px">
        {title}
      </Typography>
      {charts && <Stack>{charts}</Stack>}
      {items.length > 0 && (
        <Stack gap={"16px"} maxHeight={500} overflow="auto">
          <Grid container rowgap={"16px"}>
            <Grid item xs={5}>
              <Typography fontWeight={500} fontSize="16px">
                Name
              </Typography>
            </Grid>
            <Grid item xs={3} textAlign="center">
              <Typography fontWeight={500} fontSize="16px">
                Status
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Typography fontWeight={500} fontSize="16px">
                Action
              </Typography>
            </Grid>
          </Grid>
          {items?.map((item, index) => (
            <Grid
              container
              paddingBottom="8px"
              borderBottom={
                index !== items.length - 1 && "0.6px solid rgba(0,0,0,0.3)"
              }
            >
              <Grid xs={5}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <BulletIcon />
                  <Typography>{item.name}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={3} display="flex" justifyContent={"center"}>
                <Box
                  width={100}
                  height={32}
                  backgroundColor={
                    item.status === "pending"
                      ? "rgba(139, 128, 0, 0.3)"
                      : item.status === "active"
                      ? "rgba(16, 166, 58, 0.3)"
                      : "rgba(142, 2, 1, 0.3)"
                  }
                  borderRadius={10}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  alignSelf="center"
                >
                  <Typography
                    textAlign={"center"}
                    color={
                      item.status === "pending"
                        ? "rgba(139, 128, 0, 1)"
                        : item.status === "active"
                        ? "rgba(16, 166, 58, 1)"
                        : "rgba(142, 2, 1, 1)"
                    }
                    fontSize={16}
                  >
                    {item.status?.toUpperCase()[0] + item.status?.substr(1)}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={4}
                display="flex"
                justifyContent={"center"}
                alignItems="center"
              >
                <Stack
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(item.link);
                  }}
                  direction="row"
                  gap={"-2px"}
                >
                  <Box
                    border="1px solid rgba(0, 0, 0, 0.3)"
                    sx={{
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                    p="1px 4px 1px 4px"
                  >
                    <Typography fontWeight={400} fontSize="13px">
                      Manage
                    </Typography>
                  </Box>

                  <NavArrow style={{ zIndex: 2, backgroundColor: "white" }} />
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
