import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import auth from "./authService";

const ProtectedRoute = ({userRole}) => {
  const location = useLocation();
  let user = auth.getCurrentUser()

  React.useEffect(() => {
    console.log('location', location.pathname)
  }, [])

  return  user? (
    user?.role === userRole ? <Outlet /> : <Navigate to="/login" state={{ from: location.pathname }} />
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  );
};

export default ProtectedRoute;
