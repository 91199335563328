import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";

import Navbar from "components/Navigation/Navbar";
import { Route, Routes } from "react-router-dom";
import SideBar from "../../components/Navigation/Sidebar";
import Employees from "../Entity/Employees";

import ProtectedRoute from "auth/protectedRoute";
import {
  EMPLOYEES,
  ENTITIES,
  HOME,
  LICENSE,
  PERMISSIONS,
  CUSTOM_FORM,
  PURCHASE_ORDERS,
  ENTITY_TYPES,
} from "config/Constants";
import useWebSocket from "hooks/useWebSocket";
import Entities from "pages/Entities";
import Role from "pages/RolePermissions/Role";
import Dashboard from "./Dashboard";
import EntityLicenseList from "./EntityLicenseList";
import CustomFormPage from "pages/CustomFormPage";
import PurchaseOrders from "pages/Entity/PurchaseOrders";
import EntityTypes from "./EntityTypes";

export default () => {
  const webSocket = useWebSocket();

  useEffect(() => {
    webSocket.handleWebSocket();
  }, []);

  return (
    <Stack
      direction={"row"}
      width="100vw"
      sx={{ position: "relative", overflow: "hidden" }}
    >
      <Navbar webSocket={webSocket} superadmin />
      <SideBar superadmin />
      <Stack sx={{ flex: 1, pl: "220px", pt: "70px", pr: "50px" }}>
        <Routes>
          <Route path={HOME} element={<ProtectedRoute userRole={"Admin"} />}>
            <Route path={HOME} element={<Dashboard />} />
          </Route>
          <Route
            path={EMPLOYEES}
            element={<ProtectedRoute userRole={"Admin"} />}
          >
            <Route path={EMPLOYEES} element={<Employees />} />
          </Route>
          <Route
            path={ENTITIES}
            element={<ProtectedRoute userRole={"Admin"} />}
          >
            <Route path={ENTITIES} element={<Entities />} />
          </Route>
          <Route path={LICENSE} element={<ProtectedRoute userRole={"Admin"} />}>
            <Route path={LICENSE} element={<EntityLicenseList />} />
          </Route>

          <Route
            path={PERMISSIONS}
            element={<ProtectedRoute userRole={"Admin"} />}
          >
            <Route path={PERMISSIONS} element={<Role superadmin />} />
          </Route>
          <Route
            path={CUSTOM_FORM}
            element={<ProtectedRoute userRole={"Admin"} />}
          >
            <Route path={CUSTOM_FORM} element={<CustomFormPage />} />
          </Route>
          <Route
            path={PURCHASE_ORDERS}
            element={<ProtectedRoute userRole={"Admin"} />}
          >
            <Route path={PURCHASE_ORDERS} element={<PurchaseOrders />} />
          </Route>
          <Route
            path={ENTITY_TYPES}
            element={<ProtectedRoute userRole={"Admin"} />}
          >
            <Route path={ENTITY_TYPES} element={<EntityTypes />} />
          </Route>
        </Routes>
      </Stack>
    </Stack>
  );
};
