import React from "react";

export default ({ isActive }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    style={
      isActive
        ? {
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: 2,
            color: "#005AB3",
          }
        : { padding: 2, color: "#fff" }
    }
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_144_43"
      maskUnits="userSpaceOnUse"
      x="4"
      y="5"
      width="40"
      height="38"
    >
      <path
        d="M22.8681 24.298C24.1161 25.5202 25.0172 27.0519 25.4792 28.7365C25.9413 30.421 25.9478 32.1981 25.4981 33.886C25.0444 35.5764 24.1519 37.1168 22.911 38.3511C21.6701 39.5853 20.1249 40.4695 18.4321 40.914C16.7374 41.3622 14.9546 41.3565 13.2628 40.8975C11.571 40.4385 10.0298 39.5423 8.79406 38.299C6.98183 36.4364 5.97796 33.9338 6.00062 31.3351C6.02328 28.7365 7.07063 26.2518 8.91506 24.421C12.7541 20.601 18.9611 20.548 22.8661 24.3L22.8681 24.298V24.298Z"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M23 24L40 7"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3052 16.9L35.7342 22.3L42.0672 16L36.6392 10.6L30.3052 16.9V16.9Z"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0_144_43)">
      <path d="M0 0H48V48H0V0Z" fill="currentColor" />
    </g>
  </svg>
);
