import React from "react";

export default () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="50" height="50" rx="25" fill="#8E0201" />
    <path
      d="M25.0002 38.3333C26.4668 38.3333 27.6668 37.1333 27.6668 35.6667H22.3335C22.3335 37.1333 23.5335 38.3333 25.0002 38.3333ZM33.0002 30.3333V23.6667C33.0002 19.5733 30.8268 16.1467 27.0002 15.24V14.3333C27.0002 13.2267 26.1068 12.3333 25.0002 12.3333C23.8935 12.3333 23.0002 13.2267 23.0002 14.3333V15.24C19.1868 16.1467 17.0002 19.56 17.0002 23.6667V30.3333L14.3335 33V34.3333H35.6668V33L33.0002 30.3333ZM30.3335 31.6667H19.6668V23.6667C19.6668 20.36 21.6802 17.6667 25.0002 17.6667C28.3202 17.6667 30.3335 20.36 30.3335 23.6667V31.6667Z"
      fill="white"
    />
  </svg>
);
