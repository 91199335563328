
import * as React from "react";

const SvgComponent = (props) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97625 11.4653C5.97625 10.4243 6.79362 9.55089 7.92225 9.54901C9.051 9.54701 9.78012 9.55089 9.78012 9.55089C9.78012 9.55089 9.73162 8.43839 9.73162 7.55364C9.73162 6.66864 10.51 5.78301 11.6394 5.78301C12.7687 5.78301 23.2396 5.75051 24.3015 5.82926C25.3634 5.90801 26.2085 6.57589 26.2085 7.65014C26.2085 8.72439 26.2017 19.376 26.2017 20.3626C26.2017 21.3491 25.3167 22.2455 24.2195 22.2579C23.1222 22.2704 22.3786 22.2454 22.3786 22.2454C22.3786 22.2454 22.419 23.4916 22.419 24.2571C22.419 25.0225 21.529 26.1341 20.4847 26.1341C19.4405 26.1341 8.84512 26.2019 7.83412 26.2019C6.82312 26.2019 5.89012 25.3124 5.93325 24.2035C5.97637 23.0946 5.97625 12.506 5.97625 11.4653ZM11.7337 7.90439L11.7252 20.1749C11.7252 20.3119 11.837 20.4236 11.9751 20.4229L23.9195 20.3591C23.9859 20.3588 24.0495 20.3322 24.0963 20.2851C24.1431 20.238 24.1693 20.1743 24.1692 20.1079L24.1612 7.94751C24.1611 7.88094 24.1346 7.81713 24.0875 7.77009C24.0404 7.72306 23.9766 7.69664 23.91 7.69664L11.985 7.65589C11.9521 7.65556 11.9195 7.66174 11.8891 7.67408C11.8586 7.68641 11.8309 7.70466 11.8076 7.72777C11.7842 7.75087 11.7657 7.77838 11.753 7.80869C11.7403 7.83901 11.7338 7.87153 11.7337 7.90439ZM7.9085 11.974L8.023 24.0048C8.02382 24.071 8.05084 24.1343 8.09815 24.1807C8.14546 24.2271 8.20922 24.2529 8.2755 24.2525L20.0411 24.1631C20.1067 24.1628 20.1715 24.1494 20.2319 24.1239C20.2923 24.0984 20.347 24.0611 20.3929 24.0143C20.4388 23.9676 20.4751 23.9122 20.4995 23.8513C20.5239 23.7905 20.5361 23.7254 20.5352 23.6599L20.5181 22.2678C20.5181 22.2678 12.6585 22.34 11.6364 22.34C10.6142 22.34 9.70662 21.3725 9.70662 20.2838C9.70662 19.195 9.78012 11.6963 9.78012 11.6963C9.78137 11.5588 9.67087 11.4475 9.53312 11.4494L8.40475 11.4656C8.27159 11.4674 8.14458 11.522 8.05156 11.6173C7.95854 11.7126 7.9071 11.8409 7.9085 11.974Z" fill="#0050A0"/>
    </svg>
    
);

export default SvgComponent;
