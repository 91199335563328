import React from "react";
import { Container, Stack, Box } from "@mui/material";

import SideBar from "../../components/Navigation/Sidebar";
import Employees from "./Employees";
import Navbar from "components/Navigation/Navbar";
import { Route, Routes } from "react-router-dom";
import Setup from "pages/Entity/Setup";

import Enrollment from "./Enrollment";
import ProtectedRoute from "auth/protectedRoute";
import TeamSetup from "./TeamSetup";
import {
  EMPLOYEES,
  ENROLLMENT,
  HOME,
  LICENSE,
  PERMISSIONS,
  SETUP,
  TEAM,
  EXTERNAL_CLINICS,
  PURCHASE_ORDERS,
  PROFILE,
  CLINIC_MANAGEMENT,
} from "config/Constants";
import Role from "pages/RolePermissions/Role";
import EntityLicenseList from "pages/Superadmin/EntityLicenseList";
import ExternalClinics from "./ExternalClinics";
import EntityDashboard from "./EntityDashboard";
import PurchaseOrders from "./PurchaseOrders";
import EntityProfile from "./EntityProfile";
import authService from "auth/authService";
import { useTranslation } from "react-i18next";

export default () => {
  const { i18n } = useTranslation();
  const isHospitalProfile =
    authService.getCurrentUser()?.workplace_type?.hospital_profile;
  return (
    <Stack
      direction={"row"}
      width="100vw"
      sx={{ position: "relative", overflow: "hidden" }}
    >
      <Navbar />
      <SideBar hospitalProfile={isHospitalProfile} />
      <Stack
        sx={{
          flex: 1,
          pl: i18n.language === "en" ? "220px" : "50px",
          pr: i18n.language === "en" ? "50px" : "220px",
          pt: "70px",
        }}
      >
        <Routes>
          <Route path={HOME} element={<ProtectedRoute userRole={"Entity"} />}>
            <Route path={HOME} element={<EntityDashboard />} />
          </Route>
          {isHospitalProfile && (
            <Route
              path={SETUP}
              element={<ProtectedRoute userRole={"Entity"} />}
            >
              <Route path={SETUP} element={<Setup />} />
            </Route>
          )}
          {isHospitalProfile && (
            <Route path={TEAM} element={<ProtectedRoute userRole={"Entity"} />}>
              <Route path={TEAM} element={<TeamSetup />} />
            </Route>
          )}
          <Route
            path={EMPLOYEES}
            element={<ProtectedRoute userRole={"Entity"} />}
          >
            <Route path={EMPLOYEES} element={<Employees />} />
          </Route>

          <Route
            path={ENROLLMENT}
            element={<ProtectedRoute userRole={"Entity"} />}
          >
            <Route path={ENROLLMENT} element={<Enrollment />} />
          </Route>

          <Route
            path={LICENSE}
            element={<ProtectedRoute userRole={"Entity"} />}
          >
            <Route path={LICENSE} element={<EntityLicenseList />} />
          </Route>
          <Route
            path={PERMISSIONS}
            element={<ProtectedRoute userRole={"Entity"} />}
          >
            <Route path={PERMISSIONS} element={<Role />} />
          </Route>
          {isHospitalProfile && (
            <Route
              path={EXTERNAL_CLINICS}
              element={<ProtectedRoute userRole={"Entity"} />}
            >
              <Route path={EXTERNAL_CLINICS} element={<ExternalClinics />} />
            </Route>
          )}
          {!isHospitalProfile && (
            <Route
              path={CLINIC_MANAGEMENT}
              element={<ProtectedRoute userRole={"Entity"} />}
            >
              <Route path={CLINIC_MANAGEMENT} element={<ExternalClinics />} />
            </Route>
          )}
          <Route
            path={PURCHASE_ORDERS}
            element={<ProtectedRoute userRole={"Entity"} />}
          >
            <Route path={PURCHASE_ORDERS} element={<PurchaseOrders />} />
          </Route>
          <Route
            path={PROFILE}
            element={<ProtectedRoute userRole={"Entity"} />}
          >
            <Route path={PROFILE} element={<EntityProfile />} />
          </Route>
        </Routes>
      </Stack>
    </Stack>
  );
};
