import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "assets/icons/Logo";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import mainApi from "../api/main";
import auth from "auth/authService";
import {
  LanguageOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import useYupResolver from "hooks/useYupResolver";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const Login = ({ superadmin = false }) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm({
    resolver: useYupResolver([
      {
        name: "username",
        type: "number",
        validation: { required: true, length: 14 },
      },
      {
        name: "password",
        validation: { required: true },
      },
    ]),
    mode: "onTouched",
  });
  //const login = useApi(mainApi.login);
  const navigate = useNavigate();
  const { addToast } = useToasts();

  let user = auth.getCurrentUser();

  useEffect(() => {
    if (user?.user_id) {
      if (user?.role === "Admin") navigate("/superadmin/home");
      else navigate("/entity/home");
    }
  }, []);

  const onSubmit = async (data) => {
    const loginPrefix = superadmin ? "A" : "E";
    const response = await mainApi.login({
      ...data,
      username: loginPrefix + data.username,
    });
    if (response?.ok) {
      let user = await auth.login(response.data.data.tokens.access);
      console.log("user", user);
      if (user?.role === "Admin" && superadmin) navigate("/superadmin/home");
      else if (user?.role !== "Admin" && !superadmin) navigate("/entity/home");
    } else {
      addToast(response?.data?.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const resetPasswordHandler = async () => {
    trigger("username");
    if (watch("username") && !errors["username"]) {
      const res = await mainApi.requestResetEmail({
        username: "E" + watch("username"),
      });
      if (res.data.data?.success) {
        addToast(res.data.data.success, {
          appearance: "info",
        });
      } else if (res.data.data?.error) {
        addToast(res.data.data?.error, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else
      addToast("Please enter valid ID and try again", {
        appearance: "error",
        autoDismiss: true,
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        background:
          "linear-gradient(46.59deg, #B80B14 -3.87%, #8E0201 24.23%, #003468 100%)",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "20px",
          p: "80px",
          maxWidth: "850px",
          width: "70%",
        }}
      >
        {/* <img src={logo} alt='logo' /> */}
        <Logo />
        <Typography
          variant="h2"
          fontSize={31}
          fontWeight="700"
          color="#003468"
          mb="15px"
        >
          {superadmin ? "Superadmin Login" : t("Login")}
        </Typography>
        <Typography
          variant="body1"
          fontSize={13}
          color="rgba(0, 52, 104, 0.5)"
          sx={{ textDecoration: "underline" }}
          mb="30px"
        >
          {t("Don’t have an account")}{" "}
          <span
            style={{ color: "#003468", fontWeight: "700", cursor: "pointer" }}
            onClick={() => navigate("/signup")}
          >
            {t("Signup")}
          </span>
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LoginField
            name="username"
            label={"ID"}
            placeholder={"Enter your ID"}
            register={register}
            errors={errors}
            type="number"
            setValue={setValue}
            trigger={trigger}
          />
          <LoginPasswordField
            title="Password"
            placeholder={"Enter your password"}
            name="password"
            register={register}
            errors={errors}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#003468",
              px: "50px",
              fontWeight: "600",
              fontSize: "20px",
              borderRadius: "10px",
              mt: "60px",
              textTransform: "none",
              width: 146,
            }}
            type="submit"
          >
            {t("Login")}
          </Button>
        </form>
        <Typography
          variant="body1"
          fontSize={14}
          color="rgba(0, 52, 104, 0.5)"
          sx={{
            textDecoration: "underline",
            color: "#003468",
            fontWeight: "700",
            cursor: "pointer",
            mt: "20px",
          }}
          onClick={resetPasswordHandler}
        >
          {t("Forgot password")}
        </Typography>
        {!superadmin && (
          <IconButton
            onClick={() => {
              if (i18n.language === "en") {
                i18n.changeLanguage("ar");
                localStorage.setItem("lng", "ar");
              } else {
                i18n.changeLanguage("en");
                localStorage.setItem("lng", "en");
              }
            }}
          >
            <LanguageOutlined style={{ fontSize: "36px", color: "#003468" }} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default Login;

const LoginField = ({
  name,
  label,
  register,
  placeholder,
  errors,
  type = "text",
  setValue,
  trigger,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(label)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Stack>
          <InputBase
            variant="outlined"
            sx={{
              paddingY: 0.7,
              paddingX: 1,
              width: "100%",
              outline: `1.5px solid ${errors[name] ? "red" : "black"}`,
              borderRadius: "10px",
            }}
            size="small"
            // type={type}
            placeholder={t(placeholder)}
            {...register(name)}
            {...(setValue &&
              trigger &&
              type === "number" && {
                onChange: (e) => {
                  setValue(name, e.target.value.replace(/\D/g, ""));
                  if (errors[name]) trigger(name);
                },
              })}
          />
          {errors[name] && (
            <Box sx={{ mt: "4px" }}>
              <Typography variant="error">{errors[name].message}</Typography>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export const LoginPasswordField = ({
  name,
  title,
  placeholder,
  register,
  errors = {},
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassowrd = () => {
    setShowPassword((state) => !state);
  };
  return (
    <Grid container alignItems="center" my="20px">
      <Grid item xs={3}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <InputBase
          type={showPassword ? "text" : "password"}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #005AB3",
            borderRadius: "10px",
            height: "45px",
            padding: 1,
          }}
          // size='small'
          placeholder={t(placeholder)}
          {...register(name)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassowrd}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors[name] && (
          <Box sx={{ mt: "4px" }}>
            <Typography variant="error">{errors[name].message}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
