import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";

import useApi from "hooks/useApi";
import NavArrow from "assets/icons/NavArrow";
import main from "api/main";
import LicenseManagement from "pages/Entity/LicenseManagement";
import { getCurrentUser } from "auth/authService";

const styles = {
  textArea: {
    borderRadius: 10,
    // textAlign: 'start!important',
    border: `1px solid #00172F`,
    padding: 10,
    fontFamily: `'Ubuntu', sans-serif`,
    fontSize: 16,
    marginBottom: 30,
    "&:hover": {
      border: `1px solid #003468`,
    },
    "&.Mui-focused": {
      border: `1px solid #003468`,
    },
    width: 700,
  },
};

const EntityLicenseList = () => {
  const navigate = useNavigate();
  const [viewModeState, setViewMode] = useState("cards");
  const [chosenCardId, setChosenCardId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const getEntitiesList = useApi(main.getEntitiesList);

  //handle direct routing
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const handleLicenseDetails = (card) => {
    navigate(`?id=${card.id}&name=${card.full_name}`);
  };

  const cardItems = [
    { header: "Name", keyName: "data?.full_name" },
    {
      header: "Established Date",
      keyName: "data?.established_date",
      type: "formattedDate",
      format: "YYYY/MM/DD",
    },
    {
      header: "Action",
      type: "element",
      element: (
        <Stack
          sx={{ cursor: "pointer", backgroundColor: "#fff" }}
          direction="row"
          gap={"-2px"}
        >
          <Box
            border="1px solid rgba(0, 0, 0, 0.3)"
            sx={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            p="1px 4px 1px 4px"
          >
            <Typography fontWeight={400} fontSize="13px">
              Manage
            </Typography>
          </Box>

          <NavArrow style={{ zIndex: 2, backgroundColor: "white" }} />
        </Stack>
      ),
      onClickHandler: handleLicenseDetails,
    },
    // {
    //   header: "Status",
    //   keyName: `data.creation_status === "Pending" ? "Pending" : data.is_active ? "Active" : "Suspended"`,
    //   type: "status",
    //   statusColor: `data.creation_status === "Pending" ? "#8B8000" : data.is_active ? "green" : "secondary.main" `,
    // },
  ];

  useEffect(() => {
    if (getCurrentUser().role === "Admin" && !urlParams.get("id"))
      getEntitiesList.request(pageNumber);
  }, [pageNumber]);

  return (
    <>
      {urlParams.get("id") || getCurrentUser().role !== "Admin" ? (
        <LicenseManagement
          entityId={urlParams.get("id")}
          entityName={urlParams.get("name")}
        />
      ) : (
        <MainContainer title={"Licenses"}>
          <Stack gap={1}>
            <CebForm
              listWidth="100%"
              fields={[]}
              cardItems={cardItems}
              cardsData={getEntitiesList.data}
              viewModeState={[viewModeState, setViewMode]}
              loading={getEntitiesList.loading}
              handleViewButton={handleLicenseDetails}
              totalCount={getEntitiesList.count}
              paginationState={[pageNumber, setPageNumber]}
            />
          </Stack>
        </MainContainer>
      )}
    </>
  );
};

export default EntityLicenseList;
