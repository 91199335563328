import React, { useEffect, useState } from "react";
import CebField from "./CebField";
import {
  Stack,
  Box,
  Button,
  Typography,
  Grid,
  Pagination,
} from "@mui/material";
import NestedForm from "./NestedForm";
import CebList from "./CebList";
import PatientCard, { PatientCardTitles } from "../patient/PatientCard";
import Filter from "../../assets/icons/Filter";
import { useForm, useWatch } from "react-hook-form";
import useYupResolver from "../../hooks/useYupResolver";
import { LoadingBox } from "../UI/LoadingBox";
import { useToasts } from "react-toast-notifications";
import { defaultPageSize } from "config/Constants";
import { useTranslation } from "react-i18next";

const getFieldsArr = (fields, conditionalFields) => {
  let arr = [];
  for (const field of fields) {
    if (field.relatedFields) {
      arr.push(...field.fields);
    } else {
      arr.push(field);
    }
  }
  // console.log("arr", arr);
  // arr = arr.filter(
  //   (field, index) =>
  //     (field?.hasOwnProperty("condition") === false ||
  //       field?.condition === true ||
  //       field?.condition?.[0] === true) &&
  //     (conditionalFields[index] || field.fieldType !== "nested")
  // );
  // console.log("arr2", arr);
  return arr;
};

export default function CebForm({
  onSubmit,
  viewModeState,
  fields = [],
  fieldsToWatch,
  getWatchedFields,
  initialValues,
  listData,
  cardsData,
  addInList = false,
  handleViewButton,
  handleClickCardButton,
  cardItems,
  modalForm = false,
  modalList = false,
  modalTitle,
  handleOpenModal,
  addNewHandler,
  formWidth = "97%",
  cardsWidth = "100%",
  resetState = [],
  disableAddNew,
  children,
  loading = false,
  chosenCardId = 0,
  saveHandler = null,
  cancelHandler,
  backHandler,
  nextButtonHandler,
  cancelButtonLabel,
  hideCancelButton = false,
  saveButtonLabel = "Save",
  requestErrors = {},
  listWidth,
  profile,
  headerActionBox,
  totalCount,
  paginationState = [],
}) {
  const { t, i18n } = useTranslation();
  const [viewMode, setViewMode] = viewModeState;
  const [resetForm, setResetForm] = resetState;
  const [resetNested, setResetNested] = useState({ nestedId: 0, state: false });
  const [conditionalFields, setConditionalFields] = useState({});
  const [pageNumber, setPageNumber] = paginationState;
  const { addToast } = useToasts();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    setError,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onTouched",
    resolver: useYupResolver(
      getFieldsArr(fields).filter(
        (field, index) =>
          (field?.hasOwnProperty("condition") === false ||
            field?.condition === true ||
            field?.condition?.[0] === true) &&
          (conditionalFields[field.nestedId] || field.fieldType !== "nested")
      )
    ),
    shouldUnregister: false,
  });

  const watchedFields = useWatch({
    control: control,
    name: fieldsToWatch?.name,
    defaultValue: fieldsToWatch?.defaultValue,
  });

  useEffect(() => {
    Object.keys(requestErrors).forEach((key) => {
      setError(
        key,
        { type: "focus", message: requestErrors[key][0] },
        { shouldFocus: true }
      );
    });
  }, [requestErrors]);

  useEffect(() => {
    console.log("watched", watchedFields);
    if (watchedFields.length > 0) getWatchedFields(watchedFields);
  }, [watchedFields]);

  useEffect(() => {
    if (resetForm) {
      reset();
      setResetForm(false);
    }
  }, [resetForm]);

  const onChangeCondition = (value, index, parentIndex = 0, nestedId) => {
    console.log("nestedId", nestedId);
    setConditionalFields((state) => ({
      ...state,
      [nestedId]: value,
      // [index + parentIndex]: value,
    }));
  };

  const cebField = (f, nestedId, i, labelWidth) => (
    <CebField
      key={i}
      type={f.type ? f.type : "text"}
      childField={f.childField ? f.childField : false}
      multipleRecords={f.multipleRecords ? f.multipleRecords : false}
      multiple={f.multiple ? f.multiple : false}
      autoComplete={f.autoComplete ? f.autoComplete : false}
      isEnum={f.isEnum ? f.isEnum : false}
      width={f.width ? f.width : "100%"}
      multiline={f.multiline ? f.multiline : false}
      // rows={f.rows? f.rows : 1}
      height={f.height ? f.height : 41}
      items={f.items}
      idName={f.idName ? f.idName : "id"}
      control={control}
      register={register}
      setValue={setValue}
      reset={reset}
      name={f.name}
      label={f.label}
      readOnlyValue={f.value}
      onInputChange={f.onInputChange ? f.onInputChange : () => void 0}
      errors={errors ? errors : {}}
      acceptFiles={f.acceptFiles}
      buttonTitle={f.buttonTitle}
      onClick={f.onClick}
      objectId={f.objectId}
      watch={watch}
      element={f.element}
      disabled={f.disabled ? f.disabled : false}
      sendOnUpload={f.sendOnUpload}
      url={f.url}
      addNewValue={f.addNewValue}
      validation={f.validation ? f.validation : {}}
      trigger={trigger}
      nestedId={nestedId}
      resetNestedState={[resetNested, setResetNested]}
      defaultValue={f.defaultValue}
      selectAll={f.selectAll ? f.selectAll : false}
      disableFilter={f.disableFilter}
      labelWidth={labelWidth}
    />
  );

  useEffect(() => {
    if (initialValues) {
      for (var key of Object.keys(initialValues)) {
        setValue(key, initialValues[key]);
      }
    }
  }, [initialValues]);

  const singleCebField = (f, nestedId, i, labelWidth) => {
    if (f.hasOwnProperty("condition")) {
      if (Array.isArray(f.condition))
        return f.condition[0] && cebField(f, nestedId, i);
      else return f.condition && cebField(f, nestedId, i);
    } else return cebField(f, nestedId, i, labelWidth);
  };

  const doubleCebFields = (f, nestedId, i) => (
    <Grid container key={i} style={{ width: "100%" }}>
      <Grid
        item
        xs={f.fields[0].xs ? f.fields[0].xs : 6}
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: i18n.language === "en" && 25,
          paddingLeft: i18n.language === "ar" && 25,
        }}
      >
        {singleCebField(f.fields[0], nestedId)}
      </Grid>
      <Grid
        item
        xs={f.fields[1].xs ? f.fields[1].xs : 6}
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: i18n.language === "en" && 25,
          paddingLeft: i18n.language === "ar" && 25,
        }}
      >
        {singleCebField(f.fields[1], nestedId, i, 100)}
      </Grid>
    </Grid>
  );

  const cebFieldFunc = (f, nestedId = 0, i) => {
    if (f.twoFieldsInRow === true) return doubleCebFields(f, nestedId, i);
    else
      return (
        <Grid container key={i} style={{ width: "100%" }}>
          <Grid item xs={f.xs ? f.xs : 12}>
            {singleCebField(f, nestedId, i)}
          </Grid>
        </Grid>
      );
  };

  const NestedFormFunc = (f, index, parentIndex) => {
    return (
      <NestedForm
        key={index}
        index={index}
        nestedId={f.nestedId}
        parentIndex={parentIndex}
        condition={f.condition}
        label={f.label}
        title={f.title}
        data={f.data}
        onAdd={f.onAdd}
        onDelete={f.onDelete}
        primaryListText={f.primaryListText}
        secondaryListText={f.secondaryListText}
        width={f.width}
        fields={f.fields.map((field) => ({
          ...field,
          name: `${f.name}_${field.name}`,
        }))}
        errors={errors}
        cebFieldFunc={cebFieldFunc}
        onChangeCondition={onChangeCondition}
        watch={watch}
        setValue={setValue}
        required={f.required}
        register={register}
        control={control}
        name={f.name}
        uniqueItems={f.uniqueItems}
        resetNested={(nestedId) => {
          setResetNested({ nestedId, state: true });
        }}
        disableNested={f.disableNested}
        onReset={f.onReset}
        actionBar={f.actionBar}
      />
    );
  };

  const onSubmitForm = (data) => {
    const OpenNestedFormIds = Object.keys(conditionalFields).filter(
      (key) => conditionalFields[key] === true
    );
    for (const id of OpenNestedFormIds) {
      if (fields.find((item) => item?.nestedId === Number(id))) {
        addToast("You have unsaved changes, please save or discard them", {
          autoDismiss: true,
        });
        return;
      }
    }

    onSubmit(data);
  };

  const addField = (field, index, parentIndex) => {
    if (field.fieldType === "nested")
      return NestedFormFunc(field, index, parentIndex);
    else if (field.fieldType === "buttons") return buttonFunc(field, index);
    else if (field.fieldType === "labelField")
      return (
        <div style={{ marginTop: "35px", marginBottom: "20px" }}>
          <Typography
            variant="h5"
            color="primary"
            sx={{
              fontWeight: "bold",
            }}
          >
            {field.label}
          </Typography>
        </div>
      );
    else return cebFieldFunc(field, 0, index);
  };

  const buttonFunc = (field, i) => {
    return (
      <Stack key={i} display="flex" flexDirection="row">
        {field.fields.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            variant="outlined"
            color="primary"
            style={{
              width: 197,
              height: 46,
              borderRadius: 10,
              textTransform: "none",
              fontWeight: 500,
              marginRight: 16,
            }}
          >
            {button.title}
          </Button>
        ))}
      </Stack>
    );
  };

  const relatedFieldsStyle = (field) => {
    return field.addBorder === false
      ? {}
      : {
          border: "1px solid black",
          borderRadius: "10px",
          p: 2,
        };
  };

  const addRelatedFields = (fieldsObj, index) =>
    fieldsObj.displayCondition ? (
      <Box key={index} /*width="97%"*/ sx={relatedFieldsStyle(fieldsObj)}>
        <Stack gap={3}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            {fieldsObj.fieldsTitle && (
              <Typography variant="h6" mb={2}>
                {fieldsObj.fieldsTitle}
              </Typography>
            )}
            {fieldsObj.actionBar && <Stack>{fieldsObj.actionBar}</Stack>}
          </Stack>

          {fieldsObj.fields.map((field, i) => {
            return (
              // to use nested related fields, e.g.: adult female
              field.relatedFields === true
                ? addRelatedFields(field)
                : addField(field, i, index)
            );
          })}
        </Stack>
      </Box>
    ) : null;

  const addFormButtons = (cancelAction) => (
    <>
      {!hideCancelButton && (
        <Button
          onClick={cancelHandler ? cancelHandler : cancelAction}
          variant="outlined"
          color="primary"
          style={{
            width: 145,
            height: 46,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
            marginRight: 16,
            marginLeft: 16,
          }}
        >
          {cancelButtonLabel
            ? cancelButtonLabel
            : modalList
            ? t("Close")
            : t("Cancel")}
        </Button>
      )}
      {!modalList && (
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          color="primary"
          style={{
            width: 145,
            height: 46,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
          }}
          onClick={saveHandler}
        >
          {t(saveButtonLabel)}
        </Button>
      )}
    </>
  );

  const checkKeyDown = (e) => {
    if (e.target.type === "textarea") return;
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <>
      {viewMode === "list" && listData && (
        <>
          {loading === true ? (
            <LoadingBox loading={loading} />
          ) : (
            <>
              {modalList && (
                <>
                  <Stack
                    display="flex"
                    flexDirection="row"
                    mb={3}
                    py={1}
                    borderBottom="2px solid lightgrey"
                  >
                    <Typography
                      justifySelf="start"
                      textAlign="start"
                      flexGrow={1}
                      style={{ fontSize: "25px", fontWeight: 700 }}
                    >
                      {t(modalTitle)}
                    </Typography>
                    {addFormButtons(handleOpenModal)}
                  </Stack>
                  <Box
                    width={listWidth ? listWidth : 1000}
                    sx={{
                      /*border: "1px solid black", borderRadius: "10px",*/ p: 2,
                    }}
                  >
                    <CebList data={listData} profile={profile} />
                  </Box>
                </>
              )}
              {!modalList && (
                <>
                  <Stack direction="row" gap={2}>
                    {!addInList && (
                      <Button
                        variant="outlined"
                        onClick={
                          backHandler ? backHandler : () => setViewMode("cards")
                        }
                        style={{
                          width: 145,
                          height: 46,
                          borderRadius: 10,
                          textTransform: "none",
                          fontWeight: 500,
                        }}
                      >
                        {t("Back")}
                      </Button>
                    )}
                    {/* disable buttons when field array is empty */}
                    {/* {!disableAddNew && fields.length > 0 && (
                      <Button
                        variant="contained"
                        onClick={() => setViewMode("form")}
                        style={{
                          width: 145,
                          height: 46,
                          borderRadius: 10,
                          textTransform: "none",
                          fontWeight: 500,
                        }}
                      >
                        Add New
                      </Button>
                    )} */}
                  </Stack>
                  <Box
                    width={listWidth ? listWidth : "100%"}
                    sx={{
                      border: !profile && "1px solid black",
                      borderRadius: "20px",
                      p: "25px",
                      backgroundColor: profile ? "card.main" : "#fff",
                    }}
                  >
                    <CebList
                      data={listData}
                      cardsData={cardsData}
                      chosenCardId={chosenCardId}
                      cardItems={cardItems}
                      onClickHandler={(card) => handleClickCardButton(card)}
                      profile={profile}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}

      {viewMode === "form" && (
        <>
          <LoadingBox addMode={true} loading={loading} />
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            {modalForm && (
              <div dir={i18n.language === "en" ? "ltr" : "rtl"}>
                <Stack display="flex" flexDirection="row" mb={3} mx={6}>
                  <Typography
                    justifySelf="start"
                    textAlign="start"
                    flexGrow={1}
                    style={{ fontSize: "25px", fontWeight: 700 }}
                  >
                    {t(modalTitle)}
                  </Typography>
                  {addFormButtons(handleOpenModal)}
                </Stack>
                <Box
                  sx={{
                    height: "2px",
                    width: "95%",
                    backgroundColor: "#D9D9D9",
                    ml: 4,
                    mr: 8,
                    mb: 6,
                  }}
                />
              </div>
            )}
            <Stack
              gap={2}
              px={modalForm && 8}
              width={formWidth}
              sx={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
            >
              {fields.map((field, index) => {
                return field.relatedFields === true
                  ? addRelatedFields(field, index)
                  : addField(field, index);
              })}
            </Stack>

            {children}

            {!modalForm && (
              <>
                {nextButtonHandler && (
                  <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="end"
                    my={3}
                    style={{ width: formWidth }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      color="primary"
                      style={{
                        width: 145,
                        height: 46,
                        borderRadius: 10,
                        textTransform: "none",
                        fontWeight: 500,
                      }}
                      onClick={nextButtonHandler}
                    >
                      {t("Next")}
                    </Button>
                  </Stack>
                )}

                <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  my={3}
                  style={{ width: formWidth }}
                >
                  {addFormButtons(() =>
                    setViewMode(addInList ? "list" : "cards")
                  )}
                </Stack>
              </>
            )}
          </form>
        </>
      )}

      {viewMode === "cards" && cardsData && (
        <>
          {loading === true ? (
            <LoadingBox loading={loading} />
          ) : (
            <>
              {/* disable buttons when fields array is empty */}
              {fields.length > 0 && (
                <Stack
                  width={"100%"}
                  justifyContent="space-between"
                  alignItems={"center"}
                  direction="row"
                >
                  <Stack direction="row" gap={2}>
                    {!disableAddNew && (
                      <Button
                        variant="outlined"
                        onClick={() =>
                          addNewHandler ? addNewHandler() : setViewMode("form")
                        }
                        style={{
                          width: 145,
                          height: 48,
                          borderRadius: 10,
                          textTransform: "none",
                          fontWeight: 500,
                        }}
                      >
                        {t("Add New")}
                      </Button>
                    )}
                    {/* <Button
                      variant="outlined"
                      onClick={() => null}
                      style={{
                        width: 145,
                        height: 48,
                        borderRadius: 10,
                        textTransform: "none",
                        fontWeight: 500,
                      }}
                      endIcon={<Filter />}
                    >
                      Filter
                    </Button> */}
                  </Stack>
                  {headerActionBox && <Stack>{headerActionBox}</Stack>}
                </Stack>
              )}
              <Stack gap={2}>
                {cardsData.map((card, index) => (
                  <>
                    {index === 0 && (
                      <PatientCardTitles
                        items={cardItems}
                        width={cardsWidth}
                        // handleView={true}
                        data={card}
                      />
                    )}
                    <PatientCard
                      key={index}
                      items={cardItems}
                      data={card}
                      index={index}
                      width={cardsWidth}
                      onClickHandler={() => handleClickCardButton(card)}
                      handleView={() => handleViewButton(card)}
                    />
                  </>
                ))}
                {(cardsData.length === 0 ||
                  cardsData === undefined ||
                  cardsData === null ||
                  cardsData === {}) && (
                  <Typography
                    variant="h5"
                    color="fieldBorder"
                    textAlign="center"
                  >
                    There is no data available
                  </Typography>
                )}
              </Stack>
            </>
          )}

          {totalCount > 0 && (
            <div dir="ltr">
              <Stack alignItems={"center"} pt={4}>
                <Pagination
                  color="primary"
                  count={Math.ceil(totalCount / defaultPageSize)}
                  onChange={(event, value) => setPageNumber(value)}
                  page={pageNumber}
                />
              </Stack>
            </div>
          )}
        </>
      )}
    </>
  );
}
