// import { create } from "apisauce";
// // import cache from "../utility/cache";
// // import authStorage from "../auth/storage";
// // import settings from "../config/settings";
// // import axios from 'axios';

// const apiClient = create({
//   // baseURL: "http://yds-int.com:8077/api",
//   baseURL: "http://yds-int.ddns.net:8020" + "/api",
// });

// // const apiClient = axios.create({ baseURL: 'http://yds-int.com:8077/api' });

// // adding the token to the header of every request
// apiClient.addAsyncRequestTransform(async (request) => {
//   //   const authToken = await authStorage.getToken();
//   //   if (!authToken) return;
//   request.headers[
//     "Authorization"
//   ] = `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkyMzU2NTY4LCJpYXQiOjE2NjA4MjA1NjgsImp0aSI6IjgxMmQyYzIzNzM5ZjQ5OTZhMDZiMDIxOWIwMDU0MThjIiwidXNlcl9pZCI6IjAwMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDA2NCIsImZ1bGxfbmFtZSI6InRlc3QgZW50aXR5Iiwicm9sZSI6Nn0.5UsckPdpC3WZlsxD3OvA-5Lx4Tq_K8cZvXHAXX1fgSk`;
// });

// // apiClient.interceptors.request.use(async function (options) {
// //   const authToken = await authStorage.getToken();
// //   if (!authToken) return;
// //   options.headers['Authorization'] = `JWT ${authToken}`;
// //   return options;
// // })

// /// caching all the data fetched from the backend(for 5 min), by editing the get request
// const get = apiClient.get;
// const post = apiClient.post;

// apiClient.get = async (url, params, axiosConfig) => {
//   const response = await get(url, params, axiosConfig);

//   if (response.ok) {
//     // cache.store(url, response.data);
//     return response;
//   }

//   //   const data = await cache.get(url);
//   //   return data ? { ok: true, data } : response;
// };

// apiClient.post = async (url, body, axiosConfig) => {
//   const response = await post(url, body, axiosConfig);
//   if (response.ok) {
//     // cache.store(url, response.data);
//     return response;
//   }
// };

// export default apiClient;

import { create } from "apisauce";
import auth from "../auth/authService";
// import cache from "../utility/cache";
// import settings from "../config/settings";
export const socketBaseURL = process.env.REACT_APP_SOCKET_URL;
const apiClient = create({
  // eslint-disable-next-line
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api",
  // baseURL: "https://20c1-41-41-220-25.eu.ngrok.io" + "/api",
});

// adding the token to the header of every request
apiClient.addAsyncRequestTransform(async (request) => {
  if (localStorage.getItem("lng") === "ar")
    request.headers["Accept-Language"] = "ar";
  const authToken = await auth.getJwt();
  if (!authToken) return;
  request.headers["Authorization"] = `JWT ${authToken}`;
});

/// caching all the data fetched from the backend(for 5 min), by editing the get request
// const get = apiClient.get;
// apiClient.get = async (url, params, axiosConfig) => {
//   const response = await get(url, params, axiosConfig);

//   if (response.ok) {
//     cache.store(url, response.data);
//     return response;
//   }

//   const data = await cache.get(url);
//   return data ? { ok: true, data } : response;
// };

export default apiClient;
