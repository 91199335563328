import { createTheme } from "@mui/material/styles";

// import green from "@material-ui/core/colors/green";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003468",
      // light: "#edf4f7",
    },
    secondary: {
      main: "#8E0201",
    },
    card: {
      main: "#F0F7FF",
      mainHover: "#a4ceff",
    },
    typography: {
      main: "#000305",
    },
    gradient: {
      main: "linear-gradient(46.59deg, #B80B14 -3.87%, #8E0201 24.23%, #003468 100%);",
    },
    gray: {
      main: "gray",
      200: "#F3F4F9",
    },
    background: {
      default: "#ffff",
    },
    divider: "#A5D2FF",
    fieldBorder: "#00172F",
    white: "#ffff",
  },
  typography: {
    fontFamily: `'Ubuntu', sans-serif`,
    fontSize: 16,
    // "fontWeightLight": 300,
    // "fontWeightRegular": 400,
    // "fontWeightMedium": 500,

    body2: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "20px",
    },
    error: {
      fontSize: 16,
      fontWeight: 400,
      color: "#ff0000",
    },
  },
  components: {
    // Name of the component
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       color: 'white',
    //       '&:hover': {
    //         color: 'black'
    //       }
    //     },
    //   },
    // },
  },
  props: {
    // Name of the component ⚛️
    // MuiButton: {
    //   color: "primary",
    //   variant: "contained",
    //   disableElevation: true,
    // },
  },
});

export default theme;
