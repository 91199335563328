import React, { useEffect, useState } from "react";
import main from "../api/main";

export const Context = React.createContext(null);

const MyContext = ({ children }) => {
  const [viewMode, setViewMode] = useState("cards");
  const [permissions, setPermissions] = useState();
  const [toastClick, setToastClick] = useState(null);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState();

  const getNotificationUnreadCount = async () => {
    const res = await main.getNotificationUnreadCount();
    if (res.ok) setUnreadNotificationCount(res.data?.count);
  };
  // const [loggedIn, setLoggedIn] = React.useState(true)
  console.log("store perm", permissions);
  const store = {
    viewModeKey: [viewMode, setViewMode],
    // permissionsKey: [permissions, setPermissions],
    permissions,

    toastClickKey: [toastClick, setToastClick],
    getNotificationUnreadCount: () => getNotificationUnreadCount(),
    unreadNotificationCount,
    // loggedInKey: [loggedIn, setLoggedIn],
  };

  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export default MyContext;
