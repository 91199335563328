import React, { useState, useEffect } from "react";
import "./CebDateField.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useWatch } from "react-hook-form";

export default function CebDateTimeField({
  width = "100%",
  height = 41,
  control,
  name,
  validation,
  defaultValue,
  errors,
  type,
  ...props
}) {
  const {
    minDateField,
    maxDateField,
    disableFuture = false,
    disablePast = false,
  } = validation;

  const [minDate, setMinDate] = useState(validation.minDate);
  const [maxDate, setMaxDate] = useState(validation.maxDate);
  const [focused, setFocused] = useState(false);

  const watchedFields = useWatch({
    control,
    name: [minDateField, maxDateField],
  });

  useEffect(() => {
    if (watchedFields[0]) {
      setMinDate(new Date(watchedFields[0]));
    }
    if (watchedFields[1]) {
      setMaxDate(new Date(watchedFields[1]));
    }
  }, [watchedFields]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        defaultValue={
          defaultValue ? moment(defaultValue).format("YYYY-MM-DD HH:mm") : null
        }
        render={({ field: { onChange, value } }) => (
          <DateTimePicker
            value={value}
            onChange={(value) => {
              console.log(
                "dateTime",
                moment(value).format(
                  type === "time" ? "HH:mm" : "YYYY-MM-DDTHH:mm:ss"
                )
              );
              return onChange(
                moment(value).format(
                  type === "time" ? "HH:mm" : "YYYY-MM-DDTHH:mm:ss"
                )
              );
            }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
            openTo="day"
            views={
              type === "time"
                ? ["hours", "minutes"]
                : ["year", "month", "day", "hours", "minutes"]
            }
            minTime={
              disablePast && moment(value).isSame(new Date(), "day")
                ? new Date()
                : moment(value).isSame(minDate, "day") && minDate
            }
            maxTime={
              disableFuture && moment(value).isSame(new Date(), "day")
                ? new Date()
                : moment(value).isSame(maxDate, "day") && maxDate
            }
            renderInput={(params) => (
              <TextField
                id="date"
                variant="outlined"
                sx={{
                  width,
                  height,
                  outline: "1.5px solid black",
                  borderRadius: "10px",
                  outlineColor: focused
                    ? "#2161d1"
                    : errors?.[name]
                    ? "red"
                    : "black",
                  outlineWidth: focused && "2px",
                }}
                size="small"
                autoComplete="bday"
                {...params}
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
