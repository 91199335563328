import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Notification from "assets/icons/Notification";
import React, { useContext, useEffect, useState } from "react";

import { LanguageOutlined, Logout } from "@mui/icons-material";
import main from "api/main";
import Polygon from "assets/icons/Polygon";
import notifImg from "assets/images/notifImg.png";
import ProfileIcon from "assets/icons/Profile";
import Logo from "assets/logo.png";
import authService, { getCurrentUser } from "auth/authService";
import { Context } from "context/Context";
import useApi from "hooks/useApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PROFILE } from "config/Constants";
import { useTranslation } from "react-i18next";

const Navbar = ({ webSocket, superadmin }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const logoutHandler = () => {
    let user = authService.logout();
    if (user?.role === "Admin") navigate("/superadmin-login");
    else navigate("/login");
  };
  const { getNotificationUnreadCount, unreadNotificationCount } =
    useContext(Context);

  const [viewNotifications, setViewNotifications] = useState(false);
  const handleNotificationView = () => setViewNotifications((state) => !state);
  const messageReceived = webSocket?.messageReceived;

  useEffect(() => {
    getNotificationUnreadCount();
  }, [viewNotifications, messageReceived]);

  return (
    <>
      {viewNotifications && (
        <NotificationList handleClose={handleNotificationView} />
      )}
      <Box
        sx={{
          position: "absolute",
          right: i18n.language === "en" && 0,
          left: i18n.language == "ar" && 0,
          top: 0,
          backgroundColor: "primary.main",
          borderBottomLeftRadius: i18n.language === "en" && 40,
          borderBottomRightRadius: i18n.language === "ar" && 40,
          borderTopLeftRadius: i18n.language === "en" && 40,
          borderTopRightRadius: i18n.language === "ar" && 40,
          width: superadmin ? 260 : 290,
          height: 69,
          zIndex: 2,
          p: 0,
        }}
      >
        <Stack
          direction="row"
          sx={{
            pl: i18n.language === "en" && "35px",
            pr: i18n.language === "ar" && "35px",
            pt: "10px",
          }}
          gap={1}
        >
          <Stack position="relative">
            <IconButton
              onClick={handleNotificationView}
              sx={{ marginTop: "-7px" }}
            >
              <Notification />
            </IconButton>
            {unreadNotificationCount !== 0 && (
              <Stack
                position="absolute"
                top={3}
                right={0}
                width="25px"
                heigth="25px"
                borderRadius="45%"
                sx={{ backgroundColor: "rgba(240, 247, 255, 1)" }}
                alignItems="center"
              >
                <Typography fontSize={14} fontWeight={600} color="primary">
                  {unreadNotificationCount}
                </Typography>
              </Stack>
            )}
          </Stack>
          {!superadmin && (
            <IconButton
              onClick={() => {
                if (i18n.language === "en") {
                  i18n.changeLanguage("ar");
                  localStorage.setItem("lng", "ar");
                } else {
                  i18n.changeLanguage("en");
                  localStorage.setItem("lng", "en");
                }
                window.location.reload();
              }}
            >
              <LanguageOutlined style={{ fontSize: "36px", color: "white" }} />
            </IconButton>
          )}

          {getCurrentUser().role !== "Admin" && (
            <IconButton onClick={() => navigate("/entity" + PROFILE)}>
              <ProfileIcon />
            </IconButton>
          )}
          <IconButton style={{ color: "#fff" }} onClick={logoutHandler}>
            <Logout />
          </IconButton>
        </Stack>
      </Box>
    </>
  );
};

const NotificationList = ({ handleClose }) => {
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState("all");
  const [notificationList, setNotificationList] = useState([]);
  const [notificationPageNumber, setNotificationPageNumber] = useState(1);

  //api
  const getNotificationList = useApi(main.getNotificationList);

  const getNotificationListHandler = async () => {
    if (notificationPageNumber) {
      const res = await getNotificationList.request(notificationPageNumber);
      if (res.ok) {
        if (res.data.next) setNotificationPageNumber((state) => state + 1);
        else setNotificationPageNumber(null);
      }
    }
  };

  useEffect(() => {
    getNotificationListHandler();
  }, []);

  useEffect(() => {
    setNotificationList((state) => [...state, ...getNotificationList.data]);
  }, [getNotificationList.data]);

  console.log("notif list", notificationList);

  const scrollHandler = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;

    if (bottom && !getNotificationList.loading) {
      getNotificationListHandler();
    }
  };

  return (
    <>
      <Stack
        sx={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 3, 5, 0.2)",
          zIndex: 20,
        }}
        onClick={handleClose}
      ></Stack>
      <Stack
        sx={{
          position: "absolute",
          top: "60px",
          zIndex: 26,
          right: i18n.language === "en" && "190px",
          left: i18n.language === "ar" && "174px",
        }}
      >
        <Polygon />
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: "80px",
          right: i18n.language === "en" && "72px",
          left: i18n.language === "ar" && "72px",
          width: "474px",
          maxHeight: "85vh",
          zIndex: 25,
          backgroundColor: "#fff",
          borderRadius: "10px",
          p: 2,
        }}
      >
        <Stack
          mb={2}
          ml={i18n.language === "en" && "15px"}
          pr={i18n.language === "ar" && "15px"}
          gap={3}
        >
          <Typography fontSize="25px" fontWeight="700">
            {t("Notifications")}
          </Typography>
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              sx={{
                p: "10px 20px 10px 20px",
                borderRadius: "20px",
                backgroundColor: filter === "all" ? "#A5D2FF" : "#fff",
                cursor: "pointer",
              }}
              alignItems="center"
              justifyContent="center"
              onClick={() => setFilter("all")}
            >
              {t("All")}
            </Stack>
            <Stack
              sx={{
                p: "10px 20px 10px 20px",
                borderRadius: "20px",
                backgroundColor: filter !== "all" ? "#A5D2FF" : "#fff",
                cursor: "pointer",
              }}
              alignItems="center"
              justifyContent="center"
              onClick={() => setFilter("unread")}
            >
              {t("Unread")}
            </Stack>
          </Stack>
        </Stack>
        <div style={{ overflow: "auto" }} gap={0.8} onScroll={scrollHandler}>
          {filter === "all"
            ? notificationList.map((item) => (
                <NotificationItem
                  key={item.id}
                  title={item.title}
                  message={item.message}
                  date={item.created_at}
                  isRead={item.is_read}
                  link={"/"}
                  handleClose={handleClose}
                />
              ))
            : notificationList
                .filter((item) => !item.is_read)
                .map((item) => (
                  <NotificationItem
                    key={item.id}
                    title={item.title}
                    message={item.message}
                    date={item.created_at}
                    isRead={item.is_read}
                    link={"/"}
                    handleClose={handleClose}
                  />
                ))}
        </div>
        {getNotificationList.loading && <LinearProgress color="primary" />}
      </Stack>
    </>
  );
};

const NotificationItem = ({
  title,
  message,
  date,
  isRead,
  link,
  handleClose,
}) => {
  const [diffTime, setDiffTime] = useState({});

  const navigate = useNavigate();

  const calculateDiffTime = () => {
    const currentDate = moment(new Date());
    const notifDate = moment(date);
    if (currentDate.diff(notifDate, "year")) {
      const number = currentDate.diff(notifDate, "year");
      setDiffTime({
        number,
        unit: number > 1 ? "years" : "year",
      });
    } else if (currentDate.diff(notifDate, "month")) {
      const number = currentDate.diff(notifDate, "month");
      setDiffTime({
        number,
        unit: number > 1 ? "months" : "month",
      });
    } else if (currentDate.diff(notifDate, "day")) {
      const number = currentDate.diff(notifDate, "day");
      setDiffTime({ number, unit: number > 1 ? "days" : "day" });
    } else if (currentDate.diff(notifDate, "hour")) {
      const number = currentDate.diff(notifDate, "hour");
      setDiffTime({
        number,
        unit: number > 1 ? "hours" : "hour",
      });
    } else if (currentDate.diff(notifDate, "minute")) {
      const number = currentDate.diff(notifDate, "minute");
      setDiffTime({
        number,
        unit: number > 1 ? "minutes" : "minute",
      });
    } else {
      setDiffTime({
        number: currentDate.diff(notifDate, "second"),
        unit: "seconds",
      });
    }
  };

  useEffect(() => {
    calculateDiffTime();
  }, []);

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      width="100%"
      gap={1.5}
      sx={{
        p: "5px 10px 5px 10px",
        // borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
        backgroundColor: isRead ? "#fff" : "rgba(240, 247, 255, 1)",
        cursor: "pointer",
        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.03)" },
        // borderTopLeftRadius: "10px",
        // borderTopRightRadius: "10px",
        borderRadius: "10px",
      }}
      onClick={() => {
        navigate(link);
        window.location.reload();
        handleClose();
      }}
    >
      <Box>
        <img src={notifImg} width="50px" height="50px" />
      </Box>
      <Stack>
        <Stack>
          <Typography color="primary">{title}</Typography>
          <Typography>{message}</Typography>
          <Typography style={{ color: "rgba(0, 35, 44, 0.5)" }}>
            {diffTime.number} {diffTime.unit} ago
          </Typography>
        </Stack>
        {/* <Stack alignItems={"center"} pl={4} pr={4}>
        <Box
          borderBottom="1.13727px solid rgba(0, 0, 0, 0.3)"
          width="100%"
        ></Box>
      </Stack> */}
      </Stack>
    </Stack>
  );
};
export default Navbar;
