import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MainContainer from "components/Entity/MainContainer";
import api from "../../api/main";
import CebForm from "components/Form/CebForm";

import useApi from "hooks/useApi";
import { get, useForm } from "react-hook-form";
import CebTextField from "components/Form/CebTextField";
import CebSelectField from "components/Form/CebSelectField";
import { Tree, TreeNode } from "react-organizational-chart";
import StyledNode from "../../components/TreeNode/TreeNode";
import CustomAddButton from "components/UI/CustomAddButton";
import useYupResolver from "hooks/useYupResolver";
import { useToasts } from "react-toast-notifications";
import Modal from "components/UI/Modal";
import { DeleteOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const TeamSetup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewModeState, setViewMode] = useState("cards");
  const [teamHead, setTeamHead] = useState();
  const [teamMembers, setTeamMembers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const { addToast } = useToasts();

  //api
  const getTeamsList = useApi(api.getTeamsList);
  const getTeam = useApi(api.getTeam);
  const createTeam = useApi(api.createTeam);
  const updateTeam = useApi(api.updateTeam);
  const deleteTeam = useApi(api.deleteTeam);

  //url params
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (urlParams.get("id")) {
      getTeam.request(urlParams.get("id"));
      setViewMode("detail");
    } else if (urlParams.get("new")) {
      setViewMode("form");
    } else {
      setViewMode("cards");
      // reset team data
      setTeamHead({});
    }
  }, [urlParams.get("id"), urlParams.get("new")]);

  useEffect(() => {
    if (viewModeState === "cards") getTeamsList.request(pageNumber);
  }, [viewModeState, pageNumber]);

  useEffect(() => {
    setTeamHead(
      getTeam.data?.members?.find((item) => item.is_head)?.practitioner
    );
    setTeamMembers(
      getTeam.data?.members
        ?.filter((item) => !item.is_head)
        ?.map((item) => item.practitioner)
    );
  }, [getTeam.data]);

  const cardItems = [
    { header: "Name", keyName: "data.name" },
    {
      header: "Team Head",
      keyName:
        "data.members?.filter(member => (member?.is_head))[0]?.practitioner?.full_name",
    },
    {
      header: "Remove",
      type: "element",
      element: (
        <IconButton color="secondary">
          <DeleteOutline />
        </IconButton>
      ),
      onClickHandler: (card) => deleteTeamHandler(card.id),
    },
  ];

  const handleViewButton = (card) => {
    navigate(`?id=${card.id}`);
  };

  const createTeamHandler = async (data) => {
    const res = await createTeam.requestWithToast(
      t("Team has been created successfully"),
      data
    );
    if (res.ok) {
      navigate(-1);
      setPageNumber(1);
    } else {
      for (const key in res.data?.errors) {
        addToast(res.data.errors[key]?.[0], {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  const updateTeamHandler = async (data) => {
    const res = await updateTeam.requestWithToast(
      t("Updated successfully"),
      urlParams.get("id"),
      data
    );
    if (res.ok) {
      //reset team data
      setTeamHead({});
      getTeam.request(urlParams.get("id"));
    }
  };

  const deleteTeamHandler = (cardId) => {
    //delete from cards by cardId and delete from detail view by id from url params
    const teamId = cardId || urlParams.get("id");
    if (window.confirm("Are you sure you want to delete this team")) {
      deleteTeam
        .requestWithToast(t("Deleted successfully"), teamId)
        .then((res) => {
          if (res.ok) {
            addToast(t("Deleted successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            if (cardId) {
              getTeamsList.request(pageNumber);
            } else {
              navigate(-1);
            }
          }
        });
    }
  };

  return (
    <>
      <MainContainer title={"Teams"}>
        <Stack gap={1}>
          {viewModeState === "cards" && (
            <>
              <Stack alignItems={"flex-start"} mb={1}>
                <Button
                  style={{
                    width: 145,
                    height: 46,
                    borderRadius: 10,
                    textTransform: "none",
                    fontWeight: 500,
                    marginRight: 16,
                  }}
                  onClick={() => navigate(`?new=true`)}
                  variant="outlined"
                >
                  {t("Add New")}
                </Button>
              </Stack>
              <CebForm
                cardItems={cardItems}
                cardsData={getTeamsList.data}
                viewModeState={[viewModeState, setViewMode]}
                handleViewButton={handleViewButton}
                loading={
                  getTeamsList.loading || createTeam.loading || getTeam.loading
                }
                totalCount={getTeamsList.count}
                paginationState={[pageNumber, setPageNumber]}
              />
            </>
          )}
          {viewModeState === "detail" && teamHead?.id && (
            <TeamForm
              name={getTeam.data?.name}
              head={teamHead}
              members={teamMembers}
              onSubmitHandler={updateTeamHandler}
              backHandler={() => navigate(-1)}
              deleteHandler={deleteTeamHandler}
            />
          )}
          {viewModeState === "form" && (
            <TeamForm
              onSubmitHandler={createTeamHandler}
              backHandler={() => navigate(-1)}
            />
          )}
        </Stack>
      </MainContainer>
    </>
  );
};

export default TeamSetup;

const TeamForm = ({
  name = "",
  head = {},
  members = [],
  onSubmitHandler,
  backHandler,
  deleteHandler,
}) => {
  const { t } = useTranslation();
  const { register, control, setValue, watch, handleSubmit, reset } = useForm();
  const [headList, setHeadList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [teamName, setTeamName] = useState(name);
  const [teamHead, setTeamHead] = useState(head);
  const [teamMembers, setTeamMembers] = useState(members);
  const [openModal, setOpenModal] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const handleOpenModal = () => setOpenModal((old) => !old);

  const { addToast } = useToasts();

  //api
  const getTeamMemberList = useApi(api.getTeamMemberList);
  const getPractitionerTypes = useApi(api.getPractitionerTypes);
  const getTeamHeadList = useApi(api.getTeamHeadList);

  useEffect(() => {
    getTeamMemberList.request();
    getPractitionerTypes.request();
    getTeamHeadList.request();
  }, []);

  useEffect(() => {
    if (getTeamMemberList.data?.length > 0) {
      setMemberList(getTeamMemberList.data);
    }
  }, [getTeamMemberList.data]);

  useEffect(() => {
    if (getTeamHeadList.data?.length > 0) {
      if (head?.id) setHeadList([head, ...getTeamHeadList.data]);
      else setHeadList(getTeamHeadList.data);
    }
  }, [getTeamHeadList.data]);

  const onSubmit = async (data) => {
    if (!data.team_name) {
      addToast("Please enter a valid team name", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (!watch("team_head")?.id) {
      addToast("Please enter a valid team head", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    const obj = {
      members: [
        { practitioner: teamHead.id, is_head: true },
        ...teamMembers.map((member) => ({
          practitioner: member.id,
          is_head: false,
        })),
      ],
    };
    if (data.team_name !== name) {
      obj.name = data.team_name;
    }
    const res = await onSubmitHandler(obj);
  };

  useEffect(() => {
    const doctorId = watch("team_head")?.id;
    const newHead = headList.find((item) => item.id === doctorId);
    if (newHead) {
      setTeamHead(newHead);
      setValue("member", "");
    }
  }, [watch("team_head")?.id, headList]);

  useEffect(() => {
    if (watch("name")) setTeamName(watch("name"));
  }, [watch("team_name")]);

  const handleAddMember = () => {
    if (!watch("team_head")?.id) {
      addToast("Please select the team head", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (watch("member")) {
      const member = memberList.find((item) => item.id === watch("member")?.id);
      setTeamMembers((old) => [...old, member]);
      setValue("member", "");
    }
  };

  const onClickNodeHandler = (id) => {
    if (id === teamHead.id) {
      setSelectedMember({ ...teamHead, is_head: true });
    } else {
      const member = teamMembers.find((item) => item.id === id);
      setSelectedMember({ ...member, is_head: false });
    }
    handleOpenModal();
  };
  return (
    <Stack gap={4} padding={2}>
      <Modal open={openModal} handleOpen={handleOpenModal} minHeight={200}>
        <ViewMemberModal
          member={selectedMember}
          closeHandler={handleOpenModal}
          removeHandler={() =>
            setTeamMembers((old) =>
              old.filter((item) => item.id !== selectedMember?.id)
            )
          }
        />
      </Modal>
      <Stack direction={"row"} gap={8} alignItems="center">
        <Stack direction={"row"} alignItems="center">
          <Typography width={180}>{t("Team Name")}</Typography>
          <Stack gap={1}>
            <CebTextField
              name="team_name"
              register={register}
              placeholder={t("Team Name")}
              width={300}
              defaultValue={name}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Typography width={180}>{t("Team Head")}</Typography>
          <CebSelectField
            control={control}
            name="team_head"
            placeholder="Team Head"
            height={40}
            items={headList
              .map((item) => ({
                id: item.id,
                label: item.full_name,
              }))
              .filter(
                (item) =>
                  !teamMembers?.map((item) => item.id)?.includes(item.id)
              )}
            width={300}
            autoComplete
            defaultValue={head?.id && { id: head?.id, label: head?.full_name }}
            onInputChange={() => {}}
          />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={8}>
        <Stack direction="row" alignItems="center">
          <Typography width={180}>{t("Filter by Role")}</Typography>
          <CebSelectField
            control={control}
            name="role"
            placeholder="Filter by role "
            height={40}
            items={getPractitionerTypes.data}
            width={300}
            idName="id"
          />
        </Stack>

        <Stack direction="row" alignItems="center">
          <Typography width={180}>{t("Member")}</Typography>
          <CebSelectField
            control={control}
            name="member"
            placeholder="Team Members"
            height={40}
            items={memberList
              .filter(
                (item) =>
                  item.type?.id === watch("role") &&
                  !teamMembers?.map((item) => item.id)?.includes(item.id) &&
                  item.id !== teamHead?.id
              )
              ?.map((item) => ({ id: item.id, label: item.full_name }))}
            width={300}
            autoComplete
            onInputChange={() => {}}
          />
          <Stack mx={3}>
            <CustomAddButton onClick={handleAddMember} />
          </Stack>
        </Stack>
      </Stack>
      <hr />
      {teamHead?.id && (
        <Stack
          width="100%"
          maxWidth={window.innerWidth - 380}
          overflow={"auto"}
          pb={2}
          sx={{
            direction: "ltr",
          }}
        >
          <TeamTree
            head={teamHead}
            members={teamMembers}
            onClickNodeHandler={onClickNodeHandler}
          />
        </Stack>
      )}
      <Stack direction={"row"} justifyContent={"flex-end"} gap={2} pt={4}>
        {deleteHandler && (
          <Button
            variant="outlined"
            color="secondary"
            sx={{ textTransform: "none", width: 160, height: 50 }}
            onClick={() => deleteHandler()}
          >
            <Stack direction={"row"} alignItems={"center"}>
              {t("Delete Team")}
              <DeleteOutline />
            </Stack>
          </Button>
        )}
        <Button
          variant="outlined"
          sx={{ textTransform: "none", width: 120, height: 50 }}
          onClick={backHandler}
        >
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", width: 120, height: 50 }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("Save")}
        </Button>
      </Stack>
    </Stack>
  );
};

const TeamTree = ({ head, members, onClickNodeHandler }) => {
  const { t } = useTranslation();
  return (
    <Tree
      label={t("Team Hierarchy")}
      lineWidth={"2px"}
      lineColor={"#B3B3B3"}
      lineBorderRadius={"10px"}
    >
      <TreeNode
        label={
          <StyledNode
            name={head.full_name}
            onClick={onClickNodeHandler}
            id={head.id}
            layer={0}
            // isService={node.isService}
            type={head.specialty}
            // onChangePosition={(position) =>
            //   setNodePosition((state) => ({ ...state, [node.id]: position }))
            // }
            // dragHandler={dragHandler}
            // dragHover={dragHoverEffect[node.id]}
          />
        }
      >
        {members?.length > 0 &&
          members.map((item) => (
            <TreeNode
              key={item.id}
              label={
                <StyledNode
                  id={item.id}
                  name={item.full_name}
                  layer={1}
                  type={item.specialty}
                  onClick={onClickNodeHandler}
                />
              }
            />
          ))}
      </TreeNode>
    </Tree>
  );
};

const ViewMemberModal = ({ member, closeHandler, removeHandler }) => {
  return (
    <Stack gap={4}>
      <Stack direction="row" alignItems={"center"}>
        <Typography fontWeight={600} color="primary" width={90}>
          Name:
        </Typography>
        <Typography>{member.full_name}</Typography>
      </Stack>
      <Stack direction="row" alignItems={"center"}>
        <Typography fontWeight={600} color="primary" width={90}>
          Role:
        </Typography>
        <Typography>{member.specialty}</Typography>
      </Stack>
      <Stack direction="row" justifyContent={"center"} gap={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ textTransform: "none" }}
          onClick={closeHandler}
        >
          Close
        </Button>
        {!member?.is_head && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ textTransform: "none" }}
            onClick={() => {
              removeHandler();
              closeHandler();
            }}
          >
            Remove
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
