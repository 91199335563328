export const HOME = "/home";
export const SETUP = "/setup";
export const TEAM = "/team";
export const EMPLOYEES = "/employees";
export const ENROLLMENT = "/enrollment";
export const LICENSE = "/license";
export const ENTITIES = "/entities";
export const CUSTOM_FORM = "/custom-form";
export const PERMISSIONS = "/permissions";
export const EXTERNAL_CLINICS = "/external-clinics";
export const CLINIC_MANAGEMENT = "/clinic-management";
export const PURCHASE_ORDERS = "/purchase-orders";
export const PROFILE = "/profile";
export const ENTITY_TYPES = "/entity-types";

export const WEEK_DAYS = [
  {
    id: 0,
    label: "Monday",
  },
  { id: 1, label: "Tuesday" },
  { id: 2, label: "Wednesday" },
  { id: 3, label: "Thursday" },
  { id: 4, label: "Friday" },
  { id: 5, label: "Saturday" },
  { id: 6, label: "Sunday" },
];

export const HOURS = [
  "00:00:00",
  "01:00:00",
  "02:00:00",
  "03:00:00",
  "04:00:00",
  "05:00:00",
  "06:00:00",
  "07:00:00",
  "08:00:00",
  "09:00:00",
  "10:00:00",
  "11:00:00",
  "12:00:00",
  "13:00:00",
  "14:00:00",
  "15:00:00",
  "16:00:00",
  "17:00:00",
  "18:00:00",
  "19:00:00",
  "20:00:00",
  "21:00:00",
  "22:00:00",
  "23:00:00",
];

export const defaultPageSize = 20;
