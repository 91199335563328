import * as React from "react";

const SvgComponent = (props) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.84332 2.16669H5.78499C3.41249 2.16669 2.16666 3.41252 2.16666 5.77419V7.83252C2.16666 10.1942 3.41249 11.44 5.77416 11.44H7.83249C10.1942 11.44 11.44 10.1942 11.44 7.83252V5.77419C11.4508 3.41252 10.205 2.16669 7.84332 2.16669Z" fill="white"/>
    <path opacity="0.4" d="M20.2258 2.16669H18.1675C15.8058 2.16669 14.56 3.41252 14.56 5.77419V7.83252C14.56 10.1942 15.8058 11.44 18.1675 11.44H20.2258C22.5875 11.44 23.8333 10.1942 23.8333 7.83252V5.77419C23.8333 3.41252 22.5875 2.16669 20.2258 2.16669Z" fill="white"/>
    <path d="M20.2258 14.5492H18.1675C15.8058 14.5492 14.56 15.795 14.56 18.1567V20.215C14.56 22.5767 15.8058 23.8225 18.1675 23.8225H20.2258C22.5875 23.8225 23.8333 22.5767 23.8333 20.215V18.1567C23.8333 15.795 22.5875 14.5492 20.2258 14.5492Z" fill="white"/>
    <path opacity="0.4" d="M7.84332 14.5492H5.78499C3.41249 14.5492 2.16666 15.795 2.16666 18.1567V20.215C2.16666 22.5875 3.41249 23.8334 5.77416 23.8334H7.83249C10.1942 23.8334 11.44 22.5875 11.44 20.2259V18.1675C11.4508 15.795 10.205 14.5492 7.84332 14.5492Z" fill="white"/>
    </svg>
    
);

export default SvgComponent;
