import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import theme from "styles/MUITheme";

const layerColor = {
  1: "#0050A0",
  2: "#008BFF",
  3: "#996600",
  4: "#003333",
  5: "#660066",
  6: "#FF6600",
  7: "#600000",
  8: "#663399",
};

const StyledNode = ({
  name,
  type = "department",
  onClick,
  isService,
  id,
  layer,
  // dragHandler,
  onChangePosition,
  dragHover,
}) => {
  const ref = useRef();

  // const [initialPosition, setInitialPosition] = useState();
  // const [isDragging, setIsDragging] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => onChangePosition(getPosition()));
  // }, []);

  const getPosition = () => {
    const { x, y } = ref.current?.getBoundingClientRect();
    return { x, y };
  };

  return (
    // <Draggable
    //   onDrag={() => {
    //     dragHandler(getPosition(), "drag", id);
    //     setIsDragging(true);
    //   }}
    //   onStop={() => {
    //     dragHandler(getPosition(), "stop", id);
    //     if (!isDragging) onClick(id, name);
    //   }}
    //   disabled={id === "root"}
    // >
    <div
      style={{
        padding: "10px",
        opacity: dragHover && 0.4,
        borderRadius: "10px",
        backgroundColor: isService
          ? "green"
          : layer === 0
          ? theme.palette.primary.main
          : // : layerColor[layer],
            `rgba(0, 52, 104, ${1 - layer / 10})`,
        color: "#fff",
        // width: "130px",
        width: "fit-content",
        height: "70px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: id !== "root" && "pointer",
      }}
      {...(id !== "root" && { onClick: () => onClick(id, name) })}
      ref={ref}
    >
      <p style={{ margin: 0, fontSize: "16px" }}>
        <b>
          {/* {name.length > 14 && layer !== 0 ? `${name.substr(0, 12)}..` : name} */}
          {name}
        </b>
      </p>
      {layer !== 0 && <p style={{ margin: 0, fontSize: "12px" }}>{type}</p>}
    </div>
    // </Draggable>
  );
};

export default StyledNode;
