import { Button, IconButton, Stack, Typography } from "@mui/material";
import main from "api/main";
import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";
import { WEEK_DAYS } from "config/Constants";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import Modal from "components/UI/Modal";
import moment from "moment";
import formatTime from "helpers/formatTime";
import ScheduleCalendar from "components/ScheduleCalendar";
import { Delete, DeleteOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const SchedulePage = ({ clinicId, doctorId }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  //api
  const getDoctor = useApi(main.getPractitioner);
  const addDoctorAvailability = useApi(main.addDoctorAvailability);
  const getDoctorAvailabilityList = useApi(main.getDoctorAvailabilityList);
  const getAppointmentList = useApi(main.getAppointmentList);
  const cancelAppointment = useApi(main.cancelAppintment);
  const deleteAvailability = useApi(main.deleteAvailability);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  useEffect(() => {
    getDoctor.request(doctorId);
    getDoctorAvailabilityList.request(clinicId, doctorId);
  }, [doctorId]);

  useEffect(() => {
    if (startDate && endDate && doctorId)
      getAppointmentList.request(clinicId, doctorId, startDate, endDate);
  }, [startDate, endDate, doctorId]);

  const onSubmitNewAvailability = async (data) => {
    const obj = {
      days: data.days,
      start_time: moment(data.start_time).format("HH:mm"),
      end_time: moment(data.end_time).format("HH:mm"),
    };
    const res = await addDoctorAvailability.requestWithToast(
      t("Added successfully"),
      clinicId,
      doctorId,
      obj
    );
    if (res.ok) {
      handleOpenModal();
      getDoctorAvailabilityList.request(clinicId, doctorId);
    }
  };

  const cancelAppointmentHandler = async (shiftId, appointmentId) => {
    const res = await cancelAppointment.requestWithToast(
      t("Cancelled Successfully"),
      doctorId,
      shiftId,
      appointmentId
    );
    if (res.ok) {
      getAppointmentList.request(clinicId, doctorId, startDate, endDate);
    }
  };

  const deleteAvailabilityHandler = async (availabilityId) => {
    const response = window.confirm(
      "Are you sure to permanent delete this shift ?"
    );
    if (response) {
      const res = await deleteAvailability.requestWithToast(
        t("Deleted successfully"),
        doctorId,
        clinicId,
        availabilityId
      );
      if (res.ok) {
        getDoctorAvailabilityList.request(clinicId, doctorId);
      }
    }
  };

  return (
    <MainContainer title="Times & Schedules">
      <Modal open={openModal} handleOpen={handleOpenModal}>
        <NewAvailaibilityForm
          handleOpenModal={handleOpenModal}
          onSubmit={onSubmitNewAvailability}
        />
      </Modal>
      <Stack gap={2}>
        <Stack width={"100%"} p={2} sx={{ backgroundColor: "#F9FAFC" }} gap={2}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Stack gap={1}>
              <Typography fontWeight={600} fontSize={25}>
                {t("Dr.")} {getDoctor.data.full_name}
              </Typography>
              {getDoctorAvailabilityList.data.length > 0 && (
                <AvailabilityList
                  list={getDoctorAvailabilityList.data}
                  deleteHandler={deleteAvailabilityHandler}
                />
              )}
            </Stack>
            <Stack gap={2} alignItems="flex-end">
              <Typography color="primary" fontWeight={600} fontSize={20}>
                {t("Manage Time & Availability")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                onClick={handleOpenModal}
              >
                {t("Add New")}
              </Button>
            </Stack>
          </Stack>
          <ScheduleCalendar
            appointmentList={getAppointmentList.data}
            onChangeDateRange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
            cancelHandler={cancelAppointmentHandler}
          />
        </Stack>
      </Stack>
    </MainContainer>
  );
};

export default SchedulePage;

const AvailabilityList = ({ list, deleteHandler }) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography>{t("Availability")}</Typography>
      {list.map((item) => (
        <Stack key={item.id} gap={1} direction="row" alignItems={"center"}>
          <Stack width={250} gap={1} direction="row" alignItems={"center"}>
            <Typography fontSize={13}>{item.day}</Typography>
            <Typography fontSize={13}>{`(${formatTime(
              item.start_time
            )} - ${formatTime(item.end_time)})`}</Typography>
          </Stack>

          <IconButton
            sx={{ padding: 0 }}
            edge="end"
            aria-label="delete"
            onClick={() => deleteHandler(item.id)}
          >
            <Delete />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
};

const NewAvailaibilityForm = ({ handleOpenModal, onSubmit }) => {
  const [viewMode, setViewMode] = useState("form");
  const { t } = useTranslation();

  const fields = [
    {
      name: "days",
      label: "Available Days",
      type: "select",
      items: WEEK_DAYS.map((item) => ({ id: item.id, label: t(item.label) })),
      multiple: true,
      validation: { required: true },
    },
    {
      name: "start_time",
      label: "Start Time",
      type: "time",
      validation: { required: true, maxTimeField: "end_time" },
    },
    {
      name: "end_time",
      label: "End Time",
      type: "time",
      validation: { required: true, minTimeField: "start_time" },
    },
  ];

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      modalForm
      modalTitle={"Time & Availability"}
      fields={fields}
      cancelHandler={handleOpenModal}
      formWidth={700}
      onSubmit={onSubmit}
    />
  );
};
