import React, { useState } from "react";
import { Stack, Box, Typography, Collapse } from "@mui/material";
import { NavLink } from "react-router-dom";

//Icons
import Home from "assets/icons/Home";
import Employees from "assets/icons/Employees";
import Setup from "assets/icons/Setup";
import Enrollment from "assets/icons/Enrollment";
import Category from "assets/icons/Category";
import License from "assets/icons/License";
import ClinicIcon from "assets/icons/Clinic";
import FormIcon from "assets/icons/From";
import Permissions from "assets/icons/Permissions";
import TypesIcon from "assets/icons/Types";
import { useTranslation } from "react-i18next";

const sidebarItems = (superadmin, hospitalProfile) =>
  [
    { name: "Home", icon: (props) => <Home {...props} />, to: "home" },
    !superadmin &&
      hospitalProfile && {
        name: "Setup",
        icon: <Category />,
        directory: true,
        items: [
          {
            name: "Entity",
            icon: (props) => <Setup {...props} />,
            to: "setup",
          },
          {
            name: "Teams",
            icon: (props) => <Setup {...props} />,
            to: "team",
          },
        ],
      },
    {
      name: superadmin ? "Users" : "Employees",
      icon: (props) => <Employees {...props} />,
      to: "employees",
    },
    {
      name: "License",
      icon: (props) => <License {...props} />,
      to: "license",
    },

    !superadmin && {
      name: "Enrollment",
      icon: (props) => <Enrollment {...props} />,
      to: "enrollment",
    },
    superadmin && {
      name: "Institutions",
      icon: (props) => <Setup {...props} />,
      to: "entities",
    },
    {
      name: "Permissions",
      icon: (props) => <Permissions {...props} />,
      to: "permissions",
    },
    !superadmin && {
      name: hospitalProfile ? "External Clinics" : "Clinic Management",
      icon: (props) => <ClinicIcon {...props} />,
      to: hospitalProfile ? "external-clinics" : "clinic-management",
    },
    superadmin && {
      name: "Forms",
      icon: (props) => <FormIcon {...props} />,
      to: "custom-form",
    },
    superadmin && {
      name: "Entity Types",
      icon: (props) => <TypesIcon {...props} />,
      to: "entity-types",
    },
  ].filter((item) => item);

const SidebarItem = ({ name, icon, to }) => {
  const { t } = useTranslation();
  return (
    <NavLink to={to} style={{ textDecoration: "none" }}>
      {({ isActive }) => (
        <Stack alignItems={"center"} gap={0.8}>
          {icon({ isActive })}

          <Typography
            color={"#fff"}
            variant="body2"
            sx={{ textAlign: "center" }}
          >
            {t(name)}
          </Typography>
        </Stack>
      )}
    </NavLink>
  );
};

const SidebarDirectory = ({ name, icon, items }) => {
  const { t, i18n } = useTranslation();
  const [showItems, setShowItems] = useState(false);
  return (
    <Stack
      sx={{
        backgroundColor: showItems && "rgba(255,255,255,0.2)",
        padding: 2,
        borderRadius: "5px",
      }}
      gap={showItems && 2}
    >
      <Stack
        alignItems={"center"}
        gap={1}
        onClick={() => setShowItems((s) => !s)}
        sx={{ cursor: "pointer" }}
      >
        {icon}
        <Typography color={"#fff"} variant="body2">
          {name}
        </Typography>
      </Stack>
      <Collapse in={showItems}>
        <Stack gap={2}>
          {items.map((item) => (
            <SidebarItem
              key={item.name}
              name={t(item.name)}
              icon={item.icon}
              to={item.to}
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const Sidebar = ({ superadmin = false, hospitalProfile = false }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        pt: "20px",
        position: "fixed",
        height: "100vh",
        width: "162px",
        background: "#003468",
      }}
    >
      <Stack
        alignItems={"center"}
        alignContent={"space-between"}
        gap={2.5}
        height="100%"
        overflow="auto"
        paddingBottom={2}
      >
        {sidebarItems(superadmin, hospitalProfile).map((item) => (
          <Stack key={item.name}>
            {!item.directory && (
              <SidebarItem name={t(item.name)} icon={item.icon} to={item.to} />
            )}
            {item.directory && (
              <SidebarDirectory
                name={t(item.name)}
                icon={item.icon}
                items={item.items}
              />
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default Sidebar;
