import { Button, IconButton, Stack, Typography } from "@mui/material";
import CebForm from "./Form/CebForm";
import React, { useEffect, useState } from "react";
import Modal from "./UI/Modal";
import { useToasts } from "react-toast-notifications";
import useApi from "hooks/useApi";
import main from "api/main";
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  DeleteOutline,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import useYupResolver from "hooks/useYupResolver";
import ImagesGallery from "./ImagesGallery";
import getBase64 from "helpers/getBase64";
import moment from "moment";
import { t } from "i18next";

const getCebFormField = (field, deleteField, reorderHandler) => {
  const name = field.id ? `${field.id}` : field.label;
  if (field.options.condition) {
  }
  const newField = {
    ...field,
    name,
    validation: field.type !== "date" ? field.options : {},
  };
  switch (field.type) {
    case "text":
      newField.multiline = field.options?.multiline ? true : false;
      break;
    case "date":
      if (field.options.required) newField.validation.required = true;
      if (field.options.minDate)
        newField.validation.minDate = new Date(field.options.minDate);

      if (field.options.maxDate)
        newField.validation.maxDate = new Date(field.options.maxDate);

      if (field.options.disableFuture) newField.validation.disableFuture = true;
      if (field.options.disablePast) newField.validation.disablePast = true;
      break;
    case "number":
      newField.type = "decimal";
      break;
    case "file":
      newField.acceptFiles = field.options?.acceptFiles;
      break;
    case "select":
      newField.items = field.options?.items;
      newField.multiple = field.options?.multiple;
    default:
      break;
  }

  // return newField;
  return {
    index: newField.fieldcustomorder_set?.index,
    twoFieldsInRow: true,
    fields: [
      { ...newField, xs: 8.5 },
      {
        type: "element",
        element: (
          <Stack
            direction={"row"}
            alignItems="center"
            gap={0.5}
            justifyContent="flex-end"
          >
            <StatusLabel status={field.status} />
            <IconButton onClick={() => reorderHandler(field.id, "moveUpField")}>
              <ArrowUpward />
            </IconButton>
            <IconButton
              onClick={() => reorderHandler(field.id, "moveDownField")}
            >
              <ArrowDownward />
            </IconButton>
            <IconButton onClick={() => deleteField(field.id)}>
              <DeleteOutline color="secondary" />
            </IconButton>
          </Stack>
        ),
        xs: 3.5,
      },
    ],
  };
};

const fieldsMapper = (
  tabFields,
  deleteField,
  reorderHandler,
  showConditionalFieldsIds
) => {
  let viewFields = [];
  for (const field of tabFields.field_set) {
    if (!field.options.condition || showConditionalFieldsIds.includes(field.id))
      viewFields.push(getCebFormField(field, deleteField, reorderHandler));
  }
  for (const group of tabFields.groups) {
    const groupFieldsArr = [];
    for (const field of group.field_set) {
      if (
        !field.options.condition ||
        showConditionalFieldsIds.includes(field.id)
      )
        groupFieldsArr.push(
          getCebFormField(field, deleteField, reorderHandler)
        );
    }

    if (groupFieldsArr.length > 0) {
      const newGroupFields = {
        index: group.fieldcustomorder_set?.index || 1,
        relatedFields: true,
        fieldsTitle: group.group_label,
        actionBar: (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={0.5}
          >
            <IconButton onClick={() => reorderHandler(group.id, "moveUpGroup")}>
              <ArrowUpward />
            </IconButton>
            <IconButton
              onClick={() => reorderHandler(group.id, "moveDownGroup")}
            >
              <ArrowDownward />
            </IconButton>
          </Stack>
        ),
        displayCondition: true,
        fields: groupFieldsArr.sort((a, b) => a.index - b.index),
        // group.imagepdf_set?.length > 0
        //   ? [
        //       {
        //         type: "element",
        //         element: (
        //           <ImagesGallery
        //             images={group.imagepdf_set.map((item) => item.image)}
        //           />
        //         ),
        //       },
        //       ...groupFieldsArr,
        //     ]
      };
      viewFields.push(newGroupFields);
    }
  }
  for (const nestedForm of tabFields.nested_fields) {
    const nestedFormFields = [];
    for (const index in nestedForm.field_set) {
      const field = nestedForm.field_set[index];
      if (
        !field.options.condition ||
        showConditionalFieldsIds.includes(field.id)
      )
        nestedFormFields.push(
          getCebFormField(
            index === 0
              ? { ...field, options: { ...field.options, required: true } }
              : field,
            deleteField,
            reorderHandler
          )
        );
    }
    const newNestedForm = {
      index: nestedForm.fieldcustomorder_set?.index || 1,
      title: nestedForm.nestedfield_label,
      actionBar: (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={0.5}
        >
          <IconButton
            onClick={() => reorderHandler(nestedForm.id, "moveUpNestedForm")}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            onClick={() => reorderHandler(nestedForm.id, "moveDownNestedForm")}
          >
            <ArrowDownward />
          </IconButton>
        </Stack>
      ),
      name: `${nestedForm.id}`,
      fieldType: "nested",
      nestedId: nestedForm.id,
      primaryListText: {
        key: nestedFormFields.sort((a, b) => a.index - b.index)[0]?.fields?.[0]
          ?.label,
        value: `"data"`,
      },
      fields: nestedFormFields.sort((a, b) => a.index - b.index),
    };
    viewFields.push(newNestedForm);
  }

  return viewFields.sort((a, b) => a.index - b.index);
};

const conditionalArr = (condition, arr) => {
  return condition ? arr : [null];
};

const fieldsToWatch = {
  name: [
    "label",
    "type",
    "date_range",
    "multiple_select",
    "length_validation",
    "linkedTo",
    "conditional",
    "relatedTo",
  ],
};

const CustomForm = ({ formId, tabId, refetchStatus }) => {
  const [viewMode, setViewMode] = useState("form");
  const [watchedFields, setWatchedFields] = useState([]);
  const [initialValues, setInintialValues] = useState([]);
  const [viewGroupFields, setViewGroupFields] = useState(false);
  const [groupFieldsType, setGroupFieldsType] = useState();
  const [nestedId, setNestedId] = useState(1);
  const [referenceImages, setReferenceImages] = useState([]);

  const { addToast } = useToasts();

  //BE Integration
  const [fields, setFields] = useState([]);
  const [groups, setGroups] = useState([]);
  const [nestedForm, setNestedForm] = useState([]);

  //reference images
  const { register, control, watch, setValue, trigger } = useForm(
    useYupResolver([
      {
        name: "images",
        type: "file",
        multiple: true,
        acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
      },
    ])
  );
  useEffect(() => {
    setReferenceImages(watch("images"));
  }, [watch("images")]);

  //api
  const createFields = useApi(main.createFields);
  const createField = useApi(main.createField);
  const createTabGroup = useApi(main.createTabGroup);
  const createTabNestedForm = useApi(main.createTabNestedForm);
  const getTabGroups = useApi(main.getTabGroups);
  const getTabNestedForms = useApi(main.getTabNestedForms);
  const getTabFields = useApi(main.getTabFields);
  const deleteField = useApi(main.deleteField);
  const moveUpField = useApi(main.moveUpField);
  const moveDownField = useApi(main.moveDownField);
  const moveUpNestedForm = useApi(main.moveUpNestedForm);
  const moveDownNestedForm = useApi(main.moveDownNestedForm);
  const moveUpGroup = useApi(main.moveUpGroup);
  const moveDownGroup = useApi(main.moveDownGroup);
  const getTabSingleFields = useApi(main.getTabSingleFields);

  useEffect(() => {
    getTabGroups.request(formId, tabId);
  }, [createTabGroup.data]);
  useEffect(() => {
    getTabNestedForms.request(formId, tabId);
  }, [createTabNestedForm.data]);
  useEffect(() => {
    getTabFields.request(formId, tabId);
    getTabSingleFields.request(formId, tabId);
  }, [
    createField.data,
    deleteField.data,
    formId,
    tabId,
    moveUpField.data,
    moveDownField.data,
    moveUpGroup.data,
    moveDownGroup.data,
    moveUpNestedForm.data,
    moveDownNestedForm.data,
  ]);

  // useEffect(() => {
  //   if (getTabSingleFields.data?.length > 0) {
  //     console.log(getTabSingleFields.data);
  //     setTabSelectFields(
  //       getTabSingleFields.data.filter((item) => item.type === "select")
  //     );
  //   }
  // }, [getTabSingleFields.data]);

  //Text
  const textFields = [
    { name: "multiline", label: "Multiline", type: "boolean" },
    {
      name: "length_validation",
      label: "Validation",
      type: "select",
      items: ["Fixed Length", "Range", "None"],
      isEnum: true,
    },
    watchedFields[4] === "Fixed Length" && {
      name: "length",
      label: "Length",
      type: "number",
    },
    watchedFields[4] === "Range" && {
      twoFieldsInRow: true,
      fields: [
        { name: "min_length", label: "Min Length", type: "number" },
        { name: "max_length", label: "Max Length", type: "number" },
      ],
    },
  ].filter((item) => item);
  useEffect(() => {
    if (watchedFields[4] === "Fixed Length")
      setInintialValues({
        min_length: null,
        max_length: null,
      });
    else if (watchedFields[4] === "Range")
      setInintialValues({
        length: null,
      });
    else if (watchedFields[4] === "None")
      setInintialValues({
        min_length: null,
        max_length: null,
        length: null,
      });
  }, [watchedFields[4]]);

  //Numbers
  const numberFields = [
    { name: "integer", label: "Integer", type: "boolean" },
    {
      name: "sign",
      label: "Sign",
      type: "select",
      items: ["Positive", "Negative", "Both"],
      isEnum: true,
    },
    // {
    //   name: "length",
    //   label: "Number of digits",
    //   type: "number",
    //   validation: { positive: true },
    // },
    {
      twoFieldsInRow: true,
      fields: [
        {
          name: "min_value",
          label: "Min Num",
          type: "decimal",
          width: "200px",
        },
        {
          name: "max_value",
          label: "Max Num",
          type: "decimal",
          width: "200px",
        },
      ],
    },
  ];

  //Date
  const dateFields = [
    {
      name: "date_range",
      label: "Validation",
      type: "select",
      items: [
        "Past - Future",
        "Min - Max",
        // "Related to another field",
        "Any Date",
      ],
      isEnum: true,
    },
    watchedFields[2] === "Min - Max" && {
      name: "min_date",
      label: "Min date",
      type: "date",
    },
    watchedFields[2] === "Min - Max" && {
      name: "max_date",
      label: "Max date",
      type: "date",
      validation: { minDateField: "min_date" },
    },
    // watchedFields[2] === "Related to another field" && {
    //   name: "min_date_field",
    //   label: "After",
    //   type: "select",
    //   items: fields
    //     .filter((item) => item.type === "date")
    //     .map((item) => ({ id: item.name || item.label, label: item.label })),
    // },
    // watchedFields[2] === "Related to another field" && {
    //   name: "max_date_field",
    //   label: "Before",
    //   type: "select",
    //   items: fields
    //     .filter((item) => item.type === "date")
    //     .map((item) => ({ id: item.name || item.label, label: item.label })),
    // },
    watchedFields[2] === "Past - Future" && {
      name: "past_future",
      label: "Past/ Future",
      type: "select",
      items: ["Past", "Future"],
      isEnum: true,
    },
  ].filter((item) => item);
  useEffect(() => {
    if (watchedFields[2] === "Past - Future")
      setInintialValues({
        min_date: null,
        max_date: null,
        min_date_field: null,
        max_date_field: null,
      });
    else if (watchedFields[2] === "Related to another field")
      setInintialValues({
        min_date: null,
        max_date: null,
        past_future: null,
      });
    else if (watchedFields[2] === "Min - Max")
      setInintialValues({
        min_date_field: null,
        max_date_field: null,
        past_future: null,
      });
    else if (watchedFields[2] === "Any Date")
      setInintialValues({
        min_date: null,
        max_date: null,
        min_date_field: null,
        max_date_field: null,
        past_future: null,
      });
  }, [watchedFields[2]]);

  //File
  const fileFields = [
    // {
    //   name: "multiple_files",
    //   label: "Multiple",
    //   type: "boolean",
    // },
    {
      name: "accept_files",
      label: "File Types",
      type: "select",
      items: ["Images", "PDF"],
      multiple: true,
      isEnum: true,
      validation: { required: true },
    },
  ];

  //Select
  const selectFields = [
    {
      name: "multiple_select",
      label: "Multiple",
      type: "boolean",
    },
    {
      fieldType: "nested",
      nestedId: 1,
      title: "Options",
      name: "options",
      primaryListText: {
        key: "Name",
        value: "field.label",
      },
      fields: [
        {
          name: "label",
          label: "Name",
          validation: { required: true },
        },
        watchedFields[3]?.[0] && {
          name: "only_one",
          label: "Chosen only",
          type: "boolean",
        },
      ].filter((item) => item),
    },
  ];

  //Fields
  const formFields = [
    {
      name: "type",
      label: "Type",
      type: "select",
      items: [
        { id: "text", label: "Text" },
        { id: "number", label: "Number" },
        { id: "date", label: "Date" },
        { id: "file", label: "File" },
        { id: "select", label: "Dropdown Menu" },
      ],
      validation: { required: true },
    },
    {
      name: "label",
      label: "Label",
      validation: { required: true },
    },
    {
      name: "required",
      label: "Required",
      type: "boolean",
    },
    {
      name: "linkedTo",
      label: "Linked to",
      type: "select",
      items: ["None", "Group", "Nested Form"],
      isEnum: true,
    },
    watchedFields?.[5] === "Group" && {
      name: "group",
      label: "Group",
      type: "select",
      items: getTabGroups.data?.map((item) => ({
        id: item.id,
        label: item.group_label,
      })),
    },
    watchedFields?.[5] === "Nested Form" && {
      name: "nested_field",
      label: "Nested Form",
      type: "select",
      items: getTabNestedForms.data?.map((item) => ({
        id: item.id,
        label: item.nestedfield_label,
      })),
    },
    {
      name: "conditional",
      label: "Conditional",
      type: "boolean",
    },
    watchedFields?.[6]?.[0] && {
      label: "Related to",
      type: "select",
      name: "relatedTo",
      items: getTabSingleFields.data.filter((item) => item.type === "select"),
    },
    watchedFields?.[6]?.[0] &&
      watchedFields?.[7] && {
        label: "Condition",
        type: "select",
        name: "condition",
        items: getTabSingleFields.data.find(
          (item) => item.id === watchedFields?.[7]
        )?.options?.items,
      },
    ...conditionalArr(watchedFields?.[1] === "text", textFields),
    ...conditionalArr(watchedFields?.[1] === "number", numberFields),
    ...conditionalArr(watchedFields?.[1] === "date", dateFields),
    ...conditionalArr(watchedFields?.[1] === "file", fileFields),
    ...conditionalArr(watchedFields?.[1] === "select", selectFields),
  ].filter((item) => item);

  const onSubmitField = async (data) => {
    const newField = {
      type: data.type,
      label: data.label,
      options: { required: data.required?.[0] },
      group: null,
      nested_field: null,
    };
    if (data.linkedTo === "Group") {
      newField.group = data.group;
    } else if (data.linkedTo === "Nested Form") {
      newField.nested_field = data.nested_field;
    }
    console.log("ff", data);
    if (data.conditional?.[0]) {
      if (data.condition >= 0) {
        newField.options.condition = { [data.relatedTo]: data.condition };
      } else {
        addToast("please enter condition for this conditional field", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }
    }
    switch (data.type) {
      case "text":
        newField.options = {
          ...newField.options,
          length: data.length,
          min: data.min_length,
          max: data.max_length,
          multiline: data.multiline?.[0],
        };
        break;
      case "number":
        newField.options = {
          ...newField.options,
          minValue: data.min_value,
          maxValue: data.max_value,
          integer: data.integer?.[0],
          positive: data.sign === "Positive",
          negative: data.sign === "Negative",
        };
        break;
      case "date":
        newField.options = {
          ...newField.options,
          minDate: data.min_date ? new Date(data.min_date) : null,
          maxDate: data.max_date ? new Date(data.max_date) : null,
          // minDateField: data.min_date_field,
          // maxDateField: data.max_date_field,
          disablePast: data.past_future === "Future",
          disableFuture: data.past_future === "Past",
        };
        break;
      case "file":
        const imageTypes = ["image/jpg", "image/png", "image/jpeg"];
        const pdfTypes = [".pdf", "application/pdf"];
        newField.options = {
          ...newField.options,
          multiple: data.multiple_files?.[0],
          acceptFiles:
            data.accept_files.length === 2
              ? [...imageTypes, ...pdfTypes]
              : data.accept_files.includes("PDF")
              ? pdfTypes
              : imageTypes,
        };
        break;
      case "select":
        newField.options = {
          ...newField.options,
          multiple: data.multiple_select?.[0],
          items: data.options.map((item, index) => ({
            id: index + 1,
            label: item.label,
            only_one: item.only_one ? true : false,
          })),
        };
        break;
      default:
        break;
    }
    const res = await createField.requestWithToast(
      t("Added successfully"),
      formId,
      tabId,
      newField
    );
    if (res.ok) {
      setInintialValues({ label: "" });
      refetchStatus();
    }
  };

  //Group Fields
  const groupFields = [
    {
      name: "title",
      label: "Title",
    },
    groupFieldsType === "related" && {
      label: "Reference Images",
      name: "images",
      type: "file",
      multiple: true,
      acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
    },
  ].filter((item) => item);

  const handleGroupFieldsModal = () => {
    setViewGroupFields((state) => !state);
  };

  const onSubmitGroupFields = async (data) => {
    // const groupObj = { title: data.title, fields: data.fields };
    let res;
    if (groupFieldsType === "related") {
      const imageSet = [];
      for (const img of data.images) {
        const img64 = await getBase64(img);
        imageSet.push({ image: img64 });
      }
      res = await createTabGroup.requestWithToast(
        t("Added successfully"),
        formId,
        tabId,
        {
          group_label: data.title,
          imagepdf_set: imageSet,
        }
      );
    } else {
      res = await createTabNestedForm.requestWithToast(
        t("Added successfully"),
        formId,
        tabId,
        { nestedfield_label: data.title }
      );
    }
    if (res.ok) handleGroupFieldsModal();
  };

  const deleteFieldHandler = (fieldId) => {
    if (window.confirm("Are you sure you want to delete this field?"))
      deleteField.request(formId, tabId, fieldId).then((res) => {
        if (res.ok) refetchStatus();
      });
  };

  //Reordering Handlers
  // const moveUpFieldHandler = (fieldId) => {
  //   moveUpField.request(formId, tabId, fieldId);
  // };

  // const moveDownFieldHandler = (fieldId) => {
  //   moveDownField.request(formId, tabId, fieldId);
  // };

  const reorderHandler = (fieldId, type) => {
    switch (type) {
      case "moveUpField":
        moveUpField.request(formId, tabId, fieldId);
        break;
      case "moveDownField":
        moveDownField.request(formId, tabId, fieldId);
        break;
      case "moveUpNestedForm":
        moveUpNestedForm.request(formId, tabId, fieldId);
        break;
      case "moveDownNestedForm":
        moveDownNestedForm.request(formId, tabId, fieldId);
        break;
      case "moveUpGroup":
        moveUpGroup.request(formId, tabId, fieldId);
        break;
      case "moveDownGroup":
        moveDownGroup.request(formId, tabId, fieldId);
        break;
      default:
        break;
    }
  };

  // const handleRemoveImage = (groupdIndex, imageIndex) => {
  //   // setGroups(state=>state.map(item,index)=> index === groupIndex ?)
  //   const group = groups[groupdIndex];
  //   group.images = group.images.filter((item, index) => index !== imageIndex);
  //   setGroups((state) =>
  //     state.map((item, index) => (index === groupdIndex ? group : item))
  //   );
  // };
  return (
    <Stack width={"100%"}>
      {viewGroupFields && (
        <Modal open={viewGroupFields} handleOpen={handleGroupFieldsModal}>
          <CebForm
            viewModeState={[viewMode, setViewMode]}
            fields={groupFields}
            onSubmit={onSubmitGroupFields}
            modalForm
            modalTitle={
              groupFieldsType === "related"
                ? "Create New Group"
                : "Create Nested Form"
            }
            formWidth="900px"
            cancelHandler={handleGroupFieldsModal}
          />
        </Modal>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={2}
        mb={2}
      >
        <Stack maxWidth={"50%"}>
          <Stack direction="row" gap={2} mb={2}>
            <Button
              onClick={() => {
                handleGroupFieldsModal();
                setGroupFieldsType("related");
              }}
              variant="contained"
              color="primary"
            >
              Group Fields
            </Button>
            <Button
              onClick={() => {
                handleGroupFieldsModal();
                setGroupFieldsType("nested");
              }}
              color="primary"
              variant="contained"
            >
              Nested Form
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          padding: 2,
          border: "1px solid black",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <Typography
          style={{ fontSize: "22px", fontWeight: 600 }}
          color="primary"
          mb={1}
        >
          Create Field
        </Typography>
        <CebForm
          fields={formFields}
          onSubmit={onSubmitField}
          viewModeState={[viewMode, setViewMode]}
          fieldsToWatch={fieldsToWatch}
          getWatchedFields={setWatchedFields}
          initialValues={initialValues}
          saveButtonLabel="Add"
          hideCancelButton
        />
      </Stack>
      {Object.keys(getTabFields.data)?.length > 0 && (
        <Stack
          padding={2}
          sx={{
            backgroundColor: "#ececec",
            width: "100%",
            borderRadius: "10px",
          }}
          mt={2}
        >
          <Typography
            style={{ fontSize: "22px", fontWeight: 600 }}
            color="primary"
            mb={1}
          >
            View Form
          </Typography>
          <Stack
            sx={{
              padding: 2,
              pt: 3,
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
              borderRadius: "5px",
              backgroundColor: "#fff",
            }}
          >
            {/* {referenceImages.length > 0 && (
              <Stack gap={1}>
                <Typography fontWeight={500}>Reference Images</Typography>
                <ImagesGallery images={referenceImages} />
              </Stack>
            )} */}

            <ViewCustomForm
              tabFields={getTabFields.data}
              conditionalFields={getTabSingleFields.data.filter(
                (item) => item?.options?.condition
              )}
              // handleRemoveImage={handleRemoveImage}
              loading={
                createFields.loading ||
                getTabFields.loading ||
                getTabSingleFields.loading
              }
              deleteFieldHandler={deleteFieldHandler}
              reorderHandler={reorderHandler}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CustomForm;

export const ViewCustomForm = ({
  tabFields,
  conditionalFields,
  // handleRemoveImage,
  loading,
  deleteFieldHandler,
  reorderHandler,
}) => {
  const [viewMode, setViewMode] = useState("form");
  const { addToast } = useToasts();
  const [showConditionalFieldsIds, setShowConditinalFieldsIds] = useState([]);
  const [conditionFieldsNames, setConditionFieldsNames] = useState([]);
  const [conditionFieldsValues, setConditionFieldsValues] = useState([]);

  //get all fields names that have condition to hide/appear other fields
  useEffect(() => {
    const conditionsFields = conditionalFields.map(
      (item) => `${Object.keys(item.options.condition)[0]}`
    );
    setConditionFieldsNames(
      conditionsFields.filter(
        (value, index) => conditionsFields.indexOf(value) === index
      )
    );
  }, [conditionalFields]);

  //check conditional fields
  useEffect(() => {
    for (const index in conditionFieldsValues) {
      //get conditional fields related to this condition
      const currentConditionalFields = conditionalFields.filter(
        (item) => item.options.condition[conditionFieldsNames[index]] >= 0
      );
      for (const field of currentConditionalFields) {
        if (
          field.options.condition[conditionFieldsNames[index]] ===
          conditionFieldsValues[index]
        ) {
          setShowConditinalFieldsIds((old) => [...old, field.id]);
        } else {
          setShowConditinalFieldsIds((old) =>
            old.filter((item) => item !== field.id)
          );
        }
      }
    }
  }, [conditionFieldsValues.join()]);

  return (
    <CebForm
      fields={fieldsMapper(
        tabFields,
        deleteFieldHandler,
        reorderHandler,
        showConditionalFieldsIds
      )}
      viewModeState={[viewMode, setViewMode]}
      saveButtonLabel="Test Validation"
      hideCancelButton
      loading={loading}
      onSubmit={() =>
        addToast("Inputs are valid", {
          appearance: "success",
          autoDismiss: true,
        })
      }
      fieldsToWatch={{ name: conditionFieldsNames }}
      getWatchedFields={(f) => setConditionFieldsValues(f)}
    />
  );
};

export const StatusLabel = ({ status = "" }) => {
  return (
    <Stack
      width={100}
      height={32}
      backgroundColor={
        status === "published"
          ? "green"
          : status === "draft"
          ? "primary.main"
          : "rgba(139, 128, 0, 1)"
      }
      borderRadius={10}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography textAlign={"center"} color="white" fontSize={16}>
        {status.toUpperCase()[0] + status.substr(1)}
      </Typography>
    </Stack>
  );
};
