import React, { useEffect, useState } from "react";
import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";
import useApi from "hooks/useApi";
import main from "api/main";
import { Grid, ListItemIcon, Stack, Typography } from "@mui/material";
import { Delete, PlusOne } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const fieldsToWatch = {
  name: ["team-practitioner", "role", "name"],
};

const Assigning = ({ level, cancelHandler }) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState("form");
  const [watchedFields, setWatchedFields] = useState([]);
  const [initialValues, setInintialValues] = useState({});

  const getPractitionerTypes = useApi(main.getPractitionerTypes);
  const getPractitionerList = useApi(main.getPractitionerList);
  const getTeamsList = useApi(main.getTeamsList);
  const addLayerAssignment = useApi(main.addLayerAssignment);
  const getLayerAssignment = useApi(main.getLayerAssignment);
  const removeLayerAssignment = useApi(main.removeLayerAssignment);

  useEffect(() => {
    getLayerAssignment.request(level.id);
  }, []);

  //handle change of team/practitioner and role
  useEffect(() => {
    setInintialValues({ practitioners: [] });
    if (
      watchedFields[0] === "Practitioner" &&
      getPractitionerTypes.data.length === 0
    )
      getPractitionerTypes.request();
    else if (watchedFields[0] === "Team") getTeamsList.request();

    if (watchedFields[1]) getPractitionerList.request(watchedFields[1]);
  }, [watchedFields[0], watchedFields[1]]);

  const getWatchedFields = (f) => {
    setWatchedFields(f);
  };

  const fields = [
    {
      name: "team-practitioner",
      label: "Team/ Practitioner",
      type: "select",
      items: ["Team", "Practitioner"],
      isEnum: true,
    },
    watchedFields[0] === "Practitioner" && {
      name: "role",
      label: "Job Role",
      type: "select",
      items: getPractitionerTypes.data,
    },
    {
      name: "practitioners",
      label: "Name",
      type: "select",
      autoComplete: true,
      multiple: true,
      validation: { required: true },
      items:
        watchedFields[0] === "Practitioner"
          ? getPractitionerList.data.map((item) => ({
              id: item.id,
              label: item.full_name,
            }))
          : getTeamsList.data.map((item) => ({
              id: item.id,
              label: item.name,
            })),
    },
    watchedFields[0] === "Practitioner" &&
      watchedFields[1] === 1 &&
      watchedFields[2] && {
        type: "readOnly",
        label: "Specialty",
        value: getPractitionerList.data.find(
          (item) => item.id === watchedFields[2].id
        )?.specialty,
      },
  ].filter((item) => item);

  const deleteLayerAssignmentHandler = async (type, id) => {
    let obj = {};
    if (type === "team") obj = { teams: [id] };
    else obj = { practitioners: [id] };
    const res = await removeLayerAssignment.requestWithToast(
      t("Deleted successfully"),
      level.id,
      obj
    );
    if (res.ok) getLayerAssignment.request(level.id);
  };

  const assignedMembersView = () => (
    <>
      {getLayerAssignment.data.teams?.length > 0 && (
        <Stack>
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: 500,
              marginBottom: "15px",
              marginTop: "15px",
            }}
            color="primary"
          >
            Assigned Teams
          </Typography>
          {getLayerAssignment.data.teams.map((item, index) => (
            <>
              <Grid container key={item.id}>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginBottom: "10px",
                    }}
                  >
                    {index + 1}- {item.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Delete
                    style={{ cursor: "pointer", color: "grey" }}
                    onClick={() =>
                      deleteLayerAssignmentHandler("team", item.id)
                    }
                  />
                </Grid>
              </Grid>
              {item.members.map((member, i) => (
                <Grid key={member.practitioner?.id} container>
                  <Grid item xs={3}>
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        marginBottom: "10px",
                      }}
                    >
                      &nbsp; &nbsp; &nbsp; {member.practitioner?.full_name}
                    </Typography>
                  </Grid>
                  <Grid item>{member.practitioner?.specialty}</Grid>
                </Grid>
              ))}
            </>
          ))}
        </Stack>
      )}
      {getLayerAssignment.data.practitioners?.length > 0 && (
        <Stack>
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: 500,
              marginBottom: "15px",
              marginTop: "15px",
            }}
            color="primary"
          >
            Assigned Practitioners
          </Typography>
          {getLayerAssignment.data.practitioners.map((item, index) => (
            <Grid container key={item.id}>
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 400,
                    marginBottom: "10px",
                  }}
                >
                  {index + 1}- {item.full_name}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 400,
                    marginBottom: "10px",
                  }}
                >
                  {item.specialty}
                </Typography>
              </Grid>
              <Grid item>
                <Delete
                  style={{ cursor: "pointer", color: "grey" }}
                  onClick={() =>
                    deleteLayerAssignmentHandler("practitioner", item.id)
                  }
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      )}
    </>
  );

  const onSubmit = async (data) => {
    let obj;
    if (watchedFields[0] === "Practitioner") {
      obj = {
        practitioners: data.practitioners?.map((item) => item.id),
      };
    } else {
      obj = {
        teams: data.practitioners?.map((item) => item.id),
      };
    }
    const res = await addLayerAssignment.requestWithToast(
      t("Assigned successfully"),
      level.id,
      obj
    );
    if (res.ok) {
      getLayerAssignment.request(level.id);
    } else if (res.data?.errors) {
      console.log("err", res.data.errors?.practitioners);
    }
  };

  return (
    <MainContainer title={`${t("Assigning to")} ${level.name}`}>
      <CebForm
        onSubmit={onSubmit}
        fields={fields}
        viewModeState={[viewMode, setViewMode]}
        fieldsToWatch={fieldsToWatch}
        getWatchedFields={getWatchedFields}
        initialValues={initialValues}
        cancelHandler={cancelHandler}
        requestErrors={addLayerAssignment.error}
      />
      {assignedMembersView()}
    </MainContainer>
  );
};

export default Assigning;
