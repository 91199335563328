import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import UploadIcon from "../../assets/icons/Upload";
import Document from "../../assets/icons/Document";
import { Delete } from "@mui/icons-material";
import mainApi from "../../api/main";
import { useTranslation } from "react-i18next";

export default function CebFileField({
  acceptFiles = ["*"],
  register,
  name,
  multiple = false,
  setValue,
  sendOnUpload = false,
  url = "",
  trigger,
  watch,
  errors,
}) {
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const deleteHandler = async (index) => {
    const newFiles = files.filter((f, i) => i !== index);
    const id = files.find((f, i) => i === index).id;
    if (sendOnUpload) {
      setLoading(true);
      await mainApi.deleteFile(`${url}${id}/`);
    }
    setFiles(newFiles);
    setLoading(false);
  };

  const changeHandler = async (e) => {
    if (e.target.files) {
      const watchedFiles = [...e.target.files];
      const newFiles = [];
      for (let i = 0; i < watchedFiles.length; i++) {
        if (!files?.find((f) => f.file.name === watchedFiles[i].name)) {
          newFiles.push({ file: watchedFiles[i] });
        }
      }
      if (newFiles.length > 0) {
        if (sendOnUpload && !errors[name]) {
          setLoading(true);
          for (const index in newFiles) {
            const res = await mainApi.sendFile(url, newFiles[index].file);
            newFiles[index].id = res.data.data.id;
          }
        }
        if (files) {
          setFiles((state) => [...state, ...newFiles]);
        } else {
          setFiles(newFiles);
        }

        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (files) {
      setValue(
        name,
        files.map((item) => item.file)
      );
      trigger(name);
    } else {
      setValue(name, []);
    }
  }, [files]);

  return (
    <>
      {loading && (
        <Box alignItems="center">
          <CircularProgress size={30} />
        </Box>
      )}
      {!loading && (
        <Stack direction="row" alignItem="center" justifyContent="left">
          <input hidden disabled {...register(name)} />
          {(multiple || (!multiple && !files?.length)) && (
            <Button
              component="label"
              endIcon={<UploadIcon />}
              style={{
                textTransform: "none",
                color: "#8C8C8C",
                alignSelf: "center",
              }}
            >
              {t("Upload")}
              <input
                style={{ width: 0 }}
                accept={acceptFiles.join(", ")}
                onChange={changeHandler}
                type="file"
                multiple={multiple}
              />
            </Button>
          )}
          <Stack>
            {files?.length > 0 &&
              watch(name)?.length > 0 &&
              files.map((file, index) => (
                <Stack key={index} direction="row" alignItems="center">
                  <Button
                    style={{
                      background: "#A5D2FF",
                      color: "#003468",
                      width: 175,
                      height: 36,
                      borderRadius: 10,
                      fontSize: 16,
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                    disabled
                    startIcon={<Document />}
                  >
                    {file.file.name.length > 12
                      ? `${file.file.name.substr(0, 10)}..`
                      : file.file.name}
                  </Button>
                  <IconButton onClick={() => deleteHandler(index)}>
                    <Delete />
                  </IconButton>
                </Stack>
              ))}
          </Stack>
        </Stack>
      )}
    </>
  );
}
