import React, { useEffect } from "react";
import CountCard from "components/CountCard";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { CardContainer } from "components/Entity/StyledComponents";
import doctorsImg from "assets/images/doctors.png";

import useApi from "hooks/useApi";
import main from "../../api/main";
import { QuickNavCard, StatusCard } from "pages/Superadmin/Dashboard";
import trendingIcon from "assets/images/trendingIcon.png";
import { useNavigate } from "react-router-dom";
import BulletIcon from "assets/icons/Bullet";
import authService from "auth/authService";
import { useTranslation } from "react-i18next";

const EntityDashboard = () => {
  const { t, i18n } = useTranslation();
  //api
  const getEntityDashboard = useApi(main.getEntityDashboard);
  const getClinicList = useApi(main.getClinicList);

  const isHospitalProfile =
    authService.getCurrentUser()?.workplace_type?.hospital_profile;

  useEffect(() => {
    getEntityDashboard.request();
    getClinicList.request();
  }, []);

  return (
    <Box sx={{ width: "97%", maxWidth: "1500px", pb: 4 }}>
      <Typography
        color={"secondary"}
        sx={{ mb: 2, fontWeight: 700, fontSize: "31px" }}
      >
        {t("Dashboard")}
      </Typography>
      <Stack direction="row" gap="80px">
        <Stack flex={1} gap="16px">
          <Stack position="relative" height="216px" justifyContent="center">
            <CardContainer
              style={{
                height: "129px",
                justifyContent: "center",
                paddingLeft: i18n.language === "en" && "32px",
                paddingRight: i18n.language === "ar" && "32px",
              }}
            >
              <Typography fontSize="20px" fontWeight={500}>
                {t("WELCOME BACK")}
              </Typography>
            </CardContainer>
            <img
              src={doctorsImg}
              style={{
                position: "absolute",
                top: 0,
                right: i18n.language === "en" && 16,
                left: i18n.language === "ar" && 16,
              }}
            />
          </Stack>
          <LicenseCard licenseData={getEntityDashboard.data.license_info} />
        </Stack>
        <Stack pt="35px" width="30%" gap="16px">
          <CountCard
            title={t("Number of Patients Admitted Today")}
            count={getEntityDashboard.data?.number_admitted_patients_today}
          />
          <CountCard
            title={t("Number of Doctors")}
            count={getEntityDashboard.data?.number_doctors}
            link="/entity/employees"
          />
          <QuickNavCard
            title={
              isHospitalProfile ? t("External Clinics") : t("Clinic Managment")
            }
            items={getClinicList.data?.map((item) => ({
              label: item.clinic_name,
              link: `/entity/${
                isHospitalProfile ? "external-clinics" : "clinic-management"
              }?id=${item.id}`,
            }))}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default EntityDashboard;

const LicenseCharts = ({ licenseData }) => {
  const { t } = useTranslation();
  return (
    <Stack
      width="100%"
      justifyContent={"space-around"}
      direction="row"
      overflow={"auto"}
    >
      <CountCard
        title={t("Active")}
        count={licenseData?.active_license_count}
        percentage={{
          value:
            (licenseData?.active_license_count /
              licenseData?.total_license_count) *
            100,
          color: "green",
        }}
        border={false}
        width="130px"
      />
      <CountCard
        title={t("Unused")}
        count={licenseData?.active_unused_license_count}
        percentage={{
          value:
            (licenseData?.active_unused_license_count /
              licenseData?.total_license_count) *
            100,
          color: "#0050A0",
        }}
        border={false}
        width="130px"
      />
    </Stack>
  );
};

const LicenseCard = ({ licenseData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack
      border="1px solid rgba(0,0,0,0.3)"
      borderRadius={"10px"}
      gap={2}
      padding={"16px"}
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography fontWeight={500} fontSize="16px">
          {t("Licenses")}
        </Typography>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/entity/license")}
        >
          <img src={trendingIcon} />
          <Typography fontWeight={500} fontSize="13px" color="#0050A0">
            {t("Manage")}
          </Typography>
        </Stack>
      </Stack>
      <LicenseCharts licenseData={licenseData} />
      <Stack gap={"16px"} maxHeight={500} overflow="auto">
        <Grid container rowgap={"16px"}>
          <Grid item xs={6}>
            <Typography fontWeight={500} fontSize="16px">
              {t("Recent Employees")}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Typography fontWeight={500} fontSize="16px">
              {t("Status")}
            </Typography>
          </Grid>
        </Grid>
        {licenseData?.recent_user_license_status?.map((item, index) => (
          <Grid
            container
            paddingBottom="8px"
            borderBottom={
              index !== licenseData.recent_user_license_status.length - 1 &&
              "0.6px solid rgba(0,0,0,0.3)"
            }
          >
            <Grid xs={6}>
              <Stack direction="row" alignItems="center" gap={2}>
                <BulletIcon />
                <Typography>{item.full_name}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={6} display="flex" justifyContent={"center"}>
              <Box
                width={100}
                height={32}
                backgroundColor={
                  item.license_status === "Active"
                    ? "rgba(16, 166, 58, 0.3)"
                    : "rgba(142, 2, 1, 0.3)"
                }
                borderRadius={10}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                alignSelf="center"
              >
                <Typography
                  textAlign={"center"}
                  color={
                    item.license_status === "Active"
                      ? "rgba(16, 166, 58, 1)"
                      : "rgba(142, 2, 1, 1)"
                  }
                  fontSize={16}
                >
                  {t(item.license_status)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Stack>
  );
};
