import React from "react";

export default ({ isActive }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    style={
      isActive
        ? {
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: 2,
            color: "#005AB3",
          }
        : { padding: 2, color: "#fff" }
    }
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.7069 22.7069C32.8891 22.5183 32.9899 22.2657 32.9876 22.0035C32.9853 21.7413 32.8801 21.4905 32.6947 21.3051C32.5093 21.1197 32.2585 21.0145 31.9963 21.0122C31.7341 21.01 31.4815 21.1108 31.2929 21.2929L23.9999 28.5859L20.7069 25.2929C20.5183 25.1108 20.2657 25.01 20.0035 25.0122C19.7413 25.0145 19.4905 25.1197 19.3051 25.3051C19.1197 25.4905 19.0145 25.7413 19.0122 26.0035C19.01 26.2657 19.1108 26.5183 19.2929 26.7069L23.9999 31.4139L32.7069 22.7069Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 15V36C38 36.7956 37.6839 37.5587 37.1213 38.1213C36.5587 38.6839 35.7956 39 35 39H17C16.2044 39 15.4413 38.6839 14.8787 38.1213C14.3161 37.5587 14 36.7956 14 36V8C14 7.20435 14.3161 6.44129 14.8787 5.87868C15.4413 5.31607 16.2044 5 17 5H28L38 15ZM28 16C27.7348 16 27.4804 15.8946 27.2929 15.7071C27.1054 15.5196 27 15.2652 27 15V7H17C16.7348 7 16.4804 7.10536 16.2929 7.29289C16.1054 7.48043 16 7.73478 16 8V36C16 36.2652 16.1054 36.5196 16.2929 36.7071C16.4804 36.8946 16.7348 37 17 37H35C35.2652 37 35.5196 36.8946 35.7071 36.7071C35.8946 36.5196 36 36.2652 36 36V16H28ZM29 8.828L34.172 14H29V8.828Z"
      fill="currentColor"
    />
    <path
      d="M12 11V38C12 38.7956 12.3161 39.5587 12.8787 40.1213C13.4413 40.6839 14.2044 41 15 41H34V43H15C13.6739 43 12.4021 42.4732 11.4645 41.5355C10.5268 40.5979 10 39.3261 10 38V11H12Z"
      fill="currentColor"
    />
  </svg>
);
