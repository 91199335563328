import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={449}
    height={420}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.655 212.095a3.745 3.745 0 0 1 1.102 2.655 3.749 3.749 0 0 1-1.102 2.655l-22.5 22.5a3.745 3.745 0 0 1-2.655 1.102 3.745 3.745 0 0 1-2.655-1.102l-11.25-11.25a3.753 3.753 0 0 1 1.218-6.124 3.752 3.752 0 0 1 4.092.814l8.595 8.603 19.845-19.853a3.745 3.745 0 0 1 2.655-1.102 3.749 3.749 0 0 1 2.655 1.102Z"
      fill="#346800"
    />
    <path
      d="m227.048 184.848-6.908-7.08 5.363-5.235 4.665 4.777 6.675-.082a21.658 21.658 0 0 1 15.583 6.347 21.675 21.675 0 0 1 6.347 15.583l-.075 6.675 4.77 4.665A21.685 21.685 0 0 1 269.994 226a21.668 21.668 0 0 1-6.526 15.503l-4.778 4.665.083 6.675a21.677 21.677 0 0 1-21.93 21.93l-6.675-.075-4.665 4.77A21.684 21.684 0 0 1 210 285.994a21.668 21.668 0 0 1-15.502-6.526l-4.665-4.778-6.675.083a21.678 21.678 0 0 1-21.93-21.93l.075-6.675-4.77-4.665A21.687 21.687 0 0 1 150.006 226a21.671 21.671 0 0 1 6.527-15.502l4.777-4.665-.082-6.675a21.659 21.659 0 0 1 6.346-15.583 21.677 21.677 0 0 1 15.584-6.347l6.675.075 4.665-4.77a21.672 21.672 0 0 1 31.005 0l-5.363 5.235a14.161 14.161 0 0 0-15.641-3.159 14.156 14.156 0 0 0-4.639 3.159l-6.9 7.08-9.9-.12a14.16 14.16 0 0 0-10.184 4.153 14.17 14.17 0 0 0-4.148 10.187l.12 9.885-7.08 6.907a14.166 14.166 0 0 0-4.27 10.14c0 1.89.377 3.76 1.111 5.501a14.156 14.156 0 0 0 3.159 4.639l7.08 6.9-.12 9.9a14.165 14.165 0 0 0 4.153 10.184 14.17 14.17 0 0 0 10.187 4.149l9.885-.12 6.907 7.08a14.168 14.168 0 0 0 10.14 4.269 14.168 14.168 0 0 0 10.14-4.269l6.9-7.08 9.9.12a14.176 14.176 0 0 0 14.333-14.34l-.12-9.885 7.08-6.908a14.168 14.168 0 0 0 4.269-10.14 14.179 14.179 0 0 0-4.269-10.14l-7.08-6.9.12-9.9a14.18 14.18 0 0 0-8.829-13.287 14.16 14.16 0 0 0-5.511-1.045l-9.885.12Z"
      fill="#346800"
    />
    <path
      d="M228.121 305.617c13.229 33.92 32.583 68.312 38.766 104.351M191.96 319.619c-4.848 18.98-13.624 35.209-17.99 54.337M133.891 308.571c-17.748 8.417-36.342 15.765-51.168 28.867M306.962 93.533c-14.114 20.182-30.444 36.098-45.931 54.461M240.473 81.123l-25.192 51.24M169.076 66.391c.651 19.405-1.129 52.56 11.616 68.656M279.21 194.791c31.939-17.479 63.558-41.092 98.504-51.855M297.743 228.853c19.446 2.368 36.669 8.986 56.199 10.857M294.25 287.86c10.629 16.519 20.305 34.014 35.204 47.034M70.946 129.034c21.828 11.403 39.711 25.553 59.912 38.551M67.183 196.567l54.053 18.397M61.75 269.265c19.16-3.139 52.268-5.635 66.593-20.343"
      stroke="#6CA633"
      strokeWidth={8}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgComponent;
