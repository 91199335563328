const StyledTextField = ({
  name,
  type = "text",
  options,
  onChange,
  defaultValue,
  id,
}) =>
  type === "text" ? (
    <input
      type="text"
      style={{
        height: "41px",
        width: "230px",
        borderRadius: "10px",
        backgroundColor: "#F0F7FF",
        border: "1px solid #003468",
        padding: "4px 10px 4px 10px",
        fontSize: "18px",
        fontFamily: "Poppins",
        marginTop: "8px",
      }}
      onChange={onChange}
      placeholder={name}
      defaultValue={defaultValue}
    />
  ) : (
    <select
      className="classic"
      style={{
        height: "45px",
        width: "230px",
        borderRadius: "10px",
        backgroundColor: "#F0F7FF",
        border: "1px solid #003468",
        padding: "4px 10px 4px 10px",
        fontSize: "18px",
        fontFamily: "Poppins",
        marginTop: "8px",
      }}
      onChange={onChange}
      defaultValue={defaultValue}
      id={id && id}
    >
      {options.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );

export default StyledTextField;
