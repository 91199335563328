import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const CardContainer = styled((props) => (
  <Grid container item direction={"column"} {...props}>
    {props.children}
  </Grid>
))`
  background-color: #f2fcff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
`;
export const RowContainer = styled((props) => (
  <Grid
    container
    direction={"row"}
    alignItems={"center"}
    gap={2}
    sx={{ height: props.height ? props.height : " 250px" }}
    {...props}
  >
    {props.children}
  </Grid>
))`
  margin-bottom: 50px;
`;
