import React, { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";

//MUI components
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import {
  Autocomplete,
  createFilterOptions,
  Box,
  Chip,
  FormControl,
  InputBase,
  Select,
  Stack,
  TextField,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import OutlinedInput from "@mui/material/OutlinedInput";

//icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//styles
import { makeStyles, withStyles } from "@mui/styles";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const iconStyles = {
  selectIcon: {
    display: "flex",
    backgroundColor: "#A5D2FF",
    height: "100%",
    alignItems: "center",
    // borderRadius: '0 10px 10px  0',
    cursor: "pointer",
  },
};

const CustomSelectIcon = withStyles(iconStyles)(({ className, classes }) => {
  return (
    <Box className={clsx(classes.selectIcon)}>
      <KeyboardArrowDownIcon fontSize="small" className={clsx(className)} />
    </Box>
  );
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: "500!important",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

export default function CebSelectField({
  multiple = false,
  autoComplete = false,
  width = "100%",
  items,
  idName,
  isEnum = false,
  register,
  control,
  name,
  onInputChange,
  objectId,
  disabled = false,
  addNewValue = false,
  errors,
  selectAll,
  defaultValue,
  disableFilter = false,
  ...props
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const isAllSelected = items.length > 0 && selected.length === items.length;

  const filter = createFilterOptions();

  const selectStyle = (errors) => ({
    borderRadius: 2.5,
    outline: `1.5px solid ${errors?.[name] ? "#ff0000" : "#00172F"}`,
    height: 41,
    width,
  });

  const multipleSelectStyle = (errors) => ({
    borderRadius: 2.5,
    outline: `1.5px solid ${errors?.[name] ? "#ff0000" : "#00172F"}`,
    width,
  });

  const getLabel = (item, autoComplete) => {
    console.log("ddd", autoComplete, item);
    let label = "";
    if (isEnum) label = item;
    else if (objectId === true)
      label =
        JSON.parse(item).name ||
        JSON.parse(item).title ||
        JSON.parse(item).label;
    else if (autoComplete) label = item?.label;
    else {
      let filtered = items.filter((obj) => obj.id === item);
      if (filtered.length > 0)
        label = filtered[0].name || filtered[0].label || filtered[0].title;
      else label = item;
    }
    return label;
  };

  const getItems = (selected, autoComplete = false) => {
    if (selected.length === items.length) return <Chip label="All" />;
    else
      return selected.map((item, i) => (
        <Chip key={i} label={getLabel(item, autoComplete)} />
      ));
  };
  const setItems = (filteredItems) => {
    if (isEnum) {
      return filteredItems;
    } else if (objectId) {
      return JSON.stringify(filteredItems);
    } else {
      return filteredItems.map((item) => item.id);
    }
  };

  const optionRenderer = (props, option, state) => {
    // const selectAllProps =
    //   option.value === "select-all" // To control the state of 'select-all' checkbox
    //     ? { checked: allSelected }
    //     : {};
    // console.log('ddd', selected)
    if (option.id === "all")
      return (
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
          {...props}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < items.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary={t("Select All")}
          />
        </MenuItem>
      );

    return (
      <MenuItem
        key={option?.id ? option.id : option /*enum*/}
        value={option?.id ? option.id : option /*enum*/}
        {...props}
      >
        <ListItemIcon>
          <Checkbox checked={state.selected} />
        </ListItemIcon>
        <ListItemText primary={option.label ? option.label : option} />
      </MenuItem>
    );
  };

  return (
    <Stack>
      <FormControl fullWidth>
        {!multiple && !autoComplete && (
          <Controller
            name={name}
            control={control}
            defaultValue={""}
            render={({
              field: { onChange, value },
              fieldState: { error, invalid },
            }) => (
              <Select
                sx={selectStyle(errors)}
                value={value}
                onChange={(value) => onChange(value)}
                disabled={disabled}
              >
                {items.map((item, index) => (
                  <MenuItem
                    key={isEnum ? item : item[idName]}
                    value={
                      isEnum
                        ? item
                        : objectId
                        ? JSON.stringify(item)
                        : item[idName]
                    }
                  >
                    {isEnum ? t(item) : item.title || item.label || item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        )}

        {multiple && !autoComplete && (
          <Controller
            name={name}
            control={control}
            defaultValue={[]}
            render={({
              field: { onChange, value },
              fieldState: { error, invalid },
            }) => (
              <Select
                multiple
                sx={multipleSelectStyle(errors)}
                disabled={disabled}
                value={selected}
                open={openSelect}
                onClose={() => setOpenSelect(false)}
                onOpen={() => setOpenSelect(true)}
                onChange={
                  (value) => {
                    setOpenSelect(false);
                    let valueArr = value.target.value;
                    if (!isEnum) {
                      for (const v of valueArr) {
                        if (
                          items.find((item) =>
                            objectId
                              ? JSON.parse(v).id === item.id
                              : v === item.id
                          )?.only_one
                        ) {
                          valueArr = [v];
                          break;
                        }
                      }
                    }
                    console.log("selected", valueArr);
                    if (valueArr[valueArr.length - 1] === "all") {
                      const filteredItems = items.filter(
                        (item) => !item.only_one
                      );
                      valueArr =
                        selected.length === filteredItems.length
                          ? []
                          : setItems(filteredItems);
                      setSelected(
                        selected.length === filteredItems.length
                          ? []
                          : setItems(filteredItems)
                      );
                    } else {
                      setSelected(valueArr);
                    }

                    // value.target.value = valueArr;
                    onChange(
                      typeof valueArr === "string"
                        ? valueArr.split(",")
                        : valueArr
                    );
                  }
                  //
                }
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {getItems(selected)}
                    </Box>
                  );
                }}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 && selected.length < items.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary={t("Select All")}
                  />
                </MenuItem>
                {items.map((item, index) => (
                  <MenuItem
                    key={isEnum ? item : item[idName]}
                    value={
                      isEnum
                        ? item
                        : objectId
                        ? JSON.stringify(item)
                        : item[idName]
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          selected.indexOf(
                            isEnum
                              ? item
                              : objectId
                              ? JSON.stringify(item)
                              : item[idName]
                          ) > -1
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        isEnum ? t(item) : item.title || item.label || item.name
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        )}
      </FormControl>
      {!multiple && autoComplete && (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : ""}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <Autocomplete
              filterOptions={(options, params) => {
                let filtered = options;
                if (!disableFilter) filtered = filter(options, params);
                if (!addNewValue) return filtered;
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }
                return filtered;
              }}
              options={items}
              disabled={disabled}
              defaultValue={defaultValue ? defaultValue : ""}
              freeSolo={addNewValue}
              sx={{
                borderRadius: 2.5,
                outline: `1.5px solid ${
                  errors?.[name] ? "#ff0000" : "#00172F"
                }`,
                width,
              }}
              value={value}
              onChange={(event, newValue) => onChange(newValue)}
              onInputChange={(event, newValue) => onInputChange(newValue)}
              renderInput={(params) => <TextField {...params} />}
              {...props}
            />
          )}
        />
      )}

      {multiple && autoComplete && (
        <Controller
          name={name}
          control={control}
          defaultValue={Array.isArray(defaultValue) ? defaultValue : []}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <Autocomplete
              filterOptions={(options, params) => {
                let filtered = options;
                if (!disableFilter) filtered = filter(options, params);
                return filtered;
              }}
              disabled={disabled}
              multiple
              options={
                selectAll
                  ? [{ id: "all", label: t("Select All") }, ...items]
                  : items
              }
              defaultValue={[]}
              sx={multipleSelectStyle(errors)}
              value={value}
              onChange={(event, newValue) => {
                let valueArr =
                  typeof newValue === "string" ? newValue.split(",") : newValue;
                if (!isEnum) {
                  for (const v of valueArr) {
                    if (items.find((item) => v.id === item.id)?.only_one) {
                      valueArr = [v];
                      break;
                    }
                  }
                }
                if (valueArr[valueArr.length - 1]?.id === "all") {
                  setSelected(selected.length === items.length ? [] : items);
                  valueArr = selected.length === items.length ? [] : items;
                } else setSelected(valueArr);
                onChange(valueArr);
              }}
              onInputChange={(event, newValue) => onInputChange(newValue)}
              renderInput={(params) => <TextField {...params} />}
              renderOption={optionRenderer}
              renderTags={(value, getTagProps) => {
                const numTags = value.length;
                const limitTags = 10;

                return (
                  <>
                    {value.slice(0, limitTags).map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        label={option.label ? option.label : option}
                      />
                    ))}

                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              {...props}
            />
          )}
        />
      )}
    </Stack>
  );
}
