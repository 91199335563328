import React from "react";

export default ({ isActive }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    style={
      isActive
        ? {
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: 2,
            color: "#005AB3",
          }
        : { padding: 2, color: "#fff" }
    }
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_582_1223"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="44"
      height="44"
    >
      <path
        d="M14 34C16.7614 34 19 31.7614 19 29C19 26.2386 16.7614 24 14 24C11.2386 24 9 26.2386 9 29C9 31.7614 11.2386 34 14 34Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34 34C36.7614 34 39 31.7614 39 29C39 26.2386 36.7614 24 34 24C31.2386 24 29 26.2386 29 29C29 31.7614 31.2386 34 34 34Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 14C26.7614 14 29 11.7614 29 9C29 6.23858 26.7614 4 24 4C21.2386 4 19 6.23858 19 9C19 11.7614 21.2386 14 24 14Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 44C24 38.477 19.523 34 14 34C8.477 34 4 38.477 4 44M44 44C44 38.477 39.523 34 34 34C28.477 34 24 38.477 24 44M34 24C34 18.477 29.523 14 24 14C18.477 14 14 18.477 14 24"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0_582_1223)">
      <path d="M0 0H48V48H0V0Z" fill="currentColor" />
    </g>
  </svg>
);
