import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import MainContainer from "components/Entity/MainContainer";
import Modal from "components/UI/Modal";
import api from "../../api/main";
import StyledButton from "../../components/UI/StyledButton";
import CebForm from "components/Form/CebForm";
import CebSelectField from "components/Form/CebSelectField";
import getBase64 from "helpers/getBase64";
import useApi from "hooks/useApi";
import { getCurrentUser } from "auth/authService";
import ActionButton from "components/UI/ActionButton";
import main from "../../api/main";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ViewButton = ({ url }) => {
  const { t } = useTranslation();

  return (
    <a
      style={{
        background: "#A5D2FF",
        color: "#003468",
        padding: "1px 6px 1px 6px",
        borderRadius: 5,
        fontSize: 14,
        fontWeight: 300,
        cursor: "pointer",
      }}
      onClick={() => window.open(url, "_blank").focus()}
    >
      {t("view")}
    </a>
  );
};

const getYearList = () => {
  let year = new Date().getFullYear();
  const minYear = year - 100;
  let years = [];
  while (year >= minYear) {
    years.push(year);
    year--;
  }
  return years;
};

const Employees = () => {
  const [openModal, setOpenModal] = useState(false);
  const [userType, setUserType] = useState({});
  const [viewMode, setViewMode] = useState();
  const [chosenCardId, setChosenCardId] = useState();
  const [listData, setListData] = useState([]);
  const [resetForm, setResetForm] = useState();
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [watchedFields, setWatchedFields] = useState([]);
  const [filterByEntityId, setFilterByEntityId] = useState(null);
  const { t, i18n } = useTranslation();

  const useFormModal = useForm();
  const navigate = useNavigate();

  //url params
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const userId = urlParams.get("id");
    const userTypeId = urlParams.get("type_id");
    const userTypeName = urlParams.get("type_name");
    const role = urlParams.get("role");
    const isActive = urlParams.get("is_active");
    // detail view
    if (userId) {
      setUserType({ is_doctor_profile: role === "Doctor" });
      handleDetailView(userId, role, isActive === "true");
    }
    // creation view
    else if (role) {
      setUserType({
        id: userTypeId,
        name: userTypeName,
        is_doctor_profile: role === "Doctor",
      });
      setViewMode("form");
    }
    // list of users view
    else {
      setViewMode("cards");
    }
  }, [urlParams.get("role")]);

  //add new practitioner
  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  //update role modal
  const handleRoleModal = () => {
    setOpenRoleModal((s) => !s);
  };
  const updateRoleHandler = async (roleId) => {
    const res = await updatePractitionerRole.requestWithToast(
      "Updated successfully",
      chosenCardId,
      roleId
    );
    if (res.ok) {
      handleRoleModal();
      navigate(-1);
    }
  };

  //api
  const getPractitoners = useApi(api.getPractitionerList);
  const createDoctor = useApi(api.createDoctor);
  const createPractitioner = useApi(api.createPractitioner);
  const getSpecialtyList = useApi(api.getSpecialtyList);
  const getPractitioner = useApi(api.getPractitioner);
  const getPractitionerTypeList = useApi(api.getGroupsList);
  const getEntitiesList = useApi(api.getEntitiesList);
  const deactivatePractitioner = useApi(main.deactivatePractitioner);
  const activatePractitioner = useApi(main.activatePractitioner);
  const updatePractitionerRole = useApi(main.updatePractitionerRole);

  const practitionerFields = [
    {
      label: "Name",
      name: "full_name",
      validation: { required: true },
      xs: 10,
    },
    {
      twoFieldsInRow: true,
      fields: [
        {
          label: "NID",
          name: "nid",
          type: "number",
          validation: { required: true, length: 14 },
          xs: 10.4,
        },
        {
          type: "file",
          label: "",
          name: "username_pic",
          acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
          validation: { required: true, message: "Invalid Image" },
          xs: 1,
        },
      ],
    },
    {
      twoFieldsInRow: true,
      fields: [
        {
          label: "Syndicate ID",
          name: "syndicate_id",
          xs: 10.4,
          validation: { required: userType.is_doctor_profile },
        },
        {
          type: "file",
          label: "",
          name: "syndicate_pic",
          acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
          xs: 1,
          validation: {
            required: userType.is_doctor_profile,
            message: "Invalid Image",
          },
        },
      ],
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      xs: 10,
    },
    {
      label: "Mobile number",
      name: "phone_number",
      type: "number",
      validation: {
        required: true,
        length: 11,
        message: t("Enter valid mobile number"),
      },
      xs: 10,
    },
    {
      type: "date",
      label: "Date of birth",
      name: "birth_date",
      validation: { required: true, disableFuture: true },
      xs: 10,
    },
  ];

  const doctorFields = [
    ...practitionerFields,
    {
      type: "select",
      label: "Specialty",
      name: "specialty",
      items: getSpecialtyList.data,
      validation: { required: true },
      xs: 10,
    },
    {
      type: "date",
      label: "Egyptian fellowship Date",
      name: "egyptian_fellowship_date",
      validation: { disableFuture: true },
      xs: 10,
    },
    {
      type: "file",
      label: "Professional Permit",
      name: "professional_permit",
      acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
      xs: 10,
      validation: { required: true, message: "Invalid Image" },
    },
    {
      type: "file",
      label: "Profile Picture",
      name: "profile_pic",
      acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
      xs: 10,
      validation: { required: true, message: "Invalid Image" },
    },

    {
      fieldType: "nested",
      nestedId: 1,
      title: "Education",
      name: "degrees",
      required: true,
      fields: [
        {
          childField: true,
          label: "University",
          name: "university",
          width: 400,
          validation: { required: true },
        },
        {
          childField: true,
          label: "Degree",
          name: "type",
          type: "select",
          isEnum: true,
          items: ["Bachelor", "Master", "PhD"],
          width: 400,
          validation: {
            required: true,
          },
        },
        (watchedFields[0] === "Master" || watchedFields[0] === "PhD") && {
          childField: true,
          label: "Specialty",
          name: "name",
          type: "select",
          isEnum: true,
          items: getSpecialtyList.data?.map((item) => item.name),
          width: 400,
          validation: {
            required: true,
          },
        },
        {
          childField: true,
          label: "Graduation year",
          name: "graduation_year",
          type: "select",
          items: getYearList(),
          isEnum: true,
          validation: {
            required: true,
          },
          width: 400,
        },
        {
          childField: true,
          label: "Certificate",
          name: "certificate",
          type: "file",
          acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
          validation: { required: true, message: "Invalid Image" },
          width: 400,
        },
      ].filter((item) => item),
    },
  ];

  useEffect(() => {
    getSpecialtyList.request();
  }, []);

  useEffect(() => {
    getPractitoners.request(null, filterByEntityId, pageNumber);
    getPractitionerTypeList.request();
    if (getCurrentUser().role === "Admin") getEntitiesList.request();
  }, [
    createDoctor.data,
    createPractitioner.data,
    pageNumber,
    filterByEntityId,
  ]);

  const onSubmitModal = (data) => {
    let userTypeId, userTypeName, role;
    if (!data["user_type"]) return;
    if (data["user_type"] === "doctor") {
      userTypeName = "Doctor";
      role = "Doctor";
    } else if (data["user_type"] === "notDoctor") {
      userTypeName = "Practitioner";
      role = "Practitioner";
    } else {
      const type = getPractitionerTypeList.data.find(
        (item) => item.id === data["user_type"]
      );

      userTypeId = type.id;
      userTypeName = type.name;
      role = type.is_doctor_profile ? "Doctor" : "Practitioner";
    }
    setOpenModal(false);
    navigate(`?type_id=${userTypeId}&type_name=${userTypeName}&role=${role}`);
  };

  const onSubmitDoctor = async (data) => {
    createDoctor.resetError();
    const degrees = [];
    for (const degree of data.degrees) {
      const certificate = await getBase64(degree.certificate[0]);
      degrees.push({ ...degree, certificate });
    }
    const obj = {
      ...data,
      degrees,
      username: "M" + data.nid,
      username_pic: await getBase64(data.username_pic[0]),
      syndicate_pic:
        data.syndicate_pic[0] && (await getBase64(data.syndicate_pic[0])),
      professional_permit: await getBase64(data.professional_permit[0]),
      profile_pic: await getBase64(data.profile_pic[0]),
    };
    if (userType.id !== "undefined") {
      obj.type = userType.id;
    }
    const res = await createDoctor.requestWithToast("Added successfully", obj);
    if (res.ok) {
      navigate(-1);
      setResetForm(true);
      setPageNumber(1);
    }
  };

  const onSubmitPractitioner = async (data) => {
    createPractitioner.resetError();
    const obj = {
      full_name: data.full_name,
      username: "M" + data.nid,
      username_pic: await getBase64(data.username_pic[0]),
      syndicate_id: data.syndicate_id,
      syndicate_pic:
        data.syndicate_pic[0] && (await getBase64(data.syndicate_pic[0])),
      email: data.email,
      phone_number: data.phone_number,
      birth_date: data.birth_date,
    };

    if (userType.id !== "undefined") {
      obj.type = userType.id;
    }

    const res = await createPractitioner.requestWithToast(
      "Added successfully",
      obj
    );
    if (res.ok) {
      navigate(-1);
      setResetForm(true);
      setPageNumber(1);
    }
  };

  const cardItems = [
    { header: "Name", keyName: "data.full_name" },
    { header: "Role", keyName: "data.type?.name || data.role" },
    getCurrentUser().role === "Admin" && {
      header: "Institutions",
      keyName: "data.entities?.map(entity=>entity.entity)",
      type: "accordion",
    },
    getCurrentUser().role !== "Admin" && {
      header: "Department",
      keyName: "data.department",
    },
    viewMode === "cards" && {
      header: "ID",
      keyName: "data.username?.substr(1)",
    },
    getCurrentUser().role === "Admin" && {
      header: "Status",
      keyName: `data.is_active ? "Active" : "Not-Active"`,
      type: "status",
      statusColor: `data.is_active ? "green" : "secondary.main" `,
    },
  ].filter((item) => item);

  const addNewHandler = () => {
    setOpenModal(true);
  };

  const createProfile = (person) => {
    const syndicate = (person.syndicate_id || person.syndicate_pic) && (
      <Stack direction="row" gap={2} alignItems={"center"}>
        <Typography fontSize="16px" fontWeight={300}>
          {person.syndicate_id}
        </Typography>
        <ViewButton url={person.syndicate_pic} />
      </Stack>
    );
    const nid = (
      <Stack direction="row" gap={2} alignItems={"center"}>
        <Typography fontSize="16px" fontWeight={300}>
          {person.username.substr(1)}
        </Typography>
        <ViewButton url={person.username_pic} />
      </Stack>
    );
    const degrees = person.degrees?.map((item, index) => (
      <Stack direction="row" alignItems={"center"} gap={2} key={index}>
        <Typography fontSize="16px" fontWeight={300}>
          {`${item.type},${item.name ? ` ${item.name},` : ""} ${
            item.university
          }, ${item.graduation_year}`}
        </Typography>
        <ViewButton url={item.certificate} />
      </Stack>
    ));

    return {
      syndicate,
      degrees,
      nid,
    };
  };

  const handleViewButton = async (card) => {
    const userId = card.id;
    const role = card.role;
    const isActive = card.is_active;
    navigate(`?id=${userId}&role=${role}&is_active=${isActive}`);
  };

  const handleDetailView = async (userId, role, isActive) => {
    setChosenCardId(userId);
    const newListData = [];
    const res = await getPractitioner.request(userId);
    const person = res.data.data;
    const profile = createProfile(person);
    newListData.push(
      { label: "ID", fieldType: "element", element: profile.nid },
      { label: "Email", data: person.email },
      { label: "Mobile Number", data: person.phone_number },
      { label: "Date of birth", data: person.birth_date },
      profile.syndicate && {
        label: "Syndicate",
        fieldType: "element",
        element: profile.syndicate,
      }
    );
    if (role === "Doctor") {
      newListData.push(
        {
          label: "Specialty",
          data: person.specialty,
        },
        {
          label: "Egyptian fellowship date",
          data: person.egyptian_fellowship_date,
        },
        {
          label: "Professional Permit",
          data: { file: person.professional_permit, file_name: t("view") },
          fieldType: "link",
        },
        profile.degrees?.length > 0 && {
          label: "Degrees",
          fieldType: "element",
          element: <Stack gap={2}>{profile.degrees}</Stack>,
        }
      );
    }

    if (getCurrentUser().role === "Admin") {
      const handleActivation = async () => {
        let res;
        if (isActive) {
          res = await deactivatePractitioner.requestWithToast(
            "Suspended successfully",
            userId
          );
        } else {
          res = await activatePractitioner.requestWithToast(
            "Activated successfully",
            userId
          );
        }

        if (res.ok) {
          navigate(-1);
        }
      };
      newListData.push({
        fieldType: "element",
        width: "100%",
        element: (
          <Stack width="100%" direction="row" justifyContent="flex-end" gap={2}>
            <ActionButton
              style={{ width: "135px", height: "40px" }}
              type={isActive ? "reject" : "active"}
              onClick={handleActivation}
            >
              {isActive ? "Suspend" : "Activate"}
            </ActionButton>
          </Stack>
        ),
      });
    } else {
      newListData.push({
        fieldType: "element",
        width: "100%",
        element: (
          <Stack
            width="100%"
            direction="row"
            paddingRight={4}
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleRoleModal}
            >
              <Stack direction={"row"} alignItems={"center"}></Stack>
              {t("Update Role")}
              <Edit />
            </Button>
          </Stack>
        ),
      });
    }

    setListData(newListData.filter((item) => item));
    setViewMode("list");
  };

  const filterPractitionersByEntity = (entityId) => {
    setPageNumber(1);
    if (entityId === "all") {
      setFilterByEntityId(null);
    } else {
      setFilterByEntityId(entityId);
    }
  };

  const headerActionBox = (
    <Stack direction="row" alignItems={"center"} gap={2}>
      <Typography color="primary">Filter by Institution</Typography>
      <Select
        sx={{
          border: "1px solid #003468",
          borderRadius: "10px",
          height: "50px",
        }}
        onChange={(e) => filterPractitionersByEntity(e.target.value)}
        style={{ width: "200px" }}
        defaultValue="all"
        value={filterByEntityId || "all"}
      >
        <MenuItem value="all">All</MenuItem>
        {getEntitiesList.data?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.full_name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );

  return (
    <>
      <Modal open={openRoleModal} handleOpen={handleRoleModal}>
        <RoleForm
          handleClose={handleRoleModal}
          isDoctor={userType?.is_doctor_profile}
          updateRoleHandler={updateRoleHandler}
        />
      </Modal>
      <Modal open={openModal} handleOpen={handleOpenModal}>
        <Stack
          alignItems={"center"}
          gap={8}
          sx={{ pt: 4, direction: i18n.language === "en" ? "ltr" : "rtl" }}
        >
          <Typography
            color={"primary"}
            sx={{ fontWeight: 700, fontSize: "30px" }}
          >
            {t("Add new user")}
          </Typography>

          <form
            onSubmit={useFormModal.handleSubmit(onSubmitModal)}
            style={{ textAlign: "center" }}
          >
            <Stack
              direction="row"
              alignItems={"center"}
              gap={4}
              sx={{ mb: "5rem" }}
            >
              <Typography variant="body2" color="primary">
                {t("User type")}
              </Typography>
              <CebSelectField
                items={
                  getCurrentUser().role === "Admin"
                    ? [
                        { id: "doctor", name: "Doctor" },
                        { id: "notDoctor", name: "Practitioner" },
                      ]
                    : getPractitionerTypeList.data
                }
                register={useFormModal.register}
                control={useFormModal.control}
                name="user_type"
                idName="id"
                width={300}
              />
            </Stack>
            <StyledButton type="submit">{t("Next")}</StyledButton>
          </form>
        </Stack>
      </Modal>
      <MainContainer
        title={
          viewMode === "cards"
            ? `${getCurrentUser().role === "Admin" ? "Users" : "Employees"}`
            : viewMode === "list"
            ? "Profile"
            : viewMode === "form" &&
              (userType?.is_doctor_profile
                ? "Add new doctor"
                : `${t("Add new")} ${userType?.name}`)
        }
      >
        <Stack gap={1}>
          <CebForm
            headerActionBox={
              getCurrentUser().role === "Admin" && headerActionBox
            }
            fields={
              userType?.is_doctor_profile ? doctorFields : practitionerFields
            }
            cardItems={cardItems}
            cardsData={
              viewMode === "cards"
                ? getPractitoners.data
                : [getPractitioner.data]
            }
            onSubmit={
              userType?.is_doctor_profile
                ? onSubmitDoctor
                : onSubmitPractitioner
            }
            viewModeState={[viewMode, setViewMode]}
            addNewHandler={addNewHandler}
            formWidth="70%"
            chosenCardId={chosenCardId}
            handleViewButton={handleViewButton}
            listData={listData}
            resetState={[resetForm, setResetForm]}
            loading={
              getPractitoners.loading ||
              createDoctor.loading ||
              createPractitioner.loading
            }
            profile={true}
            requestErrors={
              !userType?.is_doctor_profile
                ? createPractitioner.error
                : createDoctor.error?.username
                ? {
                    ...createDoctor.error,
                    nid: ["User with same NID exists"],
                  }
                : createDoctor.error
            }
            cancelHandler={() => navigate(-1)}
            backHandler={() => navigate(-1)}
            totalCount={getPractitoners.count}
            paginationState={[pageNumber, setPageNumber]}
            fieldsToWatch={{ name: ["degrees_type"] }}
            getWatchedFields={(f) => setWatchedFields(f)}
          />
        </Stack>
      </MainContainer>
    </>
  );
};

export default Employees;

const RoleForm = ({ handleClose, isDoctor, updateRoleHandler }) => {
  const [viewMode, setViewMode] = useState("form");
  const [roleList, setRoleList] = useState([]);

  const getGroupsList = useApi(main.getGroupsList);

  useEffect(() => {
    getGroupsList.request();
  }, []);

  useEffect(() => {
    if (isDoctor) {
      setRoleList(getGroupsList.data.filter((item) => item.is_doctor_profile));
    } else {
      setRoleList(getGroupsList.data.filter((item) => !item.is_doctor_profile));
    }
  }, [getGroupsList.data, isDoctor]);

  const onSubmitHandler = (data) => {
    if (!data.role) return;
    updateRoleHandler(data.role);
  };

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      modalForm
      modalTitle={"Update Role"}
      fields={[
        {
          label: "Role",
          name: "role",
          type: "select",
          items: roleList,
        },
      ]}
      cancelHandler={handleClose}
      formWidth={700}
      onSubmit={onSubmitHandler}
    />
  );
};
