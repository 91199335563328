import { defaultPageSize } from "config/Constants";
import client from "./client";

const login = (obj) => client.post("/auth/v1/login/", obj);
const changePassword = (oldPassword, newPassword, newPasswordConfirm) =>
  client.patch(`/auth/v1/change-password/`, {
    old_password: oldPassword,
    new_password: newPassword,
    new_password_confirmation: newPasswordConfirm,
  });

//Address
const getStates = () => client.get(`/address/states/?pagination=false`);
const getAreas = (stateId) =>
  client.get(`/address/states/${stateId}/areas?pagination=false`);

//Entity
const createEntity = (entityObj) =>
  client.post("/entity/v1/entities/", entityObj);
const entityLogin = (data) => client.post("/auth/v1/login/", data);
const getMyEntityProfile = () => client.get(`/entity/v1/entities/me/`);

//Doctors
const createDoctor = (data) => client.post("/profile/v1/doctors/", data);
const getDoctor = (doctorId) => client.get(`/profile/v1/doctors/${doctorId}/`);
const getEntityDoctors = () => client.get("/profile/v1/doctors/");

//Practitioners
const getPractitionerList = (type, entityId, pageNumber = 1) => {
  const pageQuery = pageNumber
    ? `page=${pageNumber}&page_size=${defaultPageSize}`
    : "pagination=false";
  return client.get(
    type
      ? `/profile/v1/practitioners/?type=${type}&${pageQuery}`
      : entityId
      ? `/profile/v1/practitioners/?entity=${entityId}&${pageQuery}`
      : `/profile/v1/practitioners/?${pageQuery}`
  );
};

const getPractitionerByNid = (nid) =>
  client.get(`/profile/v1/practitioners/?username=M${nid}`);
const createPractitioner = (data) =>
  client.post("/profile/v1/practitioners/", data);
const getPractitioner = (practitionerId) =>
  client.get(`/profile/v1/practitioners/${practitionerId}/`);
// const getPractitionerTypes = () => client.get(`/profile/practitioner-types/`);
const getPractitionerTypes = () =>
  client.get(`/app-roles/v1/groups/?pagination=false`);
const activatePractitioner = (practitionerId) =>
  client.patch(`/profile/v1/practitioners/${practitionerId}/active/`);
const deactivatePractitioner = (practitionerId) =>
  client.patch(`/profile/v1/practitioners/${practitionerId}/inactive/`);
const updatePractitionerRole = (practitionerId, roleId) =>
  client.patch(`/profile/v1/practitioners/${practitionerId}/change_role/`, {
    type: roleId,
  });

/////////////////////
const getSpecialtyList = () =>
  client.get("/profile/doctor-specialties/?pagination=false");
//Entity Setup
const getLayerTypeList = () =>
  client.get(`/entity-setup/v1/layer_type/?pagination=false`);
const addLayerType = (obj) => client.post(`/entity-setup/v1/layer_type/`, obj);
const addLayer = (obj) => client.post(`/entity-setup/v1/layer/`, obj);
const getLayerList = () =>
  client.get(`/entity-setup/v1/layer/?pagination=false`);
const updateLayer = (layerId, obj) =>
  client.patch(`/entity-setup/v1/layer/${layerId}/`, obj);
const deleteLayer = (layerId) =>
  client.delete(`/entity-setup/v1/layer/${layerId}/`);

//Enrollment
const requestPractitionerEnroll = (obj) =>
  client.post(`/entity-enroll/v1/practitioner-enroll/enroll/`, obj);
const getPractitionerSecureSearch = (nid) =>
  client.get(`/profile/v1/practitioners/secure_search/?username=M${nid}`);

//Teams
const getTeamsList = (pageNumber) =>
  client.get(
    pageNumber
      ? `/entity-setup/v1/teams/?page=${pageNumber}&page_size=${defaultPageSize}`
      : `/entity-setup/v1/teams/?pagination=false`
  );
const getTeam = (id) => client.get(`/entity-setup/v1/teams/${id}`);
const createTeam = (obj) => client.post("/entity-setup/v1/teams/", obj);
const updateTeam = (id, obj) =>
  client.patch(`/entity-setup/v1/teams/${id}/`, obj);
const deleteTeam = (id) => client.delete(`/entity-setup/v1/teams/${id}`);
const getTeamHeadList = () =>
  client.get(`/entity-setup/v1/teams/team_head_list/?pagination=false`);
const getTeamMemberList = () =>
  client.get(`/entity-setup/v1/teams/team_member_list/?pagination=false`);

//otp
const sendOtpById = (obj) => client.post("/otp/v1/send-otp-by-id/", obj);
const validateOtpEnrollUser = (obj) =>
  client.post("/otp/v1/validate-otp-confirm-enroll-user/", obj);
//Assigning
const addLayerAssignment = (id, obj) =>
  client.patch(`/entity-setup/v1/layer/${id}/add-layer-assignment/`, obj);
const getLayerAssignment = (layerId) =>
  client.get(`/entity-setup/v1/layer/${layerId}/`);
const removeLayerAssignment = (id, obj) =>
  client.patch(`/entity-setup/v1/layer/${id}/remove-layer-assignment/`, obj);

//entities
const getEntitiesList = (pageNumber) =>
  client.get(
    pageNumber
      ? `/entity/v1/entities/?page=${pageNumber}&page_size=${defaultPageSize}`
      : "/entity/v1/entities/?pagination=false"
  );
const getEntity = (id) => client.get(`/entity/v1/entities/${id}`);

const entityApprove = (id) => client.patch(`/auth/v1/users/${id}/approve/`);
const entityReject = (id, obj) =>
  client.patch(`/auth/v1/users/${id}/reject/`, obj);
const entityToggleActivitation = (id) =>
  client.patch(`/entity/v1/entities/${id}/toggle_active/`);
const createEntityType = (obj) => client.post(`/entity/v1/entity-types/`, obj);
const getEntityTypeList = () =>
  client.get(`/entity/v1/entity-types/?pagination=false`);
const getEntityType = (typeId) =>
  client.get(`entity/v1/entity-types/${typeId}/`);
const updateEntityType = (typeId, obj) =>
  client.patch(`/entity/v1/entity-types/${typeId}/`, obj);
const deleteEntityType = (typeId) =>
  client.delete(`/entity/v1/entity-types/${typeId}/`);

//permissions
const getPermissionList = () =>
  client.get(`/app-roles/v1/permissions/?pagination=false`);
const addRolePermissions = (roleId, obj) =>
  client.patch(`/app-roles/v1/groups/${roleId}/add_permission/`, obj);
const removeRolePermissions = (roleId, obj) =>
  client.patch(`/app-roles/v1/groups/${roleId}/remove_permission/`, obj);
const getGroupsList = () =>
  client.get("/app-roles/v1/groups/?pagination=false");
const createGroup = (obj) => client.post("/app-roles/v1/groups/", obj);
const getRolePermissions = (roleId) =>
  client.get(`/app-roles/v1/groups/${roleId}/permissions/?pagination=false`);
const resetPermissions = (roleId) =>
  client.post(`/app-roles/v1/groups/${roleId}/reset_permissions/`);
const resetClinicPermissions = (roleId) =>
  client.post(`/app-roles/v1/groups/${roleId}/reset_clinic_permission/`);
const getAdminPermissions = (roleId) =>
  client.get(
    `/app-roles/v1/groups/${roleId}/permissions/?admin_permission=true&pagination=false`
  );
//clinic permissions
const getRoleClinicPermissions = (roleId) =>
  client.get(
    `/app-roles/v1/groups/${roleId}/clinic-permissions/?pagination=false`
  );
const getAdminClinicPermissions = (roleId) =>
  client.get(
    `/app-roles/v1/groups/${roleId}/clinic-permissions/?admin_permission=true&pagination=false`
  );
const addRoleClinicPermissions = (roleId, obj) =>
  client.patch(`/app-roles/v1/groups/${roleId}/add_clinic_permission/`, obj);
const removeRoleClinicPermissions = (roleId, obj) =>
  client.patch(`/app-roles/v1/groups/${roleId}/remove_clinic_permission/`, obj);

//Admin Dashboard
const getAdminDashboardList = () => client.get("/dashboard/v1/admin/");
//License
const createLicense = (entityId, obj) =>
  client.post(`/license/v1/entity/${entityId}/licenses/create/`, obj);
const getLicenseDashboardList = (entityId) =>
  client.get(
    `/license/v1/entity/${entityId}/licenses/dashboard/?pagination=false`
  );
const getMyLicenseDashboardList = () =>
  client.get(`/license/v1/entity/me/licenses/dashboard/?pagination=false`);
const assignLicense = (obj) => client.post(`/license/v1/licenses/assign/`, obj);
const unAssignLicense = (licenseId) =>
  client.patch(`/license/v1/licenses/${licenseId}/unassign/`);

const getNotificationUnreadCount = () =>
  client.get(`/notifications/v1/notification/unread_count/`);
const getNotificationList = (pageNumber) =>
  client.get(`/notifications/v1/notification/?page=${pageNumber}&page_size=10`);
const getMyPurchaseOrderList = () =>
  client.get(`/license/v1/license_purchase_orders/`);
const getPurchaseOrderList = (entityId) =>
  client.get(`/license/v1/entity/${entityId}/license_purchase_orders/`);
const addPurchaseOrder = (obj) =>
  client.post(`/license/v1/license_purchase_orders/`, obj);
const approvePurchaseOrder = (entityId, orderId) =>
  client.patch(
    `/license/v1/entity/${entityId}/license_purchase_orders/${orderId}/approve/`
  );
const rejectPurchaseOrder = (entityId, orderId) =>
  client.patch(
    `/license/v1/entity/${entityId}/license_purchase_orders/${orderId}/reject/`
  );

//Clinic Shedular
const getClinicList = (pageNumber = 1) =>
  client.get(
    `/clinic-scheduler-management/v1/departments/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getClinic = (clinicId, doctorOnly = false) =>
  client.get(
    `/clinic-scheduler-management/v1/departments/${clinicId}/?doctor_only=${doctorOnly}`
  );
const addClinicPractitioner = (clinicId, uuid) =>
  client.put(
    `/clinic-scheduler-management/v1/departments/${clinicId}/assign-practitioners/`,
    { practitioner: uuid }
  );
const removeClinicPractitioner = (clinicId, uuid) =>
  client.put(
    `/clinic-scheduler-management/v1/departments/${clinicId}/unassign-practitioners/`,
    { practitioner: uuid }
  );
const addClinic = (obj) =>
  client.post(`/clinic-scheduler-management/v1/departments/`, obj);

const deleteClinic = (clinicId) =>
  client.delete(`/clinic-scheduler-management/v1/departments/${clinicId}/`);

//Doctor Schedule
const getDoctorAvailabilityList = (clinicId, doctorId) =>
  client.get(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/`
  );
const addDoctorAvailability = (clinicId, doctorId, obj) =>
  client.post(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/`,
    obj
  );
const getAppointmentList = (clinicId, doctorId, startDate, endDate) =>
  client.get(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/get_doctor_time_schedule/?start_date=${startDate}&end_date=${endDate}&pagination=false`
  );

const cancelAppintment = (doctorId, shiftId, appointmentId) =>
  client.post(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/shift/${shiftId}/appointments/${appointmentId}/cancel/`
  );

const deleteAvailability = (doctorId, clinicId, availabilityId) =>
  client.delete(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/${availabilityId}/`
  );

//custom-form
const createForm = (obj) => client.post(`/custom_form/v2/forms/`, obj);
const getFormList = (pageNumber = 1) =>
  client.get(
    `/custom_form/v2/forms/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const createTab = (formId, obj) =>
  client.post(`/custom_form/v2/forms/${formId}/tabs/`, obj);
const getFormTabs = (formId) =>
  client.get(`custom_form/v2/forms/${formId}/tabs/?pagination=false`);
const getTabFields = (formId, tabId) =>
  client.get(`/custom_form/v2/forms/${formId}/tabs/${tabId}/?pagination=false`);
const createFields = (formId, tabId, obj) =>
  client.post(`/custom_form/v2/forms/${formId}/tabs/${tabId}/fields/`, obj);
const deleteForm = (formId) =>
  client.delete(`/custom_form/v2/forms/${formId}/`);
const createTabGroup = (formId, tabId, obj) =>
  client.post(`/custom_form/v2/forms/${formId}/tabs/${tabId}/groups/`, obj);
const createTabNestedForm = (formId, tabId, obj) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/nestedfields/`,
    obj
  );
const getTabGroups = (formId, tabId) =>
  client.get(`/custom_form/v2/forms/${formId}/tabs/${tabId}/groups/`);
const getTabNestedForms = (formId, tabId) =>
  client.get(`/custom_form/v2/forms/${formId}/tabs/${tabId}/nestedfields/`);
const createField = (formId, tabId, obj) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/single_fields/`,
    obj
  );
const publishForm = (formId) =>
  client.post(`/custom_form/v2/forms/${formId}/publish/`);
const getForm = (formId) => client.get(`/custom_form/v2/forms/${formId}/`);
const duplicateForm = (formId, obj) =>
  client.post(`/custom_form/v2/forms/${formId}/duplicate/`, obj);
const deleteField = (formId, tabId, fieldId) =>
  client.delete(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/single_fields/${fieldId}/`
  );
const deleteTab = (formId, tabId) =>
  client.delete(`/custom_form/v2/forms/${formId}/tabs/${tabId}/`);
const moveDownField = (formId, tabId, fieldId) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/single_fields/${fieldId}/increment/`
  );
const moveUpField = (formId, tabId, fieldId) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/single_fields/${fieldId}/decrement/`
  );
const moveUpNestedForm = (formId, tabId, nestedId) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/nestedfields/${nestedId}/decrement/`
  );
const moveDownNestedForm = (formId, tabId, nestedId) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/nestedfields/${nestedId}/increment/`
  );
const moveUpGroup = (formId, tabId, groupId) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/groups/${groupId}/decrement/`
  );
const moveDownGroup = (formId, tabId, groupId) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/groups/${groupId}/increment/`
  );
const getTabSingleFields = (formId, tabId) =>
  client.get(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/single_fields/?pagination=false`
  );

const moveRightTab = (fomrId, tabId) =>
  client.post(`/custom_form/v2/forms/${fomrId}/tabs/${tabId}/increment/`);
const moveLeftTab = (fomrId, tabId) =>
  client.post(`/custom_form/v2/forms/${fomrId}/tabs/${tabId}/decrement/`);
const updateTab = (formId, tabId, obj) =>
  client.put(`/custom_form/v2/forms/${formId}/tabs/${tabId}/`, obj);

//entity dashboard
const getEntityDashboard = () => client.get(`/dashboard/v1/entity/`);

const requestResetEmail = (obj) =>
  client.post(`/auth/v1/request-reset-email/`, obj);

const apis = {
  login,
  changePassword,
  createEntity,
  getMyEntityProfile,
  getPractitionerList,
  getPractitionerByNid,
  createPractitioner,
  createDoctor,
  entityLogin,
  getEntityDoctors,
  getDoctor,
  getSpecialtyList,
  getPractitioner,
  getStates,
  getAreas,
  getLayerList,
  addLayer,
  getLayerTypeList,
  addLayerType,
  updateLayer,
  deleteLayer,
  requestPractitionerEnroll,
  getPractitionerTypes,
  getTeamsList,
  getTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  getTeamHeadList,
  getTeamMemberList,
  getPractitionerSecureSearch,
  sendOtpById,
  validateOtpEnrollUser,
  addLayerAssignment,
  getLayerAssignment,
  removeLayerAssignment,
  getEntitiesList,
  getEntity,
  entityApprove,
  entityReject,
  getPermissionList,
  addRolePermissions,
  removeRolePermissions,
  getGroupsList,
  createGroup,
  getRolePermissions,
  resetPermissions,
  getAdminPermissions,
  getAdminDashboardList,
  entityToggleActivitation,
  createEntityType,
  getEntityTypeList,
  getEntityType,
  updateEntityType,
  deleteEntityType,
  createLicense,
  getLicenseDashboardList,
  getMyLicenseDashboardList,
  assignLicense,
  unAssignLicense,
  getPurchaseOrderList,
  getMyPurchaseOrderList,
  addPurchaseOrder,
  approvePurchaseOrder,
  rejectPurchaseOrder,
  getNotificationUnreadCount,
  getNotificationList,
  getClinicList,
  getClinic,
  addClinicPractitioner,
  removeClinicPractitioner,
  addClinic,
  deleteClinic,
  activatePractitioner,
  deactivatePractitioner,
  updatePractitionerRole,
  getAppointmentList,
  getDoctorAvailabilityList,
  addDoctorAvailability,
  cancelAppintment,
  deleteAvailability,
  getRoleClinicPermissions,
  getAdminClinicPermissions,
  addRoleClinicPermissions,
  removeRoleClinicPermissions,
  resetClinicPermissions,
  createForm,
  getFormList,
  createTab,
  getFormTabs,
  createFields,
  deleteForm,
  getEntityDashboard,
  requestResetEmail,
  createTabGroup,
  createTabNestedForm,
  getTabGroups,
  getTabNestedForms,
  createField,
  getTabFields,
  publishForm,
  getForm,
  duplicateForm,
  deleteField,
  deleteTab,
  moveDownField,
  moveUpField,
  moveUpNestedForm,
  moveDownNestedForm,
  moveUpGroup,
  moveDownGroup,
  getTabSingleFields,
  moveRightTab,
  moveLeftTab,
  updateTab,
};

export default apis;
