import { Stack, useScrollTrigger } from "@mui/material";
import main from "api/main";
import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import DeleteIcon from "assets/icons/Delete";
import EditIcon from "assets/icons/Edit";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const EntityTypes = () => {
  const [viewMode, setViewMode] = useState("cards");
  const [currentEntityType, setCurrentEntityType] = useState();

  const navigate = useNavigate();

  //api
  const getEntityTypeList = useApi(main.getEntityTypeList);
  const updateEntityType = useApi(main.updateEntityType);
  const deleteEntityType = useApi(main.deleteEntityType);
  const getEntityType = useApi(main.getEntityType);
  const createEntityType = useApi(main.createEntityType);

  //handle navigation
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (urlParams.get("new")) {
      setViewMode("form");
    } else if (urlParams.get("id")) {
      getEntityType
        .request(urlParams.get("id"))
        .then((res) => setCurrentEntityType(res.data?.data));
      setViewMode("form");
    } else {
      setViewMode("cards");
      setCurrentEntityType(null);
    }
  }, [urlParams.get("new"), urlParams.get("id")]);

  useEffect(() => {
    getEntityTypeList.request();
  }, [createEntityType.data, updateEntityType.data]);

  const deleteHandler = async (id) => {
    const res = await deleteEntityType.request(id);
    if (res.ok) {
      getEntityTypeList.request();
    }
  };

  const fields = [
    {
      label: "Name",
      name: "name",
      validation: { required: true },
    },
    // {
    //   label: "Description",
    //   name: "description",
    // },
    {
      label: "Hospital Profile",
      name: "hospital_profile",
      type: "boolean",
    },
    {
      label: "Specialty Type",
      name: "specialty_type",
      type: "select",
      items: ["none", "single", "multi"],
      validation: { required: true },
      isEnum: true,
    },
  ];

  const cardItems = [
    { header: "Name", keyName: "data?.name" },
    // { header: "Description", keyName: "data?.description" },
    // { header: "Hospital Profile", keyName: `data?.hospital_profile ? "Yes":"No"` },
    {
      header: "Actions",
      type: "element",
      element: (card) => (
        <Stack direction="row" gap={1} alignItems="center">
          <Stack
            onClick={() => {
              navigate(`?id=${card.id}`);
            }}
          >
            <EditIcon />
          </Stack>
          <Stack
            onClick={() => {
              deleteHandler(card.id);
            }}
          >
            <DeleteIcon />
          </Stack>
        </Stack>
      ),
    },
  ];

  const handleSubmit = async (data) => {
    const obj = {
      name: data.name,
      hospital_profile: data.hospital_profile?.[0],
      specialty_type: data.specialty_type,
    };
    let res;

    if (currentEntityType) {
      res = await updateEntityType.requestWithToast(
        t("Updated successfully"),
        currentEntityType.id,
        obj
      );
    } else {
      res = await createEntityType.requestWithToast(
        t("Added successfully"),
        obj
      );
    }
    if (res.ok) {
      setCurrentEntityType(obj);
      navigate(-1);
    }
  };

  return (
    <MainContainer title={"Entity Types"}>
      <Stack gap={1}>
        <CebForm
          viewModeState={[viewMode, setViewMode]}
          cardsData={getEntityTypeList.data}
          cardItems={cardItems}
          fields={fields}
          addNewHandler={() => navigate("?new=true")}
          cancelHandler={() => navigate(-1)}
          loading={
            getEntityTypeList.loading ||
            getEntityType.loading ||
            createEntityType.loading
          }
          initialValues={{
            name: currentEntityType ? currentEntityType.name : "",
            hospital_profile: currentEntityType
              ? [currentEntityType.hospital_profile]
              : [false],
            specialty_type: currentEntityType
              ? currentEntityType.specialty_type
              : "",
          }}
          handleViewButton={(card) => navigate(`?id=${card.id}`)}
          onSubmit={handleSubmit}
          requestErrors={createEntityType.error}
        />
      </Stack>
    </MainContainer>
  );
};

export default EntityTypes;
