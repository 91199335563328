import React from "react";

export default ({ isActive }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    style={
      isActive
        ? {
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: 2,
            color: "#005AB3",
          }
        : { padding: 2, color: "#fff" }
    }
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 22H32V26H8V22ZM8 12H40V16H8V12ZM8 36H22.47V32H8V36Z"
      fill="currentColor"
    />
  </svg>
);
