import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import theme from "styles/MUITheme";
import Modal from "components/UI/Modal";

const localizer = momentLocalizer(moment);

const ScheduleCalendar = ({
  appointmentList = [],
  onChangeDateRange,
  cancelHandler,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [currentView, setCurrentView] = useState("week");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let startDate, endDate;
    if (currentView === "day") {
      startDate = moment(currentDate).startOf("day");
      endDate = moment(currentDate).endOf("day");
    } else if (currentView === "week") {
      startDate = moment(currentDate.setDate(currentDate.getDate() + 1))
        .startOf("isoWeek")
        .subtract(1, "days");
      endDate = moment(currentDate.setDate(currentDate.getDate() + 1))
        .endOf("isoWeek")
        .subtract(1, "days");
    } else if (currentView === "month") {
      startDate = moment(currentDate).startOf("month");
      endDate = moment(currentDate).endOf("month");
    }

    console.log(
      "start-end date",
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    );
    onChangeDateRange(
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    );
  }, [currentView, currentDate]);

  const createEvents = (appointments) => {
    const events = [];
    for (const obj of appointments) {
      for (const avail of obj.items) {
        const newEvents = avail.appointments?.map((item, index) => {
          if (!item.is_cancelled) {
            const startTime = moment(
              new Date(`${obj.date} ${avail.start_time}`)
            ).add(index * 30, "minutes");

            return {
              shiftId: avail.id,
              id: item.id,
              title: `${item.patient?.full_name}`,
              start: new Date(startTime.format("YYYY-MM-DD hh:mm:ss")),
              end: new Date(
                startTime.add(30, "minutes").format("YYYY-MM-DD hh:mm:ss")
              ),
              data: item,
            };
          }
        });
        events.push(...newEvents.filter((item) => item));
      }
    }
    console.log("events", events);
    return events;
  };

  useEffect(() => {
    setEvents(createEvents(appointmentList));
  }, [appointmentList]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const eventPropGetterHandler = (event, start, end, isSelected) => {
    const backgroundColor = isSelected
      ? theme.palette.primary.main
      : event.color;
    const style = {
      backgroundColor,
      color: "white",
      width: "100%", // Set the width to 100% to cover the full slot width
      fontSize: "15px",
      marginRight: 0,
    };
    return {
      style,
    };
  };

  const handleSelectEvent = (event) => {
    if (selectedEvent?.id !== event.id) setSelectedEvent(event);
    handleOpenModal();
  };

  return (
    <Stack>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "500px" }}
        eventPropGetter={eventPropGetterHandler}
        onSelectEvent={handleSelectEvent}
        views={["month", "week", "day"]}
        defaultView="week"
        step={15}
        timeslots={2}
        onNavigate={(date) => {
          setCurrentDate(date);
        }}
        onView={(view) => {
          setCurrentView(view);
        }}
      />
      <Modal open={openModal} handleOpen={handleOpenModal} minHeight={230}>
        <AppointmentsDetails
          data={selectedEvent?.data}
          cancelHandler={() => {
            cancelHandler(selectedEvent?.shiftId, selectedEvent?.id);
            handleOpenModal();
          }}
        />
      </Modal>
    </Stack>
  );
};

export default ScheduleCalendar;

const AppointmentsDetails = ({ data = {}, cancelHandler }) => {
  return (
    <Stack minWidth={700} gap={2}>
      <Typography fontWeight={700}>Appointment Details</Typography>
      <Stack
        width={"100%"}
        key={data.id}
        gap={2}
        borderBottom="0.5px solid rgba(0,0,0,0.2)"
        paddingBottom={2}
      >
        <Stack width={"100%"} direction="row">
          <Stack width="50%" direction={"row"} gap={2}>
            <Typography color="primary" fontWeight={500}>
              Name
            </Typography>
            <Typography>{data.patient?.full_name}</Typography>
          </Stack>
          <Stack width="50%" direction={"row"} gap={2}>
            <Typography color="primary" fontWeight={500}>
              Phone Number
            </Typography>
            <Typography>{data.patient?.phone_number}</Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={2}>
          <Typography color="primary" fontWeight={500}>
            Notes
          </Typography>
          <Typography>{data.notes}</Typography>
        </Stack>
      </Stack>
      {/* <Stack width={"100%"} direction="row" justifyContent={"flex-end"}>
        <ActionButton
          type={"reject"}
          style={{ width: 200 }}
          onClick={cancelHandler}
        >
          Cancel Appointment
        </ActionButton>
      </Stack> */}
    </Stack>
  );
};
