
import React from "react";

export default ({ isActive }) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44" 
    fill="none" 
    style={{
        backgroundColor: isActive&&"#fff",
        borderRadius: "50%",
        padding: 2,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
<path d="M22 21.9997H34.8333C33.8617 29.5347 28.82 36.263 22 38.353V21.9997H9.16667V11.5497L22 5.84801M22 1.83301L5.5 9.16634V20.1663C5.5 30.3413 12.54 39.838 22 42.1663C31.46 39.838 38.5 30.3413 38.5 20.1663V9.16634L22 1.83301Z" fill={ isActive ?"#003468" :"white"}/>
  </svg>
);

<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 21.9997H34.8333C33.8617 29.5347 28.82 36.263 22 38.353V21.9997H9.16667V11.5497L22 5.84801M22 1.83301L5.5 9.16634V20.1663C5.5 30.3413 12.54 39.838 22 42.1663C31.46 39.838 38.5 30.3413 38.5 20.1663V9.16634L22 1.83301Z" fill="#003468"/>
</svg>


