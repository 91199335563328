
import * as React from "react";

const SvgComponent = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.1776 8.21047C10.1213 8.27676 10.0767 8.35549 10.0462 8.44216C10.0157 8.52884 10 8.62175 10 8.71559C10 8.80942 10.0157 8.90234 10.0462 8.98901C10.0767 9.07569 10.1213 9.15442 10.1776 9.2207L12.5346 12.0006L10.1776 14.7805C10.0641 14.9145 10.0003 15.0962 10.0003 15.2857C10.0003 15.4751 10.0641 15.6568 10.1776 15.7908C10.2912 15.9247 10.4453 16 10.6059 16C10.7665 16 10.9206 15.9247 11.0342 15.7908L13.8224 12.5022C13.8787 12.4359 13.9233 12.3571 13.9538 12.2705C13.9843 12.1838 14 12.0909 14 11.997C14 11.9032 13.9843 11.8103 13.9538 11.7236C13.9233 11.6369 13.8787 11.5582 13.8224 11.4919L11.0342 8.20331C10.8033 7.93105 10.4146 7.93105 10.1776 8.21047Z" fill="black"/>
    <path d="M0.5 0.5H19C21.4853 0.5 23.5 2.51472 23.5 5V19C23.5 21.4853 21.4853 23.5 19 23.5H0.5V0.5Z" stroke="black" stroke-opacity="0.3"/>
    </svg>
    
    
);

export default SvgComponent;