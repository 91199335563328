import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  Autocomplete,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Chip,
  InputBase,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";

import Logo from "assets/icons/Logo";
import Success from "assets/icons/Success";
import { Controller, useForm, useWatch } from "react-hook-form";
import useApi from "hooks/useApi";
import main from "api/main";
import CebDateField from "components/Form/CebDateField";
import CebFileField from "components/Form/CebFileField";
import { useNavigate } from "react-router-dom";
import useYupResolver from "hooks/useYupResolver";
import getBase64 from "helpers/getBase64";
import { LoginPasswordField } from "./Login";
import { useTranslation } from "react-i18next";
import { LanguageOutlined } from "@mui/icons-material";

const fieldsValidation = [
  { name: "full_name", validation: { required: true } },
  { name: "area", validation: { required: true } },
  { name: "description", validation: { required: true } },
  { name: "email", type: "email", validation: { required: true } },
  { name: "contact_email", type: "email", validation: { required: true } },
  {
    name: "phone_number",
    type: "number",
    validation: { required: true, length: 11 },
  },
  { name: "established_date", type: "date", validation: { required: true } },
  { name: "type", validation: { required: true } },
  { name: "website" },
  {
    name: "tax_card_file",
    type: "file",
    validation: { required: true },
    acceptFiles: [
      "image/jpg",
      "image/png",
      "image/jpeg",
      ".pdf",
      "application/pdf",
    ],
  },
  {
    name: "health_license_file",
    type: "file",
    validation: { required: true },
    acceptFiles: [
      "image/jpg",
      "image/png",
      "image/jpeg",
      ".pdf",
      "application/pdf",
    ],
  },
  {
    name: "syndicate_license_file",
    type: "file",
    validation: { required: true },
    acceptFiles: [
      "image/jpg",
      "image/png",
      "image/jpeg",
      ".pdf",
      "application/pdf",
    ],
  },
  {
    name: "commercial_register_file",
    type: "file",
    validation: { required: true },
    acceptFiles: [
      "image/jpg",
      "image/png",
      "image/jpeg",
      ".pdf",
      "application/pdf",
    ],
  },
  {
    name: "specialties",
    type: "select",
    multiple: true,
    autoComplete: true,
  },
  {
    name: "password",
    validation: { required: true },
  },
  {
    name: "retype_password",
    validation: { required: true, message: "Passwords must match" },
  },
];

const SignUp = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    trigger,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: useYupResolver(fieldsValidation),
    mode: "onTouched",
  });
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [entityType, setEntityType] = useState();

  //api
  const getStates = useApi(main.getStates);
  const getAreas = useApi(main.getAreas);
  const createEntity = useApi(main.createEntity);
  const getSpecialtyList = useApi(main.getSpecialtyList);
  const getEntityTypeList = useApi(main.getEntityTypeList);

  useEffect(() => {
    getStates.request();
    getSpecialtyList.request();
    getEntityTypeList.request();
  }, []);

  const cityId = useWatch({
    control,
    name: "city",
  });

  useEffect(() => {
    if (cityId) getAreas.request(cityId);
  }, [cityId]);

  const onSubmit = async (data) => {
    if (data.password !== data.retype_password) {
      setError("retype_password", { message: "Passwords must match" });
      return;
    }
    data.phone_number = "+20" + data.phone_number;
    data.address = {
      area: data.area,
      description: data.description,
    };
    data.tax_card_file = await getBase64(data.tax_card_file[0]);
    data.health_license_file = await getBase64(data.health_license_file[0]);
    data.syndicate_license_file = await getBase64(
      data.syndicate_license_file[0]
    );
    data.commercial_register_file = await getBase64(
      data.commercial_register_file[0]
    );
    if (data.website) {
      data.website = data.website.includes("http")
        ? data.website
        : "http://" + data.website;
    }
    if (entityType === "single" && data.specialty?.id) {
      data.specialties = [data.specialty.id];
    } else if (entityType === "multi" && data.specialties?.length > 0) {
      data.specialties = data.specialties.map((item) => item.id);
    } else {
      data.specialties = [];
    }
    delete data["specialty"];

    console.log(data);
    const response = await createEntity.request(data);
    if (response.ok === true) setStep((prevStep) => prevStep + 1);
  };

  //request error handling
  useEffect(() => {
    Object.keys(createEntity.error).forEach((key) => {
      if (key === "specialties") {
        setError(
          "specialty",
          { type: "focus", message: createEntity.error[key][0] },
          { shouldFocus: true }
        );
      }
      setError(
        key,
        { type: "focus", message: createEntity.error[key][0] },
        { shouldFocus: true }
      );
    });
  }, [createEntity.error]);

  useEffect(() => {
    if (getEntityTypeList.data?.length > 0) {
      const newEntityType = getEntityTypeList.data?.find(
        (item) => item.id === watch("type")
      );
      if (newEntityType?.specialty_type === "single") {
        if (watch("specialties")?.length > 1)
          setValue("specialties", [
            { id: newEntityType.id, name: newEntityType.name },
          ]);
      }
      setEntityType(newEntityType?.specialty_type);
    }
  }, [watch("type")]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        background:
          "linear-gradient(46.59deg, #B80B14 -3.87%, #8E0201 24.23%, #003468 100%)",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "20px",
          p: "50px",
          maxWidth: "850px",
          width: "70%",
        }}
      >
        {step !== 2 && <Logo />}
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {step === 1 && (
            <>
              <SignUpField
                register={register}
                name="full_name"
                title="Name"
                placeholder={"Entity name"}
                errors={errors}
              />
              <DoubleSignUpSelectField
                control={control}
                name1="city"
                name2="area"
                title1="City"
                items1={getStates.data}
                title2="Area"
                items2={getAreas.data}
                errors={errors}
              />
              <SignUpField
                register={register}
                name="description"
                title="Address"
                placeholder={"Address"}
                errors={errors}
              />

              <SignUpField
                register={register}
                name="email"
                title="Admin Email"
                placeholder={"Email@nt.com"}
                errors={errors}
              />
              <SignUpField
                register={register}
                name="contact_email"
                title="Contact Email"
                placeholder={"Email@nt.com"}
                errors={errors}
              />
              <SignUpField
                register={register}
                name="phone_number"
                adornment
                title="Phone Number"
                placeholder={"1000000000"}
                errors={errors}
                type="number"
                setValue={setValue}
                trigger={trigger}
              />
              <SignUpSelectField
                control={control}
                name="type"
                title="Entity Type"
                items={getEntityTypeList.data}
                errors={errors}
              />

              <SignUpFileField
                register={register}
                name="tax_card_file"
                title="Tax Card"
                errors={errors}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
              />
              <SignUpFileField
                register={register}
                name="health_license_file"
                title="Health License"
                errors={errors}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
              />
              <SignUpFileField
                register={register}
                name="syndicate_license_file"
                title="Syndicate License"
                errors={errors}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
              />
              <SignUpFileField
                register={register}
                name="commercial_register_file"
                title="Commercial Register"
                errors={errors}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
              />

              {entityType === "single" && (
                <SignUpAutoComplete
                  control={control}
                  name="specialty"
                  title="Specialty"
                  items={getSpecialtyList.data}
                  errors={errors}
                  multiple={false}
                />
              )}

              {entityType === "multi" && (
                <SignUpAutoComplete
                  control={control}
                  name="specialties"
                  title="Specialties"
                  items={getSpecialtyList.data}
                  errors={errors}
                  multiple={true}
                />
              )}
              <SignUpDateField
                register={register}
                control={control}
                name="established_date"
                title="Established Date"
                errors={errors}
              />

              <SignUpField
                register={register}
                name="website"
                title="Website"
                placeholder={"www.entity.com"}
                errors={errors}
              />

              <LoginPasswordField
                title="Create Password"
                placeholder={"Enter your password"}
                name="password"
                register={register}
                errors={errors}
              />
              <LoginPasswordField
                title="Retype Password"
                placeholder={"Enter your password"}
                name="retype_password"
                register={register}
                errors={errors}
              />
            </>
          )}

          {step === 2 && (
            <>
              <Success />
              <Stack alignItems={"center"} gap={2} m={3}>
                <Typography fontSize="22px" fontWeight="500">
                  {t("Entity ID")}
                </Typography>
                <Typography fontSize="30px" color="primary" fontWeight="500">
                  {createEntity.data?.username?.substr(1)}
                </Typography>
                <Typography fontSize="22px" color="secondary" fontWeight="500">
                  {t("Please use this ID for signing in")}
                </Typography>
              </Stack>
            </>
          )}

          <Stack gap={1} mt={2} alignItems={"center"}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#003468",
                px: "20px",
                fontWeight: "600",
                fontSize: "20px",
                borderRadius: "10px",
                textTransform: "none",
              }}
              onClick={step < 2 ? handleSubmit(onSubmit) : () => navigate("/")}
            >
              {createEntity.loading ? (
                <CircularProgress color="white" />
              ) : step < 2 ? (
                t("Submit")
              ) : (
                t("Done")
              )}
            </Button>
            <IconButton
              onClick={() => {
                if (i18n.language === "en") {
                  i18n.changeLanguage("ar");
                  localStorage.setItem("lng", "ar");
                } else {
                  i18n.changeLanguage("en");
                  localStorage.setItem("lng", "en");
                }
              }}
            >
              <LanguageOutlined
                style={{ fontSize: "36px", color: "#003468" }}
              />
            </IconButton>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
};

export default SignUp;

const SignUpField = ({
  name,
  title,
  register,
  placeholder,
  errors,
  adornment = false,
  type,
  setValue,
  trigger,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid item xs={3}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Stack>
          <InputBase
            variant="outlined"
            InputProps={{
              startAdornment: adornment && (
                <InputAdornment position="start">+20</InputAdornment>
              ),
            }}
            {...register(name)}
            sx={{
              paddingX: 1.5,
              paddingY: 0.7,
              width: "100%",
              outline: `1.5px solid ${errors[name] ? "red" : "black"}`,
              borderRadius: "10px",
            }}
            size="small"
            placeholder={t(placeholder)}
            // type={type ? type : "text"}
            {...(setValue &&
              trigger &&
              type === "number" && {
                onChange: (e) => {
                  setValue(name, e.target.value.replace(/\D/g, ""));
                  if (errors[name]) trigger(name);
                },
              })}
          />
          {errors[name] && (
            <Box sx={{ mt: "4px" }}>
              <Typography variant="error">{errors[name].message}</Typography>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const SignUpDateField = ({ name, title, register, control, errors }) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid item xs={3}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <CebDateField
          control={control}
          register={register}
          name={name}
          type="date"
          validation={{ disableFuture: true }}
          errors={errors}
        />
        {errors[name] && (
          <Box sx={{ mt: "4px" }}>
            <Typography variant="error">{errors[name].message}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const DoubleSignUpSelectField = ({
  control,
  title1,
  title2,
  name1,
  name2,
  items1,
  items2,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid item xs={3}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title1)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Controller
          name={name1}
          control={control}
          defaultValue={""}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <Select
              sx={{
                width: "100%",
                outline: `1.5px solid ${errors[name1] ? "red" : "black"}`,
                borderRadius: "10px",
                height: 43,
              }}
              value={value}
              onChange={(value) => onChange(value)}
            >
              {items1?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title2)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Controller
          name={name2}
          control={control}
          defaultValue={""}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <Select
              sx={{
                width: "100%",
                outline: `1.5px solid ${errors[name2] ? "red" : "black"}`,
                borderRadius: "10px",
                height: 43,
              }}
              value={value}
              onChange={(value) => onChange(value)}
            >
              {items2?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
    </Grid>
  );
};

const SignUpSelectField = ({ control, title, name, items, errors }) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid item xs={3}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Controller
          name={name}
          control={control}
          defaultValue={""}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <Select
              sx={{
                width: "100%",
                outline: `1.5px solid ${errors[name] ? "red" : "black"}`,
                borderRadius: "10px",
                height: 43,
              }}
              value={value}
              onChange={(value) => onChange(value)}
            >
              {items?.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors[name] && (
          <Box sx={{ mt: "4px" }}>
            <Typography variant="error">{errors[name].message}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const SignUpFileField = ({
  name,
  title,
  register,
  errors,
  setValue,
  watch,
  trigger,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid item xs={3}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack>
          <CebFileField
            setValue={setValue}
            watch={watch}
            register={register}
            name={name}
            trigger={trigger}
          />
          {errors[name] && (
            <Box sx={{ mt: "4px" }}>
              <Typography variant="error">{errors[name].message}</Typography>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: "500!important",
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const SignUpAutoComplete = ({
  title,
  name,
  control,
  items,
  errors,
  multiple,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selected, setSelected] = useState([]); // selected options in multiple select field
  const isAllSelected = items.length > 0 && selected.length === items.length;

  const optionRenderer = (props, option, state) => {
    if (option.id === "all")
      return (
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
          {...props}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < items.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
      );

    return (
      <MenuItem
        key={option?.id ? option.id : option /*enum*/}
        value={option?.id ? option.id : option}
        {...props}
      >
        <ListItemIcon>
          <Checkbox checked={state.selected} />
        </ListItemIcon>
        <ListItemText primary={option.name ? option.name : option} />
      </MenuItem>
    );
  };

  console.log("multipleeSelect", selected);

  return (
    <Grid container alignItems="center" my="20px">
      <Grid item xs={3}>
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              //disabled={disabled}
              multiple={multiple}
              options={
                multiple ? [{ id: "all", name: "Select All" }, ...items] : items
              }
              sx={{
                borderRadius: 2.5,
                outline: `1.5px solid ${errors[name] ? "red" : "black"}`,
              }}
              value={value}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                let valueArr =
                  typeof newValue === "string" ? newValue.split(",") : newValue;
                if (multiple) {
                  if (valueArr[valueArr.length - 1]?.id === "all") {
                    setSelected(selected.length === items.length ? [] : items);
                    valueArr = selected.length === items.length ? [] : items;
                  } else setSelected(valueArr);
                }
                onChange(valueArr);

                // else if (valueArr.length > 1) {
                //   console.log("multiple>1", [valueArr[0]]);
                //   const singleValue = valueArr[0];
                //   setSelected([singleValue]);
                //   onChange([singleValue]);
                // } else {
                //   console.log("multiple>0", valueArr);
                //   setSelected(valueArr);
                //   onChange(valueArr);
                // }
              }}
              //onInputChange={(event, newValue) => onInputChange(newValue)}
              renderInput={(params) => <TextField {...params} />}
              renderOption={optionRenderer}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              renderTags={(value, getTagProps) => {
                const numTags = value.length;
                const limitTags = 10;

                return (
                  <>
                    {value.slice(0, limitTags).map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        label={option.name ? option.name : option}
                      />
                    ))}

                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              {...props}
            />
          )}
        />
        {errors[name] && (
          <Box sx={{ mt: "4px" }}>
            <Typography variant="error">{errors[name].message}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
