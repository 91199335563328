import {
  Button,
  Grid,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MainContainer from "components/Entity/MainContainer";
import api from "../api/main";
import CebForm from "components/Form/CebForm";

import useApi from "hooks/useApi";
import StyledButton from "components/UI/StyledButton";
import Modal from "components/UI/Modal";
import theme from "styles/MUITheme";

const styles = {
  textArea: {
    borderRadius: 10,
    // textAlign: 'start!important',
    border: `1px solid #00172F`,
    padding: 10,
    fontFamily: `'Ubuntu', sans-serif`,
    fontSize: 16,
    marginBottom: 30,
    "&:hover": {
      border: `1px solid #003468`,
    },
    "&.Mui-focused": {
      border: `1px solid #003468`,
    },
    width: 700,
  },
};

const Entities = () => {
  const navigate = useNavigate();
  //   const [watchedFields, setWatchedFields] = useState([]);
  const [viewModeState, setViewMode] = useState();
  const [chosenCardId, setChosenCardId] = useState(0);
  const [listData, setListData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [denyReason, setDenyReason] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  //   const [resetForm, setResetForm] = useState();

  const getEntitiesList = useApi(api.getEntitiesList);
  const getEntity = useApi(api.getEntity);
  const entityApprove = useApi(api.entityApprove);
  const entityReject = useApi(api.entityReject);
  const entityToggleActivitation = useApi(api.entityToggleActivitation);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const handleEntityApprove = async () => {
    let res = await entityApprove.requestWithToast(
      "Entity approved successfully",
      chosenCardId
    );
    if (res?.ok) {
      getEntity.request(chosenCardId);
    }
  };

  //handle navigation
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const entityId = urlParams.get("id");
    if (entityId) {
      setViewMode("list");
      getEntity.request(entityId);
      setChosenCardId(entityId);
    } else {
      setViewMode("cards");
    }
  }, [urlParams.get("id")]);

  const handleEntitytoggleActivation = async (isActive) => {
    let res = await entityToggleActivitation.requestWithToast(
      isActive ? "Suspended successfully" : "Activated successfully",
      chosenCardId
    );
    if (res?.ok) {
      getEntity.request(chosenCardId);
    }
  };

  const cardItems = [
    { header: "Name", keyName: "data?.full_name" },
    {
      header: "Established Date",
      keyName: "data?.established_date",
      type: "formattedDate",
      format: "YYYY/MM/DD",
    },
    { header: "EID", keyName: "data.username?.substring(1)" },
    {
      header: "Status",
      keyName: `data.creation_status === "Pending" ? "Pending" : data.is_active ? "Active" : "Suspended"`,
      type: "status",
      statusColor: `data.creation_status === "Pending" ? "#8B8000" : data.is_active ? "green" : "secondary.main" `,
    },
  ];

  const data = [
    {
      fieldType: "header",
      title: "Institution Profile",
      actionBox:
        getEntity.data?.creation_status !== "Approved" ? (
          <Stack gap={2} direction="row">
            <Button
              variant="outlined"
              color="success"
              sx={{
                textTransform: "capitalize",
                ":hover": { backgroundColor: "green", color: "#fff" },
              }}
              style={{ height: 46, width: 146, borderRadius: 10 }}
              onClick={handleEntityApprove}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                textTransform: "capitalize",
                ":hover": {
                  backgroundColor: theme.palette.secondary.main,
                  color: "#fff",
                },
              }}
              style={{ height: 46, width: 146, borderRadius: 10 }}
              onClick={handleOpenModal}
            >
              Reject
            </Button>
          </Stack>
        ) : (
          <Stack gap={2} direction="row">
            <Button
              variant="outlined"
              color={getEntity.data?.is_active ? "secondary" : "success"}
              sx={{
                textTransform: "capitalize",
                ":hover": {
                  backgroundColor: getEntity.data?.is_active
                    ? theme.palette.secondary.main
                    : "green",
                  color: "#fff",
                },
              }}
              style={{ height: 46, width: 146, borderRadius: 10 }}
              onClick={() =>
                handleEntitytoggleActivation(getEntity.data?.is_active)
              }
            >
              {getEntity.data?.is_active ? "Suspend" : "Activate"}
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                ":hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                },
              }}
              style={{ height: 46, width: 200, borderRadius: 10 }}
              onClick={() => {
                navigate(
                  `/superadmin/license?id=${getEntity.data?.id}&name=${getEntity.data?.full_name}`
                );
              }}
            >
              Manage License
            </Button>
          </Stack>
        ),
    },
    {
      label: "Name",
      data: getEntity.data?.full_name,
      cardItem: true,
    },
    {
      label: "Email",
      data: getEntity.data?.email,
    },
    {
      label: "Specialities",
      data: getEntity.data?.specialties?.map((item) => item?.name),
    },
    {
      label: "Website",
      data: getEntity.data?.website,
    },
    {
      label: "EID",
      data: getEntity.data?.username,
      cardItem: true,
    },
    {
      label: "Phone Number",
      data: getEntity.data?.phone_number,
    },
    {
      label: "Established Date",
      data: getEntity.data?.established_date,
      cardItem: true,
    },
    {
      label: "Entity Type",
      data: getEntity.data?.type,
    },
    {
      fieldType: "link",
      label: "Tax Card",
      data: {
        file: getEntity.data?.tax_card_file,
        file_name: "view",
      },
    },
    {
      fieldType: "link",
      label: "Health License",
      data: {
        file: getEntity.data?.health_license_file,
        file_name: "view",
      },
    },
    {
      fieldType: "link",
      label: "Syndicate License",
      data: {
        file: getEntity.data?.syndicate_license_file,
        file_name: "view",
      },
    },
    {
      fieldType: "link",
      label: "Commercial Register",
      data: {
        file: getEntity.data?.commercial_register_file,
        file_name: "view",
      },
    },
    {
      label: "Address",
      data: `${getEntity.data?.address?.description} - ${getEntity.data?.address?.area} - ${getEntity.data?.address?.state}`,
    },
  ].filter((item) => item);

  useEffect(() => {
    if (viewModeState === "cards") getEntitiesList.request(pageNumber);
  }, [viewModeState, pageNumber]);

  const handleViewButton = async (card) => {
    navigate(`?id=${card.id}`);
  };

  useEffect(() => {
    setListData(data);
  }, [getEntity.data]);

  const denyEntity = async () => {
    const res = await entityReject.requestWithToast(
      "Entity denied successfully",
      chosenCardId,
      { reason: denyReason }
    );
    if (res?.ok) {
      handleOpenModal();
      navigate(-1);
    }

    setDenyReason("");
  };

  return (
    <>
      <MainContainer title={"Institutions"}>
        <Stack gap={1}>
          <CebForm
            listWidth="100%"
            fields={[]}
            cardItems={cardItems}
            cardsData={
              viewModeState === "cards"
                ? getEntitiesList.data
                : [getEntity.data]
            }
            //   onSubmit={onSubmit}
            viewModeState={[viewModeState, setViewMode]}
            //   fieldsToWatch={{ name: ["team_head", "role", "members"], defaultValue: [""] }}
            //   getWatchedFields={setWatchedFields}
            handleViewButton={handleViewButton}
            listData={listData}
            //   resetState={[resetForm, setResetForm]}
            loading={getEntitiesList.loading || getEntity.loading}
            chosenCardId={chosenCardId}
            //   requestErrors={createTeam.error}
            backHandler={() => navigate(-1)}
            totalCount={getEntitiesList.count}
            paginationState={[pageNumber, setPageNumber]}
          />
        </Stack>
      </MainContainer>

      <Modal open={openModal} handleOpen={handleOpenModal}>
        <Stack
          alignItems={"center"}
          justifyContent="center"
          gap={8}
          sx={{ pt: 4 }}
        >
          <Typography
            color={"primary"}
            sx={{ fontWeight: 700, fontSize: "30px" }}
          >
            Add Rejection Reason
          </Typography>
          <TextareaAutosize
            minRows={4}
            style={styles.textArea}
            aria-label="maximum height"
            // onKeyDown={onKeyDown}
            name="reason"
            value={denyReason}
            onChange={(event) => setDenyReason(event.target.value)}
          />
          <StyledButton onClick={denyEntity}>Send</StyledButton>
        </Stack>
      </Modal>
    </>
  );
};

export default Entities;
