import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import {
  Box,
  TextField,
  Typography,
  Stack,
  IconButton,
  Collapse,
  Button,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";

import MainContainer from "components/Entity/MainContainer";
import StyledNode from "components/TreeNode/TreeNode";
import CebSelectField from "components/Form/CebSelectField";
import CebTextField from "components/Form/CebTextField";
import useApi from "hooks/useApi";
import main from "api/main";
import CustomAddButton from "components/UI/CustomAddButton";
import Modal from "components/UI/Modal";
import StyledButton from "components/UI/StyledButton";
import LoadingScreen from "components/UI/LoadingScreen";
import { Delete } from "@mui/icons-material";
import StyledTextField from "components/UI/StyledTextField";
import authService from "auth/authService";
import Assigning from "./Assigning";
import CebBooleanField from "components/Form/CebBooleanField";
import { useTranslation } from "react-i18next";

const Setup = () => {
  const { t, i18n } = useTranslation();
  const [levels, setLevels] = useState([]);
  const [openTypeModal, setOpenTypeModal] = useState(false);
  // const [edit, setEdit] = useState(false);
  const [updatedLevels, setUpdatedLevels] = useState([]);
  const [currentLevel, setCurrentLevel] = useState();
  // const [nodePostition, setNodePosition] = useState({});
  // const [dragHoverEffect, setDragHoverEffect] = useState({});

  const naviagte = useNavigate();

  const { register, control, handleSubmit } = useForm();
  const { addToast } = useToasts();
  const entityName = authService.getCurrentUser().full_name;

  const useFormModal = useForm();

  //api
  const getLayerTypeList = useApi(main.getLayerTypeList);
  const addLayerType = useApi(main.addLayerType);
  const addLayer = useApi(main.addLayer);
  const getLayerList = useApi(main.getLayerList);
  const updateLayer = useApi(main.updateLayer);
  const deleteLayer = useApi(main.deleteLayer);

  //url params
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (urlParams.get("id")) {
      setCurrentLevel({ id: urlParams.get("id"), name: urlParams.get("name") });
    } else {
      setCurrentLevel(null);
    }
  }, [urlParams.get("id")]);

  useEffect(() => {
    getLayerTypeList.request();
  }, [addLayerType.data]);

  useEffect(() => {
    getLayerList.request();
  }, [addLayer.data, updateLayer.data, deleteLayer.data]);

  useEffect(() => {
    setLevels(
      [
        {
          id: "root",
          name: entityName,
          parent_id: "none",
        },
        ...getLayerList.data.map((item) => ({
          id: item.id,
          name: item.name,
          parent_id: item.parent ? item.parent : "root",
          isService: item.layer_type?.is_service,
          type: item.layer_type?.name,
          createdAt: new Date(item.created_at),
        })),
      ].sort((a, b) => a.createdAt - b.createdAt)
    );
  }, [getLayerList.data]);

  const onClickLayerHandler = (id, name) => {
    // setCurrentLevel({ id, name });
    naviagte(`?id=${id}&name=${name}`);
  };

  // const dragHandler = async (stopPosition, type, id) => {
  //   let isDragged = false;
  //   for (const key in nodePostition) {
  //     if (key !== id) {
  //       if (
  //         Math.abs(nodePostition[key].x - stopPosition.x) < 15 &&
  //         Math.abs(nodePostition[key].y - stopPosition.y) < 15
  //       ) {
  //         if (type === "drag") {
  //           setDragHoverEffect({ [key]: true });
  //         } else {
  //           await updateLayer.requestWithToast("Updated successfully", id, {
  //             parent: key === "root" ? null : key,
  //           });
  //           isDragged = true;
  //         }
  //         break;
  //       }
  //     }
  //   }
  //   if (!isDragged && type === "stop") {
  //     await getLayerList.request();
  //     setDragHoverEffect({});
  //   }
  // };

  const createNode = (node, layer) => {
    const children = levels.filter((l) => l.parent_id === node.id);
    if (children.length < 1) {
      return (
        <TreeNode
          label={
            <StyledNode
              name={node.name}
              onClick={onClickLayerHandler}
              id={node.id}
              isService={node.isService}
              type={node.type}
              layer={layer}
              // onChangePosition={(position) =>
              //   setNodePosition((state) => ({ ...state, [node.id]: position }))
              // }
              // dragHandler={dragHandler}
              // dragHover={dragHoverEffect[node.id]}
            />
          }
        />
      );
    } else {
      return (
        <TreeNode
          label={
            <StyledNode
              name={node.name}
              onClick={onClickLayerHandler}
              id={node.id}
              isService={node.isService}
              type={node.type}
              layer={layer}
              // onChangePosition={(position) =>
              //   setNodePosition((state) => ({ ...state, [node.id]: position }))
              // }
              // dragHandler={dragHandler}
              // dragHover={dragHoverEffect[node.id]}
            />
          }
        >
          {children.map((child) => createNode(child, layer + 1))}
        </TreeNode>
      );
    }
  };

  const onSubmit = (data) => {
    console.log("data", data);
    if (data.name && data.layer_type) {
      addLayer.requestWithToast(t("Added successfully"), {
        ...data,
        parent: data.parent === "root" ? null : data.parent,
      });
    }
  };

  const handleOpenTypeModal = () => {
    setOpenTypeModal((s) => !s);
  };

  const onSubmitModal = async (data) => {
    if (!data.name) return;
    const res = await addLayerType.requestWithToast(t("Added successfully"), {
      name: data.name,
      is_service: data.is_service[0],
    });
    if (res.ok) {
      useFormModal.setValue("name", "");
      useFormModal.setValue("is_service", [false]);
    }
  };

  //Edit levels
  const changeNameHandler = (name, id) => {
    const newLevels = levels.map((item) => {
      return item.id === id ? { ...item, name } : item;
    });
    setLevels(newLevels);
    if (!updatedLevels.find((item) => item === id)) {
      setUpdatedLevels((state) => [...state, id]);
    }
  };

  const changeParentHandler = (parent_id, id) => {
    const newLevels = levels.map((item) => {
      return item.id === id ? { ...item, parent_id } : item;
    });
    if (newLevels.find((l) => l.parent_id === "root")) {
      setLevels(newLevels);
      if (!updatedLevels.find((item) => item === id)) {
        setUpdatedLevels((state) => [...state, id]);
      }
    } else {
      addToast(`There must be at least one child of ${entityName}`, {
        appearance: "error",
        autoDismiss: true,
      });
      document.getElementById(`parent_${id}`).value = "root";
    }
  };

  const onSaveHandler = async () => {
    let res;
    for (const levelId of updatedLevels) {
      const level = levels.find((l) => l.id === levelId);
      res = await updateLayer.request(level.id, {
        name: level.name,
        parent: level.parent_id === "root" ? null : level.parent_id,
      });
    }
    if (res?.ok) {
      addToast(t("Updated successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast("Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const onDeleteHandler = async (id) => {
    await deleteLayer.requestWithToast(t("Deleted successfully"), id);
  };

  if (getLayerList.loading || addLayer.loading || updateLayer.loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {!currentLevel && (
        <MainContainer title="Medical Entity Hierarchy">
          <Modal open={openTypeModal} handleOpen={handleOpenTypeModal}>
            <div dir={i18n.language === "en" ? "lft" : "rtl"}>
              <Stack alignItems={"center"} gap={5} sx={{ pt: 3 }}>
                <Typography
                  color={"primary"}
                  sx={{ fontWeight: 700, fontSize: "30px" }}
                >
                  {t("Add New Type")}
                </Typography>

                <Stack alignItems="center">
                  <Stack gap={3} mb={6}>
                    <Stack direction="row" alignItems={"center"} gap={4}>
                      <Typography variant="body2" color="primary">
                        {t("Name")}
                      </Typography>
                      <CebTextField
                        width={300}
                        register={useFormModal.register}
                        name="name"
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      gap={4}
                      sx={{ mb: "5rem" }}
                    >
                      <Typography variant="body2" color="primary">
                        {t("Service")}
                      </Typography>
                      <CebBooleanField
                        control={useFormModal.control}
                        register={useFormModal.register}
                        name="is_service"
                      />
                    </Stack>
                  </Stack>

                  <StyledButton
                    onClick={useFormModal.handleSubmit(onSubmitModal)}
                  >
                    {t("Next")}
                  </StyledButton>
                </Stack>
              </Stack>
            </div>
          </Modal>
          <Stack direction="row" gap={2} width="100%">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                gap={3}
                sx={{
                  border: "1px solid rgba(0,0,0,0.3)",
                  borderRadius: "20px",
                  padding: 3,
                  width: "480px",
                }}
              >
                <Grid container alignItems={"center"}>
                  <Grid item xs={4}>
                    <Typography fontWeight={500}>{t("Unit Name")}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <CebTextField width={230} register={register} name="name" />
                  </Grid>
                </Grid>

                <Grid container alignItems={"center"}>
                  <Grid item xs={4}>
                    <Typography fontWeight={500}>{t("Parent")}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <CebSelectField
                      register={register}
                      width={230}
                      items={levels}
                      idName="id"
                      name="parent"
                      control={control}
                    />
                  </Grid>
                </Grid>

                <Grid container alignItems={"center"}>
                  <Grid item xs={4}>
                    <Typography fontWeight={500}>{t("Type")}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <CebSelectField
                        items={getLayerTypeList.data}
                        register={register}
                        control={control}
                        name="layer_type"
                        idName="id"
                        width={230}
                      />
                      <CustomAddButton onClick={handleOpenTypeModal} />
                    </Stack>
                  </Grid>
                </Grid>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  marginTop={4}
                  width="90%"
                >
                  <StyledButton
                    type="submit"
                    width={"100px"}
                    height="40px"
                    fontSize={"16px"}
                  >
                    {t("Add")}
                  </StyledButton>
                </Stack>
              </Stack>
            </form>

            {levels.length > 1 && (
              <Stack
                sx={{
                  border: "1px solid rgba(0,0,0,0.3)",
                  padding: 3,
                  borderRadius: "20px",
                  maxHeight: "284px",
                  overflow: "auto",
                }}
                gap={1}
              >
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography fontWeight={500}>{t("Edit Levels")}</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      width: "80px",
                      height: "40px",
                    }}
                    onClick={onSaveHandler}
                  >
                    {t("Save")}
                  </Button>
                </Stack>

                {levels.map((item, index) => (
                  <>
                    {item.id !== "root" && (
                      <Stack
                        key={item.id}
                        direction={"row"}
                        gap={2}
                        alignItems="center"
                      >
                        <StyledTextField
                          name="Level"
                          onChange={(e) =>
                            changeNameHandler(e.target.value, item.id)
                          }
                          defaultValue={item.name}
                        />
                        <IconButton onClick={() => onDeleteHandler(item.id)}>
                          <Delete sx={{ width: "30px", height: "30px" }} />
                        </IconButton>
                        <Typography>{t("Parent")}</Typography>
                        <StyledTextField
                          name="Parent"
                          id={`parent_${item.id}`}
                          type="select"
                          options={levels.filter(
                            (level) => level.id !== item.id
                          )}
                          onChange={(e) =>
                            changeParentHandler(e.target.value, item.id)
                          }
                          defaultValue={item.parent_id}
                        />
                      </Stack>
                    )}
                  </>
                ))}
                <Stack
                  justifyContent="flex-end"
                  direction="row"
                  mr={1}
                  mt={2}
                ></Stack>
              </Stack>
            )}
          </Stack>

          {levels.length > 1 && (
            <Stack
              sx={{
                p: 4,
                width: "100%",
                maxWidth: window.innerWidth - 380,
                overflow: "auto",
                direction: "ltr",
              }}
            >
              <Tree
                label={t("Hierarchy")}
                lineWidth={"2px"}
                lineColor={"#B3B3B3"}
                lineBorderRadius={"10px"}
              >
                {createNode({ id: "root", name: entityName }, 0)}
              </Tree>
            </Stack>
          )}
        </MainContainer>
      )}
      {currentLevel && (
        <Assigning level={currentLevel} cancelHandler={() => naviagte(-1)} />
      )}
    </>
  );
};

export default Setup;
