import { Stack } from "@mui/material";
import main from "api/main";
import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Permissions from "./Permissions";
import { t } from "i18next";

const Role = ({ superadmin = false }) => {
  const [viewMode, setViewMode] = useState();
  const [currentRole, setCurrentRole] = useState();

  const naviagte = useNavigate();

  //api
  const getPractitionerTypesList = useApi(main.getGroupsList);
  const createGroup = useApi(main.createGroup);

  //handle navigation
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const roleId = urlParams.get("role_id");
    const roleName = urlParams.get("role_name");
    if (urlParams.get("new")) {
      setViewMode("form");
    } else if (roleId) {
      setCurrentRole({ id: roleId, name: roleName });
      setViewMode("permissions");
    } else {
      setViewMode("cards");
    }
  }, [urlParams.get("role_id"), urlParams.get("new")]);

  const cardItems = [{ header: "Role", keyName: "data?.name" }];

  const fields = [
    {
      label: "Role",
      name: "name",
      validation: { required: true },
    },
    {
      label: "Doctor",
      name: "is_doctor_profile",
      type: "boolean",
    },
  ];

  useEffect(() => {
    getPractitionerTypesList.request();
  }, [createGroup.data]);

  const handleViewButton = (card) => {
    naviagte(`?role_id=${card.id}&role_name=${card.name}`);
  };

  const onSubmit = async (data) => {
    let res = await createGroup.requestWithToast(t("Added successfully"), {
      ...data,
      is_doctor_profile: data.is_doctor_profile?.[0],
    });
    if (res?.ok) {
      naviagte(-1);
    }
  };

  return (
    <MainContainer title={"Roles & Permissions"}>
      <Stack gap={1}>
        {(viewMode === "cards" || viewMode === "form") && (
          <CebForm
            fields={superadmin ? fields : []}
            cardItems={cardItems}
            cardsData={getPractitionerTypesList.data}
            viewModeState={[viewMode, setViewMode]}
            handleViewButton={handleViewButton}
            onSubmit={onSubmit}
            loading={getPractitionerTypesList.loading}
            addNewHandler={() => naviagte(`?new=true`)}
            cancelHandler={() => naviagte(-1)}
          />
        )}
        {viewMode === "permissions" && (
          <Permissions role={currentRole} backHandler={() => naviagte(-1)} />
        )}
      </Stack>
    </MainContainer>
  );
};

export default Role;
