import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

import Success from "../../assets/images/Success.png";
import CebTextField from "../Form/CebTextField";
import { useForm } from "react-hook-form";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import useYupResolver from "../../hooks/useYupResolver";
import CebSelectField from "components/Form/CebSelectField";
import Timer from "components/Timer";
import { t } from "i18next";

const OtpForm = ({
  userId,
  handleOpenModal,
  enrollExistingPatient = false,
  enrollPractitioner = false,
}) => {
  const [resetTimerTrigger, setResetTimerTrigger] = useState(false);
  const [startTimer, setStartTimer] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: useYupResolver(
      [
        {
          name: "otp",
          validation: {
            required: true,
            length: 4,
            message: "Must be 4 digits",
          },
        },
        enrollPractitioner && {
          name: "type",
          validation: { required: true },
        },
      ].filter((item) => item)
    ),
    mode: "onTouched",
  });
  // const { addToast } = useToasts();

  const [pk, setPk] = useState();

  //api
  const validateOtpEnrollUser = useApi(
    enrollExistingPatient
      ? mainApi.entityEnrollPatient
      : enrollPractitioner
      ? mainApi.requestPractitionerEnroll
      : mainApi.validateOtpEnrollUser
  );

  const getPractitionerTypes = useApi(mainApi.getPractitionerTypes);

  useEffect(() => {
    if (enrollPractitioner) {
      getPractitionerTypes.request();
    }
  }, [enrollPractitioner]);

  const onSubmit = async (data) => {
    let obj = {};
    obj[enrollExistingPatient || enrollPractitioner ? "token_id" : "pk"] = pk;
    obj["otp"] = data.otp;
    if (enrollPractitioner) {
      obj["type"] = data.type;
    }
    const res = await validateOtpEnrollUser.requestWithToast(
      `${
        enrollPractitioner
          ? t("Practitioner has been enrolled successfully")
          : t("Patient has been enrolled successfully")
      }`,
      obj
    );
    if (res?.ok) {
      handleOpenModal();
    }
    // else {
    //   addToast("Wrong OTP", { appearance: "error", autoDismiss: true });
    // }
  };

  const sendOtp = async () => {
    try {
      const res = await mainApi.sendOtpById({
        by_phone: true,
        user_id: userId,
      });

      setPk(res.data.data.pk);
      setResetTimerTrigger((old) => !old);
      if (!startTimer) setStartTimer(true);

      console.log("OTP", res.data.data.debug);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (userId) {
      sendOtp();
    }
  }, [userId]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            borderBottom: "2px solid #D9D9D9",
            pb: 2,
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: 25 }} color="primary">
            Enter OTP
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              width: "145px",
              height: "46px",
            }}
            type="submit"
          >
            Next
          </Button>
        </Stack>

        <Stack justifyContent="center" alignItems="center" gap={3}>
          <img src={Success} width="270px" height="203px" />
          <Typography
            sx={{ fontSize: "25px", fontWeight: 400, textAlign: "center" }}
          >
            OTP has been sent to the practitioner’s registered email
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography width={50}>OTP</Typography>
            <CebTextField
              register={register}
              width="570px"
              height="48px"
              name="otp"
            />
          </Stack>
          {errors?.otp && (
            <Typography variant="error">{errors?.otp?.message}</Typography>
          )}
          {enrollPractitioner && (
            <Stack direction="row" gap={1} alignItems="center">
              <Typography width={50}>Role</Typography>
              <CebSelectField
                register={register}
                control={control}
                items={getPractitionerTypes.data}
                idName="id"
                width="570px"
                name="type"
              />
            </Stack>
          )}

          {errors?.type && (
            <Typography variant="error">{errors?.type?.message}</Typography>
          )}
          {startTimer && <Timer resetTrigger={resetTimerTrigger} />}
          <Typography sx={{ fontSize: 20, fontWeight: 400 }}>
            Didn’t recieve OTP?
          </Typography>
          <Button onClick={sendOtp}>Resend Now</Button>
        </Stack>
      </form>
    </Box>
  );
};

export default OtpForm;
