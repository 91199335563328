import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "components/UI/Modal";
import { useForm } from "react-hook-form";
import ThirdNavTabs from "components/Navigation/ThirdNavTabs";
import CustomForm, { StatusLabel } from "components/CustomForm";
import CustomAddButton from "components/UI/CustomAddButton";
import MainContainer from "components/Entity/MainContainer";
import CebForm from "components/Form/CebForm";
import PatientCard, { PatientCardTitles } from "components/patient/PatientCard";
import CopyIcon from "assets/icons/Copy";
import DeleteIcon from "assets/icons/Delete";
import useApi from "hooks/useApi";
import main from "api/main";
import {
  ArrowLeft,
  ArrowRight,
  DeleteOutlined,
  Edit,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CustomFormPage = () => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("cards");
  const [currentForm, setCurrentForm] = useState();

  //url params
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const formId = urlParams.get("id");
    const formTitle = urlParams.get("title");
    if (formId) {
      setCurrentForm({ id: formId, title: formTitle });
      setViewMode(urlParams.get("new") ? "new" : "detail");
    } else {
      setViewMode("cards");
    }
  }, [urlParams.get("id")]);

  return (
    <MainContainer
      title={
        viewMode === "new" || viewMode === "detail"
          ? currentForm?.title
          : "Forms"
      }
    >
      {/* form list */}
      {viewMode === "cards" && <CardsView />}

      {/* create/view/edit form */}
      {viewMode !== "cards" && (
        <FormTabs
          formId={currentForm.id}
          backHandler={() => {
            navigate("/superadmin/custom-form");
          }}
          newForm={viewMode === "new"}
        />
      )}
    </MainContainer>
  );
};

export default CustomFormPage;

const CardsView = () => {
  const [openModal, setOpenModal] = useState(false);
  const [duplicatedFormId, setDuplicatedFormId] = useState();
  const [viewMode, setViewMode] = useState("cards");
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  //api
  const createForm = useApi(main.createForm);
  const getFormList = useApi(main.getFormList);
  const deleteForm = useApi(main.deleteForm);
  const duplicateForm = useApi(main.duplicateForm);

  useEffect(() => {
    getFormList.request(pageNumber);
  }, [deleteForm.data, duplicateForm.data, pageNumber]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const cardItems = [
    { header: "Name", keyName: "data.form_title" },
    {
      header: "Creation Date",
      keyName: "data.created_at",
      type: "formattedDate",
      format: "DD-MM-YYYY",
    },
    {
      header: "Specialty",
      keyName: "data.specialties",
      type: "accordion",
    },
    {
      header: "Status",
      keyName: `data.status.toUpperCase()[0] + data.status.substr(1)`,
      type: "status",
      statusColor: `data.status === "published" ? "green" : data.status === "draft" ?  "primary.main" : "rgba(139, 128, 0, 1)" `,
    },
    {
      header: "Edit",
      type: "element",
      element: (card) => (
        <Stack direction="row" gap={1} alignItems="center">
          <Stack
            onClick={() => {
              setDuplicatedFormId(card.id);
              handleOpenModal();
            }}
          >
            <CopyIcon />
          </Stack>
          <Stack onClick={() => handleDeleteForm(card.id)}>
            <DeleteIcon />
          </Stack>
        </Stack>
      ),
    },
  ];

  const handleDeleteForm = (cardId) => {
    if (window.confirm("Are you sure you want to delete this form?"))
      deleteForm.requestWithToast("Deleted successfully", cardId);
  };

  const handleNewForm = (newForm) => {
    navigate(`?id=${newForm.id}&title=${newForm.form_title}&new=true`);
  };

  const handleDuplicateForm = async (formId, obj) => {
    return await duplicateForm.requestWithToast(
      "Duplicated successfully",
      formId,
      obj
    );
  };

  const handleViewForm = (card) => {
    navigate(`?id=${card.id}&title=${card.form_title}`);
  };

  const onSubmitFormHandler = async (data) => {
    const obj = {
      form_title: data.form_title,
      specialties: data.specialties,
    };
    if (duplicatedFormId) {
      const res = await handleDuplicateForm(duplicatedFormId, obj);
      if (res.ok) {
        handleOpenModal();
        setDuplicatedFormId(null);
      }
    } else {
      const res = await createForm.requestWithToast("Added successfully", obj);
      if (res.ok) {
        setPageNumber(1);
        handleNewForm(res?.data?.data);
        handleOpenModal();
      }
    }
  };

  return (
    <>
      <AddNewModal
        title={duplicatedFormId ? "Duplicate Form" : "Add New Form"}
        openModal={openModal}
        handleOpen={handleOpenModal}
        onSubmit={onSubmitFormHandler}
        type="form"
      />
      <Stack gap={2}>
        <Button
          variant="outlined"
          onClick={handleOpenModal}
          style={{
            width: 145,
            height: 48,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
          }}
        >
          Add New Form
        </Button>

        <CebForm
          cardsData={getFormList.data}
          viewModeState={[viewMode, setViewMode]}
          cardItems={cardItems}
          loading={getFormList.loading}
          totalCount={getFormList.count}
          paginationState={[pageNumber, setPageNumber]}
          handleViewButton={handleViewForm}
        />
      </Stack>
    </>
  );
};

const FormTabs = ({ formId, backHandler }) => {
  const [openModal, setOpenModal] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [editTabId, setEditTabId] = useState();

  const { reset } = useForm();
  //api
  const createTab = useApi(main.createTab);
  const getFormTabs = useApi(main.getFormTabs);
  const publishForm = useApi(main.publishForm);
  const getForm = useApi(main.getForm);
  const deleteTab = useApi(main.deleteTab);
  const moveLeftTab = useApi(main.moveLeftTab);
  const moveRightTab = useApi(main.moveRightTab);
  const updateTab = useApi(main.updateTab);

  const deleteTabHandler = (tabId) => {
    if (window.confirm("Are you sure you want to delete this tab?"))
      deleteTab.requestWithToast("Deleted successfully", formId, tabId);
  };

  useEffect(() => {
    getFormTabs.request(formId);
  }, [
    formId,
    createTab.data,
    publishForm.data,
    deleteTab.data,
    moveLeftTab.data,
    moveRightTab.data,
    updateTab.data,
  ]);

  useEffect(() => {
    if (getFormTabs.data?.length === 1) {
      setTabs([]);
    } else {
      setTabIndex(0);
    }
  }, [deleteTab.data]);

  useEffect(() => {
    getForm.request(formId);
  }, [formId, publishForm.data]);

  const handleReorderTab = async (tabId, type) => {
    if (type === "left") {
      const res = await moveLeftTab.request(formId, tabId);
      if (res.ok) {
        setTabIndex((old) => old - 1);
      }
    } else {
      const res = await moveRightTab.request(formId, tabId);
      if (res.ok) {
        setTabIndex((old) => old + 1);
      }
    }
  };

  useEffect(() => {
    if (getFormTabs.data?.length > 0) {
      setTabs(
        getFormTabs.data.map((item) => ({
          name: (
            <Stack direction="row" gap={1} alignItems="center">
              <ArrowLeft onClick={() => handleReorderTab(item.id, "left")} />
              <Typography noWrap>{item.tab_title}</Typography>
              <Edit
                fontSize="12px"
                onClick={() => {
                  handleOpenModal();
                  setEditTabId(item.id);
                }}
              />
              <DeleteOutlined
                fontSize="14px"
                onClick={() => deleteTabHandler(item.id)}
              />
              <ArrowRight onClick={() => handleReorderTab(item.id, "right")} />
            </Stack>
          ),
          component: (
            <CustomForm
              refetchStatus={() => {
                getForm.request(formId);
              }}
              tabId={item.id}
              formId={formId}
            />
          ),
        }))
      );
    }
  }, [getFormTabs.data, publishForm.data]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const onSubmitTabHandler = async (data) => {
    let res;
    const body = {
      tab_title: data.tab,
    };
    if (editTabId) {
      res = await updateTab.requestWithToast(
        "Updated successfully",
        formId,
        editTabId,
        body
      );
    } else {
      res = await createTab.request(formId, body);
    }

    if (res.ok) {
      reset();
      handleOpenModal();
    }
  };

  return (
    <Stack>
      {/* add/edit tab */}
      <AddNewModal
        title={editTabId ? "Edit Tab Name" : "Add New Tab"}
        openModal={openModal}
        handleOpen={handleOpenModal}
        onSubmit={onSubmitTabHandler}
        type="tab"
      />
      <Stack>
        <Stack
          direction={"row"}
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          width="100%"
          marginBottom={2}
        >
          <StatusLabel status={getForm.data?.status} />
          {getForm.data?.status !== "published" && (
            <Button
              variant="contained"
              onClick={() => {
                setTabs([]);
                publishForm.requestWithToast("Published successfully", formId);
              }}
            >
              publish
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={backHandler}
            sx={{ textTransform: "none" }}
          >
            back
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          gap={2}
          width={window.innerWidth - 400}
        >
          <CustomAddButton color="secondary" onClick={handleOpenModal} />
          <ThirdNavTabs tabState={[tabIndex, setTabIndex]} tabs={tabs} />
        </Stack>

        <Stack mt={2}>
          {tabs.map(
            (tab, index) => index === tabIndex && <Box>{tab.component}</Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const AddNewModal = ({ title, type, onSubmit, handleOpen, openModal }) => {
  const [viewMode, setViewMode] = useState("form");

  //api
  const getSpecialtyList = useApi(main.getSpecialtyList);

  useEffect(() => {
    if (type === "form") getSpecialtyList.request();
  }, [type]);

  const fields =
    type === "form"
      ? [
          {
            label: "Name",
            name: "form_title",
            validation: { required: true },
          },
          {
            label: "Specialties",
            name: "specialties",
            type: "select",
            multiple: true,
            items: getSpecialtyList.data,
            validation: { required: true },
          },
        ]
      : [
          {
            name: "tab",
            label: "Name",
            validation: { required: true },
          },
        ];

  return (
    <Stack mb={2}>
      <Modal open={openModal} handleOpen={handleOpen}>
        <CebForm
          modalForm
          modalTitle={title}
          onSubmit={onSubmit}
          viewModeState={[viewMode, setViewMode]}
          fields={fields}
          formWidth={700}
          cancelHandler={handleOpen}
        />
      </Modal>
    </Stack>
  );
};
