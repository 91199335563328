import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";

import trendingIcon from "assets/images/trendingIcon.png";
import { useTranslation } from "react-i18next";

const CountCard = ({
  title,
  count,
  percentage,
  link,
  border = true,
  width = "100%",
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: [],
    datasets: [
      {
        data: [percentage?.value, 100 - percentage?.value],
        backgroundColor: [percentage?.color, "rgba(255, 255, 255, 1)"],
        borderColor: [percentage?.color, "rgba(255, 255, 255, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Stack
      width={width}
      borderRadius="10px"
      border={border && "1px solid rgba(0,0,0,0.3)"}
      padding="16px"
      gap={1}
    >
      <Typography fontWeight={500} fontSize="16px">
        {title}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={2}
        alignItems="center"
        width={"100%"}
      >
        <Typography fontWeight={500} fontSize="40px">
          {count ? Number(count).toLocaleString("en-US") : "0"}
        </Typography>
        {percentage?.value > 0 && (
          <Box width={100} position="relative">
            <Doughnut data={data} />
            <Typography
              position={"absolute"}
              zIndex={15}
              top="50%"
              left="50%"
              style={{ transform: "translate(-50%,-35%)" }}
              fontWeight={500}
              fontSize="12px"
            >
              {parseInt(percentage?.value)}%
            </Typography>
          </Box>
        )}
      </Stack>
      {link && (
        <Stack
          direction={"row"}
          gap={1}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(link)}
        >
          <img src={trendingIcon} />
          <Typography fontWeight={500} fontSize="13px" color="#0050A0">
            {t("Manage")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CountCard;
