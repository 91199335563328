import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={63}
      height={59}
      viewBox='0 0 63 59'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20.826 4.583a10.322 10.322 0 110 20.644 10.322 10.322 0 010-20.644zm0-4.128a14.45 14.45 0 100 28.9 14.45 14.45 0 000-28.9zM41.47 58.258h-4.13V47.936A10.322 10.322 0 0027.02 37.614H14.632A10.322 10.322 0 004.31 47.936v10.322H.182V47.936a14.45 14.45 0 0114.45-14.451H27.02a14.451 14.451 0 0114.45 14.45v10.323zM47.663 29.728l-5.347-5.347-2.91 2.91 8.257 8.258 14.45-14.45-2.91-2.911-11.54 11.54z'
        fill='#000'
      />
    </svg>
  );
}

export default SvgComponent;
