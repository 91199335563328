import { Button, Stack, Typography } from "@mui/material";
import main from "api/main";
import Filter from "assets/icons/Filter";
import CebForm from "components/Form/CebForm";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import Modal from "components/UI/Modal";
import { useNavigate } from "react-router-dom";
import SchedulePage from "./SchedulePage";
import MainContainer from "components/Entity/MainContainer";
import theme from "styles/MUITheme";
import { date } from "yup";
import { useTranslation } from "react-i18next";

const ExternalClinicDetails = ({ clinicId, isHospitalProfile }) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState("cards");
  const [openModal, setOpenModal] = useState(false);
  const [filterByDoctor, setFilterByDoctor] = useState(false);

  const navigate = useNavigate();

  //api
  const getClinic = useApi(main.getClinic);
  const addClinicPractitioner = useApi(main.addClinicPractitioner);
  const removeClinicPractitioner = useApi(main.removeClinicPractitioner);

  //handle schedule view
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    getClinic.request(clinicId, filterByDoctor);
  }, [
    addClinicPractitioner.data,
    filterByDoctor,
    removeClinicPractitioner.data,
  ]);

  const cardItems = [
    {
      header: filterByDoctor ? "Doctor" : "Practitioner",
      keyName: "data.full_name",
    },
    filterByDoctor && {
      header: "Specialty",
      keyName: "data.specialty",
    },
    !filterByDoctor && {
      header: "Type",
      keyName: "data.type?.name",
    },
    {
      header: "License End-Date",
      keyName: "data.license_end_date",
    },
    !filterByDoctor && {
      header: "Un-Assign",
      type: "element",
      element: (
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            backgroundColor: "#fff",
            textTransform: "none",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          {t("remove")}
        </Button>
      ),
      onClickHandler: (card) =>
        removeClinicPractitioner.requestWithToast(
          t("Deleted successfully"),
          clinicId,
          card.id
        ),
    },
    filterByDoctor && {
      header: "Schedule & Availability",
      type: "element",
      element: (
        <Button
          variant="outlined"
          color="primary"
          sx={{
            backgroundColor: "#fff",
            textTransform: "none",
            borderRadius: "10px",
          }}
        >
          {t("view schedule")}
        </Button>
      ),
      onClickHandler: (card) =>
        navigate(`?id=${clinicId}&doctor_id=${card.id}`),
    },
  ].filter((item) => item);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const onSubmitNewPractitioner = async (data) => {
    console.log(data);
    const res = await addClinicPractitioner.requestWithToast(
      t("Added successfully"),
      clinicId,
      data.uuid
    );
    if (res.ok) {
      handleOpenModal();
    }
  };

  return (
    <>
      {urlParams.get("doctor_id") ? (
        <SchedulePage
          clinicId={clinicId}
          doctorId={urlParams.get("doctor_id")}
        />
      ) : (
        <MainContainer
          title={`${isHospitalProfile ? "External " : ""}Clinic Details`}
        >
          <Stack gap={2}>
            <Modal open={openModal} handleOpen={handleOpenModal}>
              <AssignNewPractitioner
                onSubmit={onSubmitNewPractitioner}
                handleOpenModal={handleOpenModal}
              />
            </Modal>
            <Typography fontSize={"25px"} fontWeight={700} color="primary">
              {getClinic.data?.name}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="outlined"
                onClick={handleOpenModal}
                style={{
                  width: 300,
                  height: 48,
                  borderRadius: 10,
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                {t("Assign New Doctor / Practitioner")}
              </Button>
              <Button
                variant="outlined"
                style={{
                  height: 48,
                  borderRadius: 10,
                  textTransform: "none",
                  fontWeight: 500,
                }}
                onClick={() => setFilterByDoctor((state) => !state)}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  {filterByDoctor ? t("All Practitioners") : t("Doctors Only")}
                  <Filter />
                </Stack>
              </Button>
            </Stack>
            <CebForm
              viewModeState={[viewMode, setViewMode]}
              cardItems={cardItems}
              cardsData={getClinic.data.practitioners}
              loading={getClinic.loading}
            />
          </Stack>
        </MainContainer>
      )}
    </>
  );
};

export default ExternalClinicDetails;

// const AssignNewPractitioner = ({ onSubmit, handleOpenModal }) => {
//   const [viewMode, setViewMode] = useState("form");
//   const [nid, setNid] = useState();
//   const [uuid, setUuid] = useState();

//   //api
//   const getPractitionerByNid = useApi(main.getPractitionerByNid);

//   const fields = [
//     {
//       name: "nid",
//       label: "Nid",
//       type: "number",
//       validation: { required: true, length: 14 },
//     },
//     getPractitionerByNid.data?.[0]?.full_name &&
//       nid?.length === 14 && {
//         label: "Name",
//         type: "readOnly",
//         value: getPractitionerByNid.data?.[0]?.full_name,
//       },
//   ].filter((item) => item);

//   useEffect(() => {
//     if (nid?.length === 14) {
//       getPractitionerByNid.request(nid);
//     }
//   }, [nid]);

//   useEffect(() => {
//     setUuid(getPractitionerByNid.data?.[0]?.id);
//   }, [getPractitionerByNid.data]);

//   return (
//     <CebForm
//       viewModeState={[viewMode, setViewMode]}
//       fields={fields}
//       getWatchedFields={(watchedFields) => {
//         setNid(watchedFields[0]);
//       }}
//       fieldsToWatch={{
//         name: ["nid"],
//       }}
//       modalForm
//       modalTitle="Assign New Doctor/Practitioner"
//       onSubmit={(data) => onSubmit({ ...data, uuid })}
//       cancelHandler={handleOpenModal}
//       formWidth={800}
//       loading={getPractitionerByNid.loading}
//     />
//   );
// };

const AssignNewPractitioner = ({ onSubmit, handleOpenModal }) => {
  const [viewMode, setViewMode] = useState("form");
  const [role, setRole] = useState();
  const [practitionerList, setPractitionerList] = useState([]);

  //api
  const getPractitionerList = useApi(main.getPractitionerList);
  const getPractitionerTypes = useApi(main.getPractitionerTypes);

  useEffect(() => {
    getPractitionerTypes.request();
  }, []);

  useEffect(() => {
    setPractitionerList(
      getPractitionerList.data?.map((item) => ({
        id: item.id,
        label: item.full_name,
      }))
    );
  }, [getPractitionerList.data]);

  useEffect(() => {
    setPractitionerList([]);
    getPractitionerList.request(role, null, false);
  }, [role]);

  const fields = [
    {
      name: "role",
      label: "Role",
      type: "select",
      items: getPractitionerTypes.data,
    },
    {
      name: "practitioner",
      label: "Name",
      type: "select",
      autoComplete: true,
      validation: { required: true },
      items: practitionerList,
    },
  ].filter((item) => item);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      getWatchedFields={(watchedFields) => {
        setRole(watchedFields[0]);
      }}
      fieldsToWatch={{
        name: ["role"],
      }}
      modalForm
      modalTitle="Assign New Doctor / Practitioner"
      onSubmit={(data) => onSubmit({ uuid: data.practitioner?.id })}
      cancelHandler={handleOpenModal}
      formWidth={800}
    />
  );
};
