import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import { Delete } from "@mui/icons-material";
import { useToasts } from "react-toast-notifications";
import { useWatch } from "react-hook-form";
import _ from "lodash";
import CustomAddButton from "../UI/CustomAddButton";
import { useTranslation } from "react-i18next";

export default function NestedForm({
  label,
  title,
  nestedId,
  fields,
  primaryListText,
  secondaryListText,
  width = "100%",
  errors,
  index,
  parentIndex,
  onChangeCondition,
  watch,
  setValue,
  required = false,
  register,
  control,
  name,
  uniqueItems,
  cebFieldFunc,
  resetNested,
  disableNested,
  onReset,
  actionBar,
}) {
  const { t } = useTranslation();
  const [resultCondition, setResultCondition] = useState(required);
  const [data, setData] = useState([]);
  const [allFields, setAllFields] = useState([]);

  const { addToast } = useToasts();

  useEffect(() => {
    if (fields?.length > allFields.length) {
      setAllFields(fields);
    }
  }, [fields]);

  useEffect(() => {
    if (required) onChangeCondition(required, index, parentIndex, nestedId);
  }, [required]);

  const watchedFields = useWatch({
    control,
    name: fields.map((item) => item.name),
  });

  const isDataExists = (newData, data, uniqueItems = []) => {
    let uniqueData = {};
    if (!uniqueItems.length) {
      uniqueData = newData;
    } else {
      for (const item of uniqueItems) {
        uniqueData[item] = newData[item];
      }
    }
    const isExist = data.find((item) => _.isMatch(item, uniqueData));
    return isExist ? true : false;
  };

  const resetFields = () => {
    for (const i in fields) {
      if (
        fields[i].type === "file" ||
        (fields[i].type === "select" && fields[i].multiple) ||
        fields[i].multipleRecords
      )
        setValue(fields[i].name, []);
      else if (fields[i].type === "date" || fields[i].type === "dateTime")
        setValue(
          fields[i].name,
          fields[i].defaultValue ? fields[i].defaultValue : null
        );
      else if (fields[i].type === "boolean") setValue(fields[i].name, [false]);
      else if (fields[i].defaultValue)
        setValue(fields[i].name, fields[i].defaultValue);
      else setValue(fields[i].name, "");
    }
    if (onReset) onReset();
  };

  const onAddHandler = () => {
    //if false, change to true and add nested field to yup resolver
    if (resultCondition === false) {
      setResultCondition(true);
      onChangeCondition(true, index, parentIndex, nestedId);
      return;
    }
    //if true check if there are any errors before adding the current form.
    let isValid = true;
    for (const field of fields) {
      if (
        errors[field.name] ||
        (field.validation?.required &&
          (!watch(field.name) || watch(field.name)?.length === 0))
      ) {
        isValid = false;
        break;
      }
    }
    if (isValid && !disableNested) {
      const newData = {};
      for (const i in fields) {
        newData[fields[i].name.substr(name.length + 1)] =
          fields[i].type === "boolean" ? watchedFields[i][0] : watchedFields[i];
      }
      if (!isDataExists(newData, data, uniqueItems)) {
        //add data
        setData((state) => [...state, newData]);
        //reset fields
        resetFields();
        setResultCondition(false);
        onChangeCondition(false, index, parentIndex, nestedId);
      } else
        addToast("This record has already been added", {
          appearance: "error",
          autoDismiss: true,
        });
    } else {
      addToast("Enter the fields properly and try again", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const onDeleteHandler = (index) => {
    if (data.length === 1 && required) setResultCondition(true);
    const newData = data.filter((_, i) => index !== i);
    setData(newData);
  };

  useEffect(() => {
    setValue(name, data);
  }, [data]);

  return (
    <>
      <input hidden disabled {...register(name)} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack direction="row" gap={3} alignItems={"center"}>
          <Typography variant="h6" color="primary">
            {t(title)}
          </Typography>
          {resultCondition === false && (
            <CustomAddButton onClick={onAddHandler} />
          )}
        </Stack>
        {actionBar && <Stack>{actionBar}</Stack>}
      </Stack>

      <>
        {/* {data.length > 0 && primaryListText && (
          <List>
            {data.map((field, index) => (
              <ListItem
                key={index}
                value={field}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onDeleteHandler(index)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <FiberManualRecordSharpIcon
                    style={{ fontSize: 15, color: "black" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    primaryListText.key
                      ? `${primaryListText.key}: ${eval(primaryListText.value)}`
                      : `${eval(primaryListText.value)}`
                  }
                  secondary={
                    secondaryListText !== undefined &&
                    eval(secondaryListText.value) !== "" &&
                    eval(secondaryListText.value) !== null &&
                    eval(secondaryListText.value) !== undefined &&
                    `${secondaryListText.key}: ${eval(secondaryListText.value)}`
                  }
                />
              </ListItem>
            ))}
          </List>
        )} */}
        {data.length > 0 && (
          <ItemsList
            items={data}
            onDeleteHandler={onDeleteHandler}
            primaryListText={primaryListText}
            secondaryListText={secondaryListText}
            fields={allFields.map((f) => ({
              name: f.name,
              label: f.label,
              type: f.type,
              items: f.items,
              isEnum: f.isEnum,
              multiple: f.multiple,
            }))}
          />
        )}
        {resultCondition && (
          <Box
            width={width}
            sx={{
              border: "1px solid black",
              borderRadius: "10px",
              p: 2,
              my: 2,
            }}
          >
            <Stack gap={2}>
              <Typography>{label}</Typography>
              {fields.map((f, i) => cebFieldFunc(f, nestedId))}

              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="end"
                alignItems="flex-end"
              >
                {!(required && !data.length) && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setResultCondition(false);
                      onChangeCondition(false, index, parentIndex, nestedId);
                    }}
                    color="primary"
                    style={{
                      width: 100,
                      height: 42,
                      borderRadius: 10,
                      textTransform: "none",
                      fontWeight: 500,
                      marginRight: "12px",
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={onAddHandler}
                  color="primary"
                  style={{
                    width: 100,
                    height: 42,
                    borderRadius: 10,
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                >
                  {t("Save")}
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </>
    </>
  );
}

const ItemsList = ({
  items,
  onDeleteHandler,
  primaryListText,
  secondaryListText,
  fields,
}) => {
  const { t } = useTranslation();
  // const getSelectFieldValue = (field, value) => {
  //   if (field.isEnum) {
  //     return field.multiple ? value?.join(", ") : value;
  //   } else {
  //     if (field.multiple) {
  //       const selectedItems = field.items
  //         ?.filter((item) => value.includes(item.id))
  //         ?.map((item) => item.name || item.label);
  //       return selectedItems?.join(", ");
  //     } else {
  //       const selectedItem = field.items?.find(
  //         (item) => item.id == value
  //       );
  //       return selectedItem.name || selectedItem.label;
  //     }
  //   }
  // };
  const getFieldValue = (field, value) => {
    if (field.type === "select") {
      if (field.isEnum) {
        return field.multiple ? value?.join(", ") : value;
      } else {
        if (field.multiple) {
          const selectedItems = field.items
            ?.filter((item) => value.includes(item.id))
            ?.map((item) => item.name || item.label);
          return selectedItems?.join(", ");
        } else {
          const selectedItem = field.items?.find((item) => item.id == value);
          return selectedItem.name || selectedItem.label;
        }
      }
    } else if (field.type === "file") {
      return value?.map((file) => file.name)?.join(", ");
    } else return value;
  };
  return (
    <>
      {primaryListText || secondaryListText ? (
        <List>
          {items.map((field, index) => (
            <ListItem
              key={index}
              value={field}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDeleteHandler(index)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemIcon>
                <FiberManualRecordSharpIcon
                  style={{ fontSize: 15, color: "black" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  primaryListText.key
                    ? `${primaryListText.key}: ${eval(primaryListText.value)}`
                    : `${eval(primaryListText.value)}`
                }
                secondary={
                  secondaryListText !== undefined &&
                  eval(secondaryListText.value) !== "" &&
                  eval(secondaryListText.value) !== null &&
                  eval(secondaryListText.value) !== undefined &&
                  `${secondaryListText.key}: ${eval(secondaryListText.value)}`
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <>
          {items.map((item, index) => (
            <Stack
              border="1px solid"
              p={2}
              borderRadius={"10px"}
              key={index}
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-start"
            >
              <Stack gap={1}>
                {Object.keys(item).map((key, i) => (
                  <Stack key={key}>
                    {((Array.isArray(item[key]) && item[key]?.length > 0) ||
                      (!Array.isArray(item[key]) && item[key])) && (
                      <Stack direction="row" gap={1}>
                        <Typography fontWeight={500}>
                          {t(fields.find((el) => el.name.includes(key))?.label)}
                          :
                        </Typography>
                        <Typography>
                          {getFieldValue(
                            fields.find((el) => el.name.includes(key)),
                            item[key]
                          )}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                ))}
              </Stack>
              <IconButton onClick={() => onDeleteHandler(index)}>
                <Delete />
              </IconButton>
            </Stack>
          ))}
        </>
      )}
    </>
  );
};
