
import * as React from "react";

const SvgComponent = (props) => (
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.50279 25H24.4972C24.6306 25 24.7584 24.9469 24.8527 24.8523C24.947 24.7577 25 24.6294 25 24.4956C25 24.3618 24.947 24.2335 24.8527 24.1389C24.7584 24.0443 24.6306 23.9912 24.4972 23.9912H7.50279C7.36944 23.9912 7.24156 24.0443 7.14726 24.1389C7.05297 24.2335 7 24.3618 7 24.4956C7 24.6294 7.05297 24.7577 7.14726 24.8523C7.24156 24.9469 7.36944 25 7.50279 25ZM13.6992 21.2089C14.124 21.0897 14.5115 20.864 14.8255 20.5532L24.4188 10.9293C24.7473 10.5984 24.9318 10.1505 24.9318 9.68345C24.9318 9.21644 24.7473 8.76846 24.4188 8.43759L23.4735 7.4994C23.139 7.17886 22.6942 7 22.2316 7C21.769 7 21.3242 7.17886 20.9897 7.4994L11.3964 17.1132C11.086 17.4261 10.864 17.816 10.7528 18.2431L10.0087 21.0274C9.97341 21.1545 9.9725 21.2889 10.0061 21.4165C10.0397 21.5442 10.1065 21.6605 10.1998 21.7537C10.3421 21.8941 10.5332 21.9736 10.7327 21.9756L13.6992 21.2089ZM14.1115 19.837C13.9261 20.0263 13.6934 20.1622 13.4378 20.2304L12.4623 20.4927L11.4568 19.4839L11.7182 18.5054C11.7885 18.2499 11.9237 18.017 12.1104 17.8295L12.4925 17.4562L14.4936 19.4637L14.1115 19.837ZM15.2076 18.7475L13.2065 16.74L19.9741 9.95078L21.9752 11.9583L15.2076 18.7475ZM23.7048 10.2232L22.6892 11.242L20.688 9.23453L21.7037 8.20556C21.7736 8.13516 21.8566 8.07931 21.9481 8.04119C22.0395 8.00308 22.1376 7.98345 22.2366 7.98345C22.3357 7.98345 22.4337 8.00308 22.5252 8.04119C22.6167 8.07931 22.6997 8.13516 22.7696 8.20556L23.7048 9.15383C23.8451 9.29621 23.9237 9.48832 23.9237 9.68849C23.9237 9.88867 23.8451 10.0808 23.7048 10.2232Z" fill="#0050A0"/>
</svg>

    
);

export default SvgComponent;