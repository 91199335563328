import React from "react";
import { Button } from "@mui/material";

const StyledButton = ({
  children,
  color = "primary",
  width = 145,
  height = 46,
  borderRadius = 10,
  fontWeight = 500,
  fontSize = 20,
  variant = "contained",
  type = "",
  onClick = () => {},
  mr = 0,
  ml = 0,
  mt = 0,
  mb = 0,
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      style={{
        width,
        height,
        borderRadius,
        border: "1px solid #003468",
        textTransform: "none",
        fontWeight,
        fontSize,
        marginTop: mt,
        marginBottom: mb,
        marginRight: mr,
        marginTop: mt,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
